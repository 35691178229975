import { Routes, Route, BrowserRouter } from 'react-router-dom';

import {
  ActivateUserScreen,
  InvitedSignupScreen,
  SigninScreen,
  ResetPasswordScreen,
  SendResetPasswordLinkScreen,
} from 'features/auth/screens';

import {
  ChatScreen,
  NewAdamChatScreen,
} from 'features/chat/screens';

import {
  CustomerDetailsScreen,
  CustomerListScreen,
  CustomersImportScreen,
} from 'features/customer/screens';

import { HomeScreen } from 'features/home/screens';

import { InboxScreen } from 'features/inbox/screens';

import { MessageByIdScreen } from 'features/message/screens';

import {
  OrderDetailsScreen,
  OrderListScreen,
  ProcessNewOrderDraftScreen,
  ProcessOrderDraftByIdScreen,
  ProcessOrderDraftsScreen,
} from 'features/order/screens';

import {
  ProductDetailsScreen,
  ProductListScreen,
  ProductsImportScreen,
} from 'features/product/screens';

import {
  TeamMemberDetailsScreen,
  TeamMemberInviteScreen,
  TeamMemberListScreen,
} from 'features/user/screens';

import {
  InstructionListScreen,
  CreateInstructionScreen,
  EditInstructionScreen,
} from 'features/instruction/screens';

import { ErpSyncLogsScreen } from 'features/erp/screens';

import { SettingsScreen } from 'features/settings/screens';

import { SubjectListScreen } from 'features/subject/screens';
import { ThreadByIdScreen } from 'features/thread/screens';
import { RoutingLog } from './RoutingLog';
import { ROUTES } from '../config/routes';

const Routing = () => (
  <BrowserRouter>
    <RoutingLog enabled={false} />

    <Routes>
      <Route path={ROUTES.HOME} element={<HomeScreen />} />

      <Route path={ROUTES.SIGNIN(':username')} element={<SigninScreen />} />
      <Route
        path={ROUTES.INVITED_SIGNUP(':appLink')}
        element={<InvitedSignupScreen />}
      />
      <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUserScreen />} />
      <Route
        path={ROUTES.SEND_RESET_PASSWORD_LINK}
        element={<SendResetPasswordLinkScreen />}
      />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordScreen />} />

      <Route path={ROUTES.PRODUCTS} element={<ProductListScreen />} />
      <Route
        path={ROUTES.PRODUCT_BY_ID(':productId')}
        element={<ProductDetailsScreen />}
      />
      <Route path={ROUTES.PRODUCTS_IMPORT} element={<ProductsImportScreen />} />

      <Route path={ROUTES.CUSTOMERS} element={<CustomerListScreen />} />
      <Route
        path={ROUTES.CUSTOMERS_IMPORT}
        element={<CustomersImportScreen />}
      />
      <Route
        path={ROUTES.CUSTOMER_BY_ID(':businessId')}
        element={<CustomerDetailsScreen />}
      />

      <Route path={ROUTES.SUBJECTS} element={<SubjectListScreen />} />
      {/* <Route
        path={ROUTES.SUBJECT_BY_ID(':subjectId')}
        element={<SubjectDetailsScreen />}
      /> */}

      {/* DEV-702: TODO(ntauth): Re-enable once v2 chat endpoints are implemented */}
      {/* <Route path={ROUTES.CHATS} element={<ChatListScreen />} /> */}
      <Route path={ROUTES.NEW_ADAM_CHAT} element={<NewAdamChatScreen />} />
      <Route path={ROUTES.CHAT_BY_ID(':chatId')} element={<ChatScreen />} />

      <Route path={ROUTES.INBOX({})} element={<InboxScreen />} />
      <Route path={ROUTES.MESSAGE_BY_ID(':messageId')} element={<MessageByIdScreen />} />
      <Route path={ROUTES.THREAD_BY_ID(':threadId')} element={<ThreadByIdScreen />} />

      <Route path={ROUTES.ORDERS} element={<OrderListScreen />} />
      <Route path={ROUTES.ORDER_BY_ID(':orderId')} element={<OrderDetailsScreen />} />
      <Route
        path={ROUTES.PROCESS_ORDER_DRAFTS}
        element={<ProcessOrderDraftsScreen />}
      />
      <Route
        path={ROUTES.PROCESS_ORDER_DRAFT_BY_ID(':orderId')}
        element={<ProcessOrderDraftByIdScreen />}
      />
      <Route
        path={ROUTES.PROCESS_NEW_ORDER_DRAFTS}
        element={<ProcessNewOrderDraftScreen />}
      />

      <Route path={ROUTES.TEAM_MEMBERS} element={<TeamMemberListScreen />} />
      <Route
        path={ROUTES.TEAM_MEMBER_ADD}
        element={<TeamMemberInviteScreen />}
      />
      <Route
        path={ROUTES.TEAM_MEMBER_BY_ID(':userId')}
        element={<TeamMemberDetailsScreen />}
      />

      <Route path={ROUTES.INSTRUCTIONS} element={<InstructionListScreen />} />
      <Route path={ROUTES.CREATE_INSTRUCTION} element={<CreateInstructionScreen />} />
      <Route path={ROUTES.EDIT_INSTRUCTION(':instructionId')} element={<EditInstructionScreen />} />

      <Route path={ROUTES.AUDIT_ERP_SYNC_LOGS} element={<ErpSyncLogsScreen />} />

      <Route path={ROUTES.SETTINGS} element={<SettingsScreen />} />
    </Routes>
  </BrowserRouter>
);

export { Routing };
