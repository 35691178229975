import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faTriangleExclamation,
  faCirclePlay,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

import { Tooltip } from 'components/ui';

import { ProductWithQuantity } from 'features/order/models/Order';
import { usePlayRecordingContext } from 'contexts/usePlayRecordingIndex';
import { useMessagesContext } from 'contexts/useMessagesContext';
import { FieldSpec } from 'features/instruction/models/Schema';
import { FieldPathWrapper } from 'features/order/components/process-order-draft/utils';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

import { wasMatchedByID } from 'helpers/product';
import ErrorsTooltip from '../ErrorsTooltip';
import WarningsTooltip from '../WarningsTooltip';
import ConfidenceLevelIndicator from './ConfidenceLevelIndicator';

interface Props {
  product: ProductWithQuantity;
  productNameField: FieldSpec;
  removeProductByUiIdAndPositionId: (uiId: string, positionId: string) => void;

  errors: string[];
  warnings: string[];
}

const WrappedP = FieldPathWrapper(({ children }: { children: ReactNode }) => <p className="text-sm">{children}</p>);

const ProductNameAndIcons = ({
  product, productNameField, removeProductByUiIdAndPositionId, errors, warnings,
}: Props) => {
  const { isRecordingAvailable, setPlayRecordingKeywordAndMessageId } = usePlayRecordingContext();
  const { selectedMessageId } = useMessagesContext();

  const onPlayButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPlayRecordingKeywordAndMessageId({
      keyword: product.positionId,
      messageId: selectedMessageId,
    });
  };

  const onRemoveProductButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeProductByUiIdAndPositionId(product.uiId, product.positionId);
  };

  return (
    <>
      <div className="flex items-center gap-2">
        {
            errors.length > 0 && (
            <ErrorsTooltip errors={errors}>
              <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 h-5 w-5" />
            </ErrorsTooltip>
            )
        }
        {
          errors.length === 0 && warnings.length > 0 && (
            <WarningsTooltip warnings={warnings}>
              <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning-500 h-5 w-5" />
            </WarningsTooltip>
          )
        }
        <OverflowEllipsisParagraph
          maxLines={1}
          isTooltipEnabled
        >
          <WrappedP
            fieldPath={productNameField.path}
            fieldId={product.positionId}
          >
            {product.name}
          </WrappedP>
        </OverflowEllipsisParagraph>

        <div className="flex gap-2">
          {
            isRecordingAvailable && (
            <Tooltip
              label="Play audio"
            >
              <button
                type="button"
                className=""
                onClick={onPlayButtonClick}
              >
                <FontAwesomeIcon icon={faCirclePlay} />
              </button>
            </Tooltip>
            )
        }
        </div>
      </div>

      <div className="flex items-center gap-2">
        <ConfidenceLevelIndicator score={product.llmScore} isMatchedByID={wasMatchedByID(product)} className="mr-2" />
        <Tooltip label="Delete product">
          <button
            type="button"
            aria-label="Delete product"
            onClick={onRemoveProductButtonClick}
          >
            <FontAwesomeIcon icon={faTrashCan} className="text-red-500" />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default ProductNameAndIcons;
