import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { Group } from 'constants/prompt';

import { ExpandableCard, FieldRenderer } from './components';

interface Props {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const OrderDetails = ({ isExpanded, setIsExpanded }: Props) => {
  const { schema, standardFields: defaultStandardFieldSpecs } = useProcessOrderContext();

  const standardFieldSpecs = defaultStandardFieldSpecs;

  const defaultFieldSpecs = standardFieldSpecs.slice(0, 4);
  const expandedFieldSpecs = standardFieldSpecs.slice(4);

  return (
    <ExpandableCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      title="Order Details"
      group={Group.StandardFields}
      className="grid grid-cols-[auto_1fr] gap-2 items-center"
      isExpandable={expandedFieldSpecs.length > 0}
    >
      <ExpandableCard.DefaultContent>
        {
          defaultFieldSpecs?.map((fieldSpec) => (
            <FieldRenderer
              key={fieldSpec.path}
              group={Group.StandardFields}
              fieldPath={fieldSpec.path}
              wrapperClassName="contents"
              schema={schema}
              fieldSpec={fieldSpec}
            />
          ))
        }
      </ExpandableCard.DefaultContent>
      <ExpandableCard.ExpandedContent>
        {
          expandedFieldSpecs?.map((fieldSpec) => (
            <FieldRenderer
              key={fieldSpec.path}
              group={Group.StandardFields}
              fieldPath={fieldSpec.path}
              wrapperClassName="contents"
              schema={schema}
              fieldSpec={fieldSpec}
            />
          ))
        }
      </ExpandableCard.ExpandedContent>
    </ExpandableCard>
  );
};

export default OrderDetails;
