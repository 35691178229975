import {
  Tooltip as MantineTooltip,
  TooltipProps,
} from '@mantine/core';

interface Props extends TooltipProps {
  children: React.ReactNode;
}

const Tooltip = ({
  children,
  ...props
}: Props) => (
  <MantineTooltip
    {...props}
    transitionProps={{ transition: 'pop', duration: 200, ...props.transitionProps }}
    bg="white"
    styles={{
      tooltip: {
        color: 'black',
        border: '1.5px solid #E8E8E8',
        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      },
      ...props.styles,
    }}
  >
    {children}
  </MantineTooltip>
);

export default Tooltip;
