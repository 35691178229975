import { Tooltip } from '@mantine/core';
import { MantineTransitionName } from '@mantine/core/lib/components/Transition/transitions';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  maxLines?: number;
  openDelay?: number;
  transition?: MantineTransitionName;
  isTooltipEnabled?: boolean;
  customTooltipContent?: string | ReactNode;
  className?: string;

  theme?: 'standard' | 'warning' | 'error' | 'ai-info' | 'success';
};

const getTooltipBgColor = (theme: string | undefined) => {
  if (theme === 'warning') return '#ff6f00';
  if (theme === 'error') return '#f7545a';
  if (theme === 'ai-info') return '#a78bfa';
  if (theme === 'success') return '#34d399';
  return '#75AEF9';
};

const OverflowEllipsisParagraph = ({
  children,
  maxLines,
  transition,
  openDelay,
  isTooltipEnabled,
  customTooltipContent,
  className,

  theme,
}: Props) => (
  <Tooltip
    label={customTooltipContent || children}
    disabled={!isTooltipEnabled}
    openDelay={openDelay}
    transitionProps={{ transition }}
    bg={getTooltipBgColor(theme)}
    withArrow
    multiline
  >
    <div
      className={className}
      style={{
        display: '-webkit-box',
        WebkitLineClamp: maxLines,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  </Tooltip>
);

OverflowEllipsisParagraph.defaultProps = {
  maxLines: 2,
  openDelay: 0,
  transition: 'pop',
  isTooltipEnabled: false,
  customTooltipContent: null,
  className: '',
};

export { OverflowEllipsisParagraph };
