import {
  useCallback, useLayoutEffect, useRef, useState,
} from 'react';
import { LoadingOverlay } from '@mantine/core';

import { Subject } from 'models/User';
import { ScrollAreaWrapper } from '../../wrapper/ScrollAreaWrapper';

interface Props {
  subjects: Subject[];
  openDialog: (idx: number) => void;
  isLoading: boolean;
  onScrolledEnd: () => void;
}

const SubjectsTable = ({
  subjects,
  openDialog,
  isLoading,
  onScrolledEnd,
}: Props) => {
  const checkbox = useRef<HTMLInputElement>();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  useLayoutEffect(() => {
    if (selectedSubjects != null && subjects != null) {
      const isIndeterminate = selectedSubjects.length > 0
        && selectedSubjects.length < subjects.length;
      setChecked(selectedSubjects.length === subjects.length);
      setIndeterminate(isIndeterminate);
      if (checkbox && checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedSubjects, subjects]);

  const onDetailsButtonClick = useCallback(
    (idx: number) => {
      openDialog(idx);
    },
    [openDialog],
  );

  const toggleAll = useCallback(() => {
    setSelectedSubjects(checked || indeterminate ? [] : subjects);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }, [checked, indeterminate, subjects]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-md border border-solid">
      <ScrollAreaWrapper
        className="flex-1"
        offsetScrollbar={false}
        onScrolledEnd={onScrolledEnd}
      >
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="sticky top-0 z-10 bg-primary-50 text-sm font-semibold text-neutral-600 drop-shadow">
            <tr>
              <th scope="col" className="relative px-lg py-smd">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Name
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Phone
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Email
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Details
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            {subjects.map((subject, idx) => (
              <tr
                key={subject.id}
                tabIndex={0}
                onKeyDown={() => {}}
                className={`${selectedSubjects.includes(subject) && 'bg-gray-50'} hover:bg-gray-50`}
              >
                <td className="relative px-7 sm:w-12 sm:px-xl">
                  {selectedSubjects.includes(subject) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-500" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-500"
                    value={subject.id}
                    checked={selectedSubjects.includes(subject)}
                    onChange={(e) => setSelectedSubjects(
                      e.target.checked
                        ? [...selectedSubjects, subject]
                        : selectedSubjects.filter((s) => s !== subject),
                    )}
                    onClick={(e) => e.stopPropagation()}
                  />
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {
                  subject.firstName.length > 0 || subject.lastName.length > 0
                    ? `${subject.firstName} ${subject.lastName}`
                    : subject.email || subject.phone || '-'
}
                </td>
                <td className="max-w-[15ch] text-ellipsis px-lg py-lg text-sm text-gray-500">
                  {subject.phone || '-'}
                </td>
                <td className="px-lg py-lg text-sm text-gray-500">
                  {subject.email || '-'}
                </td>
                <td className="flex items-center justify-center px-lg py-lg">
                  <button
                    disabled
                    type="button"
                    className="text-sm font-semibold text-primary-400"
                    onClick={() => onDetailsButtonClick(idx)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className={`relative flex h-10 w-full items-center justify-center ${!isLoading && 'hidden'}`}
        >
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollAreaWrapper>
    </div>
  );
};

export default SubjectsTable;
