import {
  memo,
  useCallback, useEffect, useMemo, useRef,
  useState,
} from 'react';
import { useDebouncedState } from '@mantine/hooks';

import { ProductWithQuantity } from 'features/order/models/Order';
import { Product } from 'models/Product';

import { Select, SelectOption } from 'components/ui/Select';
import {
  QuerySearchType,
  useFetchProducts,
} from 'hooks/fetch/useFetchProducts';
import {
  wasMatchedByID,
} from 'helpers/product';
import { ProductSortingColumn } from 'types/product';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { formatProductOption, formatProductSelectedOption } from './utils';

type Params = {
  businessId: string;
  searchQuery?: string;
  useNameOrIDOrSKUSearch?: boolean;
  sortingColumn?: ProductSortingColumn;
  filterEnabledProducts?: boolean;
  reset?: boolean;
};

interface Props {
  businessId: string;
  productWithQuantity: ProductWithQuantity;
  onProductChange: (product: Product) => void;
  label?: string;
  setError?: (error: string) => void;
}

const MemoizedSelect = memo(({
  label,
  selectedProduct,
  productsOptions,
  isLoading,
  onProductsSelectChange,
  onProductsSearchChange,
  onProductsSelectOptionsScrolledEnd,
  onDropDownOpen,
  dropdownPlaceHolder = 'Type a product name or keyword to search',
  error,
}: {
  label: string;
  selectedProduct: SelectOption;
  productsOptions: SelectOption[];
  isLoading: boolean;
  onProductsSelectChange: (option: SelectOption) => void;
  onProductsSearchChange: (value: string) => void;
  onProductsSelectOptionsScrolledEnd: (value: string) => void;
  onDropDownOpen: () => void;
  dropdownPlaceHolder?: string;
  error?: string;
}) => (
  <Select
    label={label}
    searchPlaceholder="Type here to search products..."
    dropdownPlaceHolder={dropdownPlaceHolder}
    selectedOption={selectedProduct}
    options={productsOptions}
    isLoading={isLoading}
    isExternalSearchEnabled
    onSelectionChange={onProductsSelectChange}
    onSearchPerformed={onProductsSearchChange}
    onScrolledEnd={onProductsSelectOptionsScrolledEnd}
    onDropdownOpen={onDropDownOpen}
    position="bottom"
    error={error}
    middlewares={{ flip: false, shift: false }}
    shrinkVisibleId
  />
));

const ProductSelect = memo(({
  businessId,
  label = '',
  productWithQuantity,
  onProductChange,
  setError,
}: Props) => {
  const {
    sortingColumn, filterEnabledProducts,
  } = useProcessOrderContext();
  const {
    products, isLoading, loadProducts, endReachedRef,
  } = useFetchProducts(
    { preventInitialFetch: true },
  );

  const [dropdownPlaceHolder, setDropdownPlaceHolder] = useState(null);

  const wasMatchedByID_ = useMemo(() => wasMatchedByID(productWithQuantity), [productWithQuantity]);
  const selectedID = useMemo(() => productWithQuantity?.product?.id, [productWithQuantity]);

  const error = useMemo(() => {
    if (!productWithQuantity?.product) return 'Select a product';
    return '';
  }, [productWithQuantity?.product]);

  const [searchQuery, setSearchQuery] = useDebouncedState('', 300);

  const isFirstRender = useRef(true);
  const paramsRef = useRef<Params>({
    businessId,
    sortingColumn,
  });

  useEffect(() => {
    paramsRef.current.sortingColumn = sortingColumn;
  }, [sortingColumn]);

  useEffect(() => {
    paramsRef.current.filterEnabledProducts = filterEnabledProducts;
  }, [filterEnabledProducts]);

  useEffect(() => {
    paramsRef.current.businessId = businessId;
  }, [businessId]);

  const selectedProduct = useMemo<SelectOption>(() => (
    formatProductSelectedOption(productWithQuantity, wasMatchedByID_)
  ), [productWithQuantity, wasMatchedByID_]);

  const productsOptions: SelectOption[] = useMemo(
    () => products.map((p) => formatProductOption(p, selectedID, wasMatchedByID_)),
    [products, selectedID, wasMatchedByID_],
  );

  const onProductsSelectChange = useCallback(
    (option: SelectOption) => {
      onProductChange(
        products.find((p) => p.id === option.value),
      );
    },
    [onProductChange, products],
  );

  const onProductsSearchChange = useCallback(
    (value: string) => {
      const searchValue: string = value || '';

      if (searchValue.length < 2) {
        setDropdownPlaceHolder('More than 2 characters are required to search');
        return;
      }

      if (!isLoading && searchValue !== '') {
        paramsRef.current.useNameOrIDOrSKUSearch = true;
        paramsRef.current.reset = true;
        setSearchQuery(searchValue);
      } else if (!isLoading && searchValue === '') {
        paramsRef.current.useNameOrIDOrSKUSearch = false;
        paramsRef.current.reset = true;
        setSearchQuery(productWithQuantity.name);
      }
    },
    [isLoading, productWithQuantity.name, setSearchQuery],
  );

  const onProductsSelectOptionsScrolledEnd = useCallback(
    (value: string) => {
      if (!isLoading && value !== '' && !endReachedRef.current) {
        loadProducts({
          searchQuery: value,
          customerId: paramsRef.current.businessId,
          querySearchType: QuerySearchType.Standard,
          sortingColumns: [paramsRef.current.sortingColumn],
          filterEnabledProducts: paramsRef.current.filterEnabledProducts,
          reset: false,
        });
      }
    },
    [endReachedRef, isLoading, loadProducts],
  );

  const onDropDownOpen = useCallback(() => {
    if (!isLoading && !products.length && productWithQuantity.name) {
      paramsRef.current.useNameOrIDOrSKUSearch = false;
      paramsRef.current.reset = false;
      setSearchQuery(productWithQuantity.name);
    }
  }, [isLoading, products.length, productWithQuantity.name, setSearchQuery]);

  useEffect(() => {
    if (isFirstRender.current || !paramsRef.current || !loadProducts) {
      isFirstRender.current = false;
      return;
    }

    loadProducts({
      searchQuery,
      customerId: paramsRef.current.businessId,
      querySearchType: paramsRef.current.useNameOrIDOrSKUSearch
        ? QuerySearchType.Standard
        : QuerySearchType.Embedding,
      sortingColumns: [paramsRef.current.sortingColumn],
      filterEnabledProducts: paramsRef.current.filterEnabledProducts,
      reset: paramsRef.current.reset,
    });
  }, [loadProducts, searchQuery]);

  useEffect(() => {
    setError(error);
  }, [error, setError]);

  return (
    <MemoizedSelect
      label={label}
      selectedProduct={selectedProduct}
      productsOptions={productsOptions}
      isLoading={isLoading}
      onProductsSelectChange={onProductsSelectChange}
      onProductsSearchChange={onProductsSearchChange}
      onProductsSelectOptionsScrolledEnd={onProductsSelectOptionsScrolledEnd}
      onDropDownOpen={onDropDownOpen}
      dropdownPlaceHolder={dropdownPlaceHolder}
      error={error}
    />
  );
});

export default ProductSelect;
