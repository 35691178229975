import {
  useMemo, useEffect, useState, useRef,
} from 'react';
import {
  StarIcon,
  EllipsisVerticalIcon,
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { Buffer } from 'buffer';
import { Button } from 'components/ui/Button';
import { Tag } from 'components/common/Tag';
import { TagVariant } from 'types/tag';
import { Workflow } from 'models/Workflow';
import adam from 'assets/adam-face.png';
import { ROUTES } from 'config/routes';
import {
  ExternalMessageStatus, MessageIntent, Message as MessageModel, MessageRefType,
} from 'models/Message';
import Dayjs from 'dayjs';
import { ParsedEmail } from 'models/Mail';
import { ThreadMessageAttachments } from './ThreadMessageAttachments';

interface ThreadMessageProps {
  message: MessageModel;
  isExpanded: boolean;
  canToggleExpanded: boolean;
  senderName: string;
  senderBusinessName: string;
  recipientNames: string[];
  recipientBusinessName: string;
  time: {
    current: string;
    original: string;
    relative: string;
  };
  onToggle: () => void;
  onReply: () => void;
}

const intentToTagVariant = (intent: MessageIntent): TagVariant => {
  switch (intent) {
    case MessageIntent.ORDER:
      return TagVariant.BLUE;
    case MessageIntent.OTHER:
    default:
      return TagVariant.GREEN;
  }
};

export const ThreadMessage = ({
  message,
  isExpanded,
  canToggleExpanded,
  senderName,
  senderBusinessName,
  recipientNames,
  recipientBusinessName,
  time,
  onToggle,
  onReply,
}: ThreadMessageProps) => {
  const navigate = useNavigate();

  const isAdamParticipating = Object
    .entries(message.workflowRuns || {})
    ?.filter(([workflow]) => workflow === Workflow.Order).length > 0;

  const orderRef = useMemo(
    () => [MessageRefType.ORDER, MessageRefType.ORDER_GROUP]
      .includes(message.refType) && message.ref,
    [message],
  );

  const [parsedMessage, setParsedMessage] = useState<ParsedEmail | null>(null);

  useEffect(() => {
    const parseMessage = async () => {
      if (message.smtpMessage) {
        const decodedRaw = Buffer.from(message.smtpMessage.raw, 'base64').toString();
        const decoded = JSON.parse(decodedRaw) as ParsedEmail;
        setParsedMessage(decoded);
      } else if (message.imapMessage) {
        const decodedRaw = Buffer.from(message.imapMessage.raw, 'base64').toString();
        const decoded = JSON.parse(decodedRaw) as ParsedEmail;
        setParsedMessage(decoded);
      }
    };

    parseMessage();
  }, [message]);

  const purifiedHTMLOrText = useMemo(() => {
    if (parsedMessage) {
      const sanitizedHTML = DOMPurify.sanitize(parsedMessage.html || parsedMessage.text, {
        USE_PROFILES: { html: true },
      });
      return sanitizedHTML;
    }

    return null;
  }, [parsedMessage]);

  const handleProcessOrder = () => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(orderRef));
  };

  const getStatusText = () => {
    switch (message.smtpMessage?.status) {
      case ExternalMessageStatus.PENDING:
        return (
          <span className="animate-[blink_1s_ease-in-out_infinite]">
            • Sending
          </span>
        );
      case ExternalMessageStatus.FAILED:
        return '• Failed';
      default:
        return message.messageStatus || '';
    }
  };

  const [showEmailDetails, setShowEmailDetails] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowEmailDetails(false);
      }
    };

    if (showEmailDetails) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmailDetails]);

  const getRecipientDisplay = () => {
    if (recipientNames.length > 0) {
      if (recipientNames.length > 1) {
        return `${recipientNames[0]},..`;
      }
      return recipientNames[0];
    }
    return recipientBusinessName;
  };

  return (
    <div
      role="rowheader"
      tabIndex={0}
      onKeyDown={canToggleExpanded && !isExpanded ? (e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          e.preventDefault();
          onToggle();
        }
      } : () => {}}
      onClick={canToggleExpanded && !isExpanded ? onToggle : () => {}}
      className={`px-4 py-3 hover:bg-gray-50 group ${canToggleExpanded && !isExpanded ? 'cursor-pointer' : ''}`}
    >
      <div className="flex items-start">
        <div className="flex-shrink-0 mr-4">
          <div className="relative">
            <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center z-10 relative border border-white">
              <span className="text-blue-700 font-medium">
                {(senderBusinessName || senderName).charAt(0).toLocaleUpperCase()}
              </span>
            </div>
            {isAdamParticipating && (
              <div className="w-8 h-8 rounded-full overflow-hidden absolute -right-2 -top-2 border border-white">
                <img src={adam} alt="adam" className="w-full h-full" />
              </div>
            )}
          </div>
        </div>

        <div className="flex-1 min-w-0">
          <div
            role="rowheader"
            tabIndex={0}
            onKeyDown={canToggleExpanded && isExpanded ? (e) => {
              if (e.key === 'Enter' || e.key === 'Space') {
                e.preventDefault();
                onToggle();
              }
            } : undefined}
            onClick={canToggleExpanded && isExpanded ? onToggle : () => {}}
            className={`flex items-center justify-between ${canToggleExpanded && isExpanded ? 'cursor-pointer' : ''}`}
          >
            <div className="flex items-baseline">
              <span className="font-semibold text-sm">{senderBusinessName}</span>
              <span className="ml-2 text-xs text-gray-500">
                {senderName}
              </span>
              <span className="ml-2 text-sm text-gray-500">
                {time.current}
                <span className="mx-1">
                  (
                  {time.relative}
                  )
                </span>
              </span>
              {message.smtpMessage?.status !== ExternalMessageStatus.SENT && (
              <span className={`ml-2 text-xs ${message.smtpMessage?.status === ExternalMessageStatus.PENDING ? 'text-orange-500' : 'text-red-500'}`}>
                {getStatusText()}
              </span>
              )}

              {/* Intents */}
              {message.intents && (
              <div className="flex items-center ml-2 gap-2">
                {message.intents.map((intent) => (
                  <Tag
                    key={intent}
                    tagTitle={intent || '-'}
                    isTagLoading={false}
                    className="static"
                    tagVariant={intentToTagVariant(intent)}
                    hideCircle
                  />
                ))}
              </div>
              )}
            </div>

            {/* Actions */}
            <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
              <Tooltip position="bottom" label="Star">
                <Button
                  disabled
                  theme="secondary"
                  variant="small"
                  icon={<StarIcon className="aspect-square w-4" />}
                  onClick={() => {}}
                />
              </Tooltip>
              <Tooltip position="bottom" label="Reply">
                <Button
                  theme="secondary"
                  variant="small"
                  icon={<ArrowUturnLeftIcon className="aspect-square w-4" />}
                  onClick={onReply}
                />
              </Tooltip>
              <Tooltip position="bottom" label="More actions">
                <Button
                  disabled
                  theme="secondary"
                  variant="small"
                  icon={<EllipsisVerticalIcon className="aspect-square w-4" />}
                  onClick={() => {}}
                />
              </Tooltip>
            </div>
          </div>

          <div className="text-xs text-gray-600 relative">
            <div className="flex items-center">
              <span>
                To:
                {' '}
                {getRecipientDisplay()}
              </span>
              <button
                type="button"
                onClick={() => setShowEmailDetails(!showEmailDetails)}
                className="mt-0.5 p-1 hover:bg-gray-100 rounded-full"
              >
                {showEmailDetails
                  ? <ChevronUpIcon className="h-4 w-4" />
                  : <ChevronDownIcon className="h-4 w-4" />}
              </button>
            </div>

            {showEmailDetails && parsedMessage && (
              <div ref={dropdownRef} className="absolute left-0 mt-2 z-10 bg-white shadow-lg border rounded-md p-3 min-w-[400px] max-w-[600px] transform -translate-x-4">
                <div className="space-y-2">
                  {parsedMessage.headers.from?.length > 0 && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">From:</span>
                      <span>{parsedMessage.headers.from.map((from) => from.address).join(', ')}</span>
                    </div>
                  )}
                  {parsedMessage.headers.sender?.length > 0 && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">Sender:</span>
                      <span>{parsedMessage.headers.sender.map((sender) => sender.address).join(', ')}</span>
                    </div>
                  )}
                  {parsedMessage.headers.to?.length > 0 && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">To:</span>
                      <span>{parsedMessage.headers.to.map((to) => to.address).join(', ')}</span>
                    </div>
                  )}
                  {parsedMessage.headers.replyTo?.length > 0 && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">Reply-To:</span>
                      <span>{parsedMessage.headers.replyTo.map((replyTo) => replyTo.address).join(', ')}</span>
                    </div>
                  )}
                  {parsedMessage.headers.cc?.length > 0 && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">Cc:</span>
                      <span>{parsedMessage.headers.cc.map((cc) => cc.address).join(', ')}</span>
                    </div>
                  )}
                  {parsedMessage.headers.bcc?.length > 0 && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">Bcc:</span>
                      <span>{parsedMessage.headers.bcc.map((bcc) => bcc.address).join(', ')}</span>
                    </div>
                  )}
                  {parsedMessage.headers.subject && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">Subject:</span>
                      <span>{parsedMessage.headers.subject}</span>
                    </div>
                  )}
                  {parsedMessage.headers.date && (
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className="font-semibold text-gray-900">Date:</span>
                      <span>{Dayjs(parsedMessage.headers.date).format('MMM D YYYY, HH:mm')}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {isExpanded && isAdamParticipating && orderRef && (
            <div>
              <div className="mt-4 flex items-center space-x-2">
                <div className="rounded-full overflow-hidden">
                  <img src={adam} alt="adam" className="h-8 w-8" />
                </div>
                <div className="text-start">
                  <h3 className="font-semibold text-violet-900 text-sm">Adam</h3>
                  <p className="text-xs text-violet-600">I have created an order draft for you</p>
                </div>
              </div>

              <div className="mt-2 ml-10">
                <button
                  type="button"
                  className="px-2 py-1 rounded-md bg-violet-500 hover:bg-violet-600 text-white text-xs inline-flex items-center"
                  onClick={handleProcessOrder}
                >
                  Process order draft →
                </button>
              </div>
            </div>
          )}

          <div className={`mt-8 ${isAdamParticipating && isExpanded ? 'mb-16' : 'mb-4'} text-sm text-gray-900 whitespace-pre-wrap ${isExpanded ? '' : 'line-clamp-2'}`}>
            {purifiedHTMLOrText ? parse(purifiedHTMLOrText) : message.message}
          </div>

          {isExpanded && message.context?.attachments && (
            <>
              <div className="mt-8 mb-2">
                <div className="text-sm font-medium text-gray-900 font-semibold">Attachments</div>
                <div className="h-px bg-gray-200 mt-2" />
              </div>
              <div className="flex flex-wrap">
                <ThreadMessageAttachments attachments={message.context.attachments} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
