import { MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon } from '@heroicons/react/24/outline';

import { IconButtonWithTooltip } from 'components/ui';
import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';
import { DefaultButtonsProps } from './types';

const DefaultButtons = ({ zoomIn, zoomOut, imageTitle }: DefaultButtonsProps) => (
  <div
    className="flex space-x-1 rounded-s-full rounded-e-full border px-2 py-1 w-fit"
  >
    <div className="flex justify-center items-center rounded-s-full rounded-e-full px-2 max-w-80 bg-gray-100">
      <OverflowEllipsisParagraph maxLines={1} isTooltipEnabled>
        {imageTitle}
      </OverflowEllipsisParagraph>
    </div>
    <div className="flex flex-1 justify-center items-center">
      <IconButtonWithTooltip
        icon={<MagnifyingGlassPlusIcon className="aspect-square w-5" />}
        label="Zoom In"
        className="rounded-full aspect-square w-8 h-8 text-gray-700"
        onClick={() => zoomIn(0.5)}
      />

      <IconButtonWithTooltip
        icon={<MagnifyingGlassMinusIcon className="aspect-square w-5" />}
        label="Zoom Out"
        className="rounded-full aspect-square w-8 h-8 text-gray-700"
        onClick={() => zoomOut(0.5)}
      />
    </div>
  </div>
);

export default DefaultButtons;
