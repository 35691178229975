import {
  memo, useCallback, useEffect, useMemo,
} from 'react';
import { FloatingPosition } from '@mantine/core';

import { useFetchOrganizations } from 'hooks/fetch/useFetchOrganizations';
import { Select, SelectOption } from 'components/ui/Select';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { isZeroId } from 'helpers/objectId';
import { useFetchOrganizationById } from 'hooks/fetch/useFetchOrganizationById';
import { formatCustomerOption, formatSelectedCustomerOption } from './utils';
// import { useFetchSubjectById } from 'hooks/fetch/useFetchSubjectById';
// import { genericErrorFeedback } from 'helpers/errors';

interface Props {
  setError?: (error: string) => void;
  showLabel?: boolean;

  width?: number | string;
  position?: FloatingPosition;
}

const CustomerSelect = memo(({
  setError,
  showLabel = true,
  width,
  position,
}: Props) => {
  const { order, updateOrderByFn } = useProcessOrderContext();

  const {
    organizations: customers,
    isLoading: isCustomersLoading,
    loadOrganizations: loadCustomers,
    setSearchQuery,
  } = useFetchOrganizations({ preventInitialFetch: true });

  const {
    organization: customer,
    isLoading: isCustomerLoading,
    loadOrganization: loadCustomer,
  } = useFetchOrganizationById();

  useEffect(() => {
    if (!order.customer && !isZeroId(order?.createdByUserInfo?.subjectData?.organizationId)) {
      loadCustomer(order?.createdByUserInfo?.subjectData?.organizationId);
    }
  }, [order?.createdByUserInfo, order.customer, loadCustomer]);

  useEffect(() => {
    if (customer) {
      updateOrderByFn((order_) => ({
        ...order_,
        customer,
      }));
    }
  }, [customer, updateOrderByFn]);

  const customerError = useMemo(() => {
    if (isZeroId(order.createdBy)) return 'Select a customer';

    return '';
  }, [order.createdBy]);

  // Customer selection related functions
  const customerOptions = useMemo<SelectOption[]>(
    () => customers.map((c) => formatCustomerOption(c)),
    [customers],
  );

  const selectedCustomerOption = useMemo<SelectOption>(
    () => formatSelectedCustomerOption(order?.customer),
    [order?.customer],
  );

  const onCustomerSelectionChange = useCallback(
    (option: SelectOption) => {
      const _customer = customers.find((c) => c.id === option.value);
      updateOrderByFn((order_) => ({
        ...order_,
        customer: _customer,
        createdBy: _customer?.id,
        didChangeMade: true,
      }));
    },
    [customers, updateOrderByFn],
  );

  const onCustomerSearchPerformed = useCallback(
    (search: string) => {
      setSearchQuery(search);
    },
    [setSearchQuery],
  );

  const onCustomerSelectOptionsScrolledEnd = useCallback(() => {
    loadCustomers({});
  }, [loadCustomers]);

  const onDropDownOpen = useCallback(() => {
    if (!customers.length) {
      loadCustomers({ reset: true });
    }
  }, [loadCustomers, customers]);

  useEffect(() => {
    setError?.(customerError);
  }, [customerError, setError]);

  return (
    <Select
      label={showLabel ? 'Customer' : null}
      placeholder="Select a customer"
      searchPlaceholder="Type here to search customers..."
      dropdownPlaceHolder="Type a customer name or keyword to search"
      emptyPlaceholder="No customers found"
      error={setError ? customerError : null}
      selectedOption={selectedCustomerOption}
      options={customerOptions}
      isLoading={isCustomersLoading || isCustomerLoading}
      isExternalSearchEnabled
      onSelectionChange={onCustomerSelectionChange}
      onSearchPerformed={onCustomerSearchPerformed}
      onScrolledEnd={onCustomerSelectOptionsScrolledEnd}
      onDropdownOpen={onDropDownOpen}
      width={width}
      position={position}
    />
  );
});

export default CustomerSelect;
