import { Expose } from 'class-transformer';
import { Business } from './Business';
import { Workflow, OrderParserMessageContext as OrderWorkflowMessageContext } from './Workflow';

class BaseMessage {
  message: string;
}

class BaseFirstMessage {
  message: string;

  businessSentTo: string;
}

class Attachment {
  type: AttachmentType;

  name: string;

  url: string;

  size: number;

  contentType?: string;

  transcription?: string;

  isInline: boolean;

  intents: Record<string, boolean>;

  imagifiedPages?: Attachment[]; // For documents, these are the imagified pages
}

enum AttachmentType {
  AUDIO = 0,
  IMAGE = 1,
  PDF = 2,
  EXCEL = 3,
  HTML = 4,
  OTHER = 5,
  // Needed for messages migrated from v0 to v1 since we did not know the original attachment type
  DOCUMENT_UNKNOWN = 6,
  AUDIO_TRANSCRIPTION = 7,
  LEGACY_EXCEL = 8,
  WORD = 9,
  LEGACY_WORD = 10,
}

namespace AttachmentType {
  export function isAudio(attachmentType: AttachmentType): boolean {
    return (
      attachmentType === AttachmentType.AUDIO
    );
  }

  export function isDocument(attachmentType: AttachmentType): boolean {
    return (
      attachmentType === AttachmentType.IMAGE
      || attachmentType === AttachmentType.PDF
      || attachmentType === AttachmentType.EXCEL
      || attachmentType === AttachmentType.LEGACY_EXCEL
      || attachmentType === AttachmentType.WORD
      || attachmentType === AttachmentType.LEGACY_WORD
      || attachmentType === AttachmentType.HTML
      || attachmentType === AttachmentType.DOCUMENT_UNKNOWN
    );
  }
}

enum MessageType {
  TEXT = 'text',
  EMAIL = 'email',
  VOICE = 'voice',
}

enum MessageChannel {
  INTERNAL = 'internal',
  IMAP = 'imap',
  SMTP = 'smtp',
  VOICE_BRIDGE = 'voice_bridge',
}

enum MessageRefType {
  ORDER = 'ORDER',
  ORDER_GROUP = 'ORDER_GROUP',
}

class MessageRefAction {
  details: { [key: string]: string };

  type: string;
}

enum MessageIntent {
  ORDER = 'order',
  OTHER = 'other',
}

enum MessageStatus {
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
}

class Message {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'business_sent_by' })
    businessSentBy: string;

  @Expose({ name: 'business_sent_by_info' })
    businessSentByInfo?: Business;

  @Expose({ name: 'business_sent_to' })
    businessSentTo: string; // DEV-714: TODO(ntauth): Remove, use businessesSentToIds instead

  @Expose({ name: 'business_sent_to_ids' })
    businessSentToIds: string[];

  @Expose({ name: 'chat_id_v2' })
    chatIdV2: string;

  @Expose({ name: 'created_at' })
    createdAt: string;

  @Expose({ name: 'original_created_at' })
    originalCreatedAt: string;

  @Expose({ name: 'message' })
    message: string;

  @Expose({ name: 'message_type' })
    messageType: MessageType;

  @Expose({ name: 'source' })
    source: MessageChannel;

  @Expose({ name: 'ref' })
    ref: string;

  @Expose({ name: 'ref_type' })
    refType: MessageRefType;

  @Expose({ name: 'read_at' })
    readAt: string;

  @Expose({ name: 'user_sent_by' })
    userSentBy: string;

  @Expose({ name: 'user_sent_to' })
    userSentTo: string; // DEV-714: TODO(ntauth): Remove, use userSentToIds instead

  @Expose({ name: 'user_sent_to_ids' })
    userSentToIds: string[];

  @Expose({ name: 'context' })
    context: MessageContext;

  @Expose({ name: 'workflow_runs' })
    workflowRuns?: Record<Workflow, string>;

  @Expose({ name: 'intents' })
    intents: MessageIntent[];

  @Expose({ name: '__version' })
    __version?: string;

  @Expose({ name: 'message_status' })
    messageStatus?: MessageStatus;

  @Expose({ name: 'is_added' })
    isAdded?: boolean;

  // temporary field for a simple thinking message
  @Expose({ name: 'is_adam_thinking' })
    isAdamThinking?: boolean;

  // Linked external message
  @Expose({ name: 'imap_message' })
    imapMessage?: IMAPMessage;

  @Expose({ name: 'smtp_message' })
    smtpMessage?: SMTPMessage;

  constructor(
    id: string,
    chatId: string,

    businessSentBy: string,
    businessSentTo: string,
    userSentBy: string,
    userSentTo: string,

    message: string,
    messageType: MessageType,
    source: MessageChannel,

    ref: string,
    refType: MessageRefType,
    context: MessageContext,

    readAt: string,
    createdAt: string,
    originalCreatedAt: string,
    workflowRuns?: Record<Workflow, string>,
    __version?: string,
    messageStatus?: MessageStatus,
    isAdamThinking?: boolean,
    isAdded?: boolean,
  ) {
    this.businessSentBy = businessSentBy;
    this.businessSentTo = businessSentTo;
    this.businessSentToIds = [businessSentTo];
    this.userSentBy = userSentBy;
    this.userSentTo = userSentTo;
    this.userSentToIds = [userSentTo];
    this.chatIdV2 = chatId;
    this.createdAt = createdAt;
    this.originalCreatedAt = originalCreatedAt;
    this.id = id;
    this.message = message;
    this.messageType = messageType;
    this.source = source;
    this.ref = ref;
    this.refType = refType;
    this.readAt = readAt;
    this.userSentBy = userSentBy;
    this.userSentTo = userSentTo;
    this.context = context;
    this.workflowRuns = workflowRuns;
    this.__version = __version;
    this.messageStatus = messageStatus || MessageStatus.SENT;
    this.isAdamThinking = isAdamThinking;
    this.isAdded = isAdded;
  }
}

enum ExternalMessageStatus {
  PENDING = 'pending',
  SENT = 'sent',
  FAILED = 'failed',
}

class OutboundExternalMessageBase {
  status: ExternalMessageStatus;
}

class IMAPMessage {
  raw: string;
}

class SMTPMessage extends OutboundExternalMessageBase {
  raw: string;

  sentWithProxyAddress: boolean;
}

class RagItem {
  dataId: string;

  content: string;

  score: number;

  dataType: 'message';

  reasoning: string;

  partOfItem: string;
}

class MessageContext {
  html?: string;

  subject?: string;

  attachments?: Attachment[];

  workflowOrder?: OrderWorkflowMessageContext;

  ragItem?: RagItem;
}

// TODO(ntauth): Need to do this instead of simple getters because we are not instantiating MessageContext pdddd.
export const MessageContextUtils = {
  audioAttachments(messageContext?: MessageContext): Attachment[] {
    return messageContext?.attachments?.filter((attachment) => attachment.type === AttachmentType.AUDIO) || [];
  },

  documentAttachments(messageContext?: MessageContext): Attachment[] {
    return messageContext?.attachments?.filter((attachment) => AttachmentType.isDocument(attachment.type)) || [];
  },

  ragAttachment(messageContext?: MessageContext): RagItem | null {
    return messageContext?.ragItem || null;
  },

  docAndPageIndexToImgIndex(attachments: Attachment[], docIndex: number, pageIndex: number): number {
    let imgIndex = 0;
    for (let i = 0; i < docIndex; i += 1) {
      const attachment = attachments[i];
      imgIndex += attachment.imagifiedPages?.length || 0;
    }

    imgIndex += pageIndex;
    return imgIndex;
  },
};

export {
  BaseMessage,
  BaseFirstMessage,
  Message,
  MessageType,
  MessageRefType,
  MessageRefAction,
  MessageChannel,
  MessageIntent,
  MessageStatus,
  ExternalMessageStatus,
  Attachment,
  AttachmentType,
  RagItem,
};
