import { AnimatePresence } from 'framer-motion';
import DefaultButtons from './DefaultButtons';
import MagicPenButtons from './MagicPenButtons';

import { DefaultButtonsProps, MagicPenButtonsProps } from './types';

interface Props {
  defaultProps?: DefaultButtonsProps;
  magicPenProps?: MagicPenButtonsProps;
}

const Buttons = ({ defaultProps, magicPenProps }: Props) => (
  <AnimatePresence>
    <div className="flex gap-2 py-1.5 px-2">
      <DefaultButtons {...defaultProps} />
      <MagicPenButtons {...magicPenProps} />
    </div>
  </AnimatePresence>
);

export default Buttons;
