import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { uniqBy } from 'lodash';

import { httpGetV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { Message } from '../../models/Message';

interface FetchMessagesByReferenceIdProps {
  referenceId: string;

  preventInitialFetch?: boolean;
}

const useFetchMessagesByReferenceId = ({
  referenceId,

  preventInitialFetch = false,
}: FetchMessagesByReferenceIdProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const messagesPaginationCursor = useRef<string | null>(null);

  const resetParams = () => {
    setMessages([]);
    messagesPaginationCursor.current = null;
  };

  const loadMessages = useCallback(
    async (giveErrorFeedback: boolean = true) => {
      if (!referenceId || endReached) return;

      setIsLoading(true);

      try {
        const response = await httpGetV1(
          `/chat/messages?reference_id=${referenceId}`,
          {
            params: { cursor: messagesPaginationCursor.current, limit: 12 },
          },
        );

        const entries = (response?.data?.result || []).reverse();
        setMessages((msgs) => uniqBy([...entries, ...msgs], 'id'));
        messagesPaginationCursor.current = response.data.cursor;

        if (!response.data.cursor || entries.length === 0) {
          setEndReached(true);
        }
      } catch (error) {
        if (giveErrorFeedback) genericErrorFeedback('An error has occured while fetching messages')(error);
      } finally {
        setIsLoading(false);
      }
    },
    [endReached, referenceId],
  );

  useEffect(() => {
    if (preventInitialFetch || endReached) return () => {};

    // Initialise messages
    resetParams();

    const controller = new AbortController();

    loadMessages();

    return () => {
      controller.abort(); // Abort the fetch when referenceId changes or component unmounts
    };
  }, [referenceId, loadMessages, preventInitialFetch, endReached]);

  useEffect(() => {
    setEndReached(false);
  }, [referenceId]);

  return {
    messages,
    setMessages,
    isLoading,
    loadMessages,
  };
};

export { useFetchMessagesByReferenceId };
