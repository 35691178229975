import { Tooltip } from 'components/ui';

interface Props {
  warnings: string[];
  children: React.ReactNode;
}

const WarningsTooltip = ({ warnings, children }: Props) => (
  <Tooltip
    label={(
      <div className="p-2">
        <p className="text-warning-500 font-medium">
          {warnings.length}
          {' '}
          warnings detected
        </p>
        <p className="text-xxs">You can ignore these warnings if you don&apos;t need them.</p>
        <ul className="list-disc pl-3 space-y-1 pt-2 text-xs">
          {warnings.map((warning) => (
            <li key={warning}>{warning}</li>
          ))}
        </ul>
      </div>
    )}
  >
    {children}
  </Tooltip>
);

export default WarningsTooltip;
