/* eslint-disable no-duplicate-case */
import { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { SyncRunStatus } from 'models/Erp';

interface Props {
  status?: SyncRunStatus;
}

const StatusBadge = ({ status }: Props) => {
  const className = useMemo(() => {
    switch (status) {
      case SyncRunStatus.Ok:
        return 'bg-green-100 text-green-800';
      case SyncRunStatus.Failed:
        return 'bg-red-100 text-red-800';
      default:
        return 'text-neutral-700 bg-neutral-100';
    }
  }, [status]);

  const statusText = useMemo(() => {
    switch (status) {
      case SyncRunStatus.Ok:
        return 'SUCCESS';
      case SyncRunStatus.Failed:
        return 'FAILED';
      default:
        return '-';
    }
  }, [status]);

  const statusIcon = useMemo(() => {
    switch (status) {
      case SyncRunStatus.Ok:
        return <CheckCircleIcon className="h-4 w-4" />;
      case SyncRunStatus.Failed:
        return <ExclamationCircleIcon className="h-4 w-4" />;
      default:
        return null;
    }
  }, [status]);

  return (
    <div
      className={twMerge(
        'inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-sm px-sm py-xs text-xs font-semibold min-w-[90px] justify-center',
        className,
      )}
    >
      {statusIcon}
      {statusText}
    </div>
  );
};

export { StatusBadge };
