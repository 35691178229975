import {
  useCallback, useState,
} from 'react';
import { LoadingOverlay, ScrollArea } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'config/routes';

import { useThreadsContext } from 'contexts/useThreadsContext';
import { Thread } from 'models/Thread';
import { useMarkThreadsAsUnread } from 'hooks/useMarkThreadsAsUnread';
import { useMarkThreadsAsRead } from 'hooks/useMarkThreadsAsRead';
import { isZeroTime, zeroTime } from 'helpers/dateTime';
import TableRow from './TableRow';
import TableHeader from './TableHeader';

const ListTable = () => {
  const navigate = useNavigate();

  const {
    threads,
    setThreads,
    isLoading: isThreadsLoading,
    threadsFilter,
    setThreadsFilter,
    loadThreads,
  } = useThreadsContext();

  const {
    markThreadsAsRead: _markThreadsAsRead,
    isLoading: isMarkThreadsAsReadLoading,
  } = useMarkThreadsAsRead();

  const {
    markThreadsAsUnread: _markThreadsAsUnread,
    isLoading: isMarkThreadsAsUnreadLoading,
  } = useMarkThreadsAsUnread();

  const [allSelected, setAllSelected] = useState(false);
  const [selectedThreads, setSelectedThreads] = useState<Thread[]>([]);

  const toggleAll = useCallback(() => {
    setSelectedThreads((prev) => (prev.length > 0 ? [] : threads));
    setAllSelected((prev) => !prev);
  }, [threads]);

  const reload = useCallback(() => {
    loadThreads(true);
  }, [loadThreads]);

  const onThreadRowClick = useCallback(
    (thread: Thread, index: number) => {
      navigate(ROUTES.THREAD_BY_ID(thread.id), {
        state: {
          threads,
          currentIndex: index,
        },
      });
    },
    [navigate, threads],
  );

  const markThreadsAsRead = useCallback((threadsToMarkAsRead: Thread[]) => {
    if (threadsToMarkAsRead.length === 0) {
      return;
    }

    _markThreadsAsRead(threadsToMarkAsRead.map((t) => t.id)).then(() => {
      if (threadsFilter.unreadOnly) {
        setThreads((prev) => prev.filter((t) => !selectedThreads.includes(t)),
        );
      } else {
        setThreads((prev) => prev.map((t) => ({
          ...t,
          readAt:
              selectedThreads.map((st) => st.id).includes(t.id)
                ? new Date().toISOString()
                : t.readAt,
        })),
        );
      }
      setSelectedThreads([]);
    });
  }, [
    _markThreadsAsRead,
    threadsFilter.unreadOnly,
    selectedThreads,
    setThreads,
  ]);

  const markThreadsAsUnread = useCallback((threadsToMarkAsUnread: Thread[]) => {
    if (threadsToMarkAsUnread.length === 0) {
      return;
    }

    _markThreadsAsUnread(threadsToMarkAsUnread.map((t) => t.id)).then(() => {
      setThreads((prev) => prev.map((t) => ({
        ...t,
        readAt: selectedThreads.map((st) => st.id).includes(t.id) ? zeroTime : t.readAt,
      })));
      setSelectedThreads([]);
    });
  }, [
    _markThreadsAsUnread,
    selectedThreads,
    setThreads,
  ]);

  const toggleThreadsReadStatus = useCallback(() => {
    markThreadsAsRead(selectedThreads.filter((t) => isZeroTime(t.readAt)));
    markThreadsAsUnread(selectedThreads.filter((t) => !isZeroTime(t.readAt)));
  }, [markThreadsAsRead, markThreadsAsUnread, selectedThreads]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-md border border-solid">
      <ScrollArea.Autosize className="relative" onBottomReached={loadThreads}>
        {(isMarkThreadsAsReadLoading || isMarkThreadsAsUnreadLoading) && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/50">
            <LoadingOverlay
              visible={isMarkThreadsAsReadLoading || isMarkThreadsAsUnreadLoading}
              loaderProps={{ type: 'dots' }}
              overlayProps={{ blur: 2 }}
            />
          </div>
        )}
        <TableHeader
          allSelected={allSelected}
          toggleAll={toggleAll}
          reload={reload}
          toggleThreadsReadStatus={toggleThreadsReadStatus}
          selectedThreads={selectedThreads}
          threadsFilter={threadsFilter}
          setThreadsFilter={setThreadsFilter}
        />
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="divide-y divide-gray-200 bg-white">
            {threads.map((thread, index) => (
              <TableRow
                key={thread.id}
                thread={thread}
                selectedThreads={selectedThreads}
                setSelectedThreads={setSelectedThreads}
                onThreadRowClick={() => onThreadRowClick(thread, index)}
              />
            ))}
          </tbody>
        </table>
        <div className="relative flex h-10 w-full items-center justify-center">
          <LoadingOverlay
            visible={isThreadsLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollArea.Autosize>
    </div>
  );
};

export default ListTable;
