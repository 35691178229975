enum PhoneType {
  Unknown = 'unknown',
  Landline = 'landline',
  Mobile = 'mobile',
}

class Phone {
  uiId: string;

  number: string;

  isDefault: boolean;

  type: PhoneType;
}

export { Phone, PhoneType };
