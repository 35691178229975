import {
  object, string,
} from 'yup';

import { useState } from 'react';
import { LoadingOverlay } from '@mantine/core';

import { httpPatchV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { Subject } from 'models/User';
import { _Header as Header } from './_Header';
import { _Body as Body } from './_Body';
import { _Footer as Footer } from './_Footer';
import { SidebarBase } from '../../SidebarBase';
import { globalAlertData } from '../../../../state/globalAlertData';
import { FormProvider } from '../../../../contexts/useFormContext';

const subjectFormValidationSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().email('Invalid email address'),
  phone: string(),
});

interface Props {
  subject: Subject;
  _onComplete: (subject: Subject) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const _Sidebar = ({
  subject,
  _onComplete,
  sidebarOpen,
  setSidebarOpen,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FormProvider
      initialValues={{
        externalId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }}
      validationSchema={subjectFormValidationSchema}
      onSubmit={(values) => {
        setIsLoading(true);
        httpPatchV1(`/businesses/me/subjects/${subject.id}`, values)
          .then((response) => {
            const data = response.data as Subject;
            _onComplete(data);
            globalAlertData.create('Subject saved successfully');
          })
          .catch(genericErrorFeedback)
          .finally(() => {
            setIsLoading(false);
          });
      }}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    >
      <LoadingOverlay
        visible={!subject && sidebarOpen}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />

      <SidebarBase
        header={<Header />}
        body={<Body subject={subject} />}
        footer={<Footer isLoading={isLoading} />}
        sidebarWidth="max-w-[30vw]"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </FormProvider>
  );
};

export { _Sidebar };
