import { twMerge } from 'tailwind-merge';
import { motion, Variants } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEraser, faMagicWandSparkles,
} from '@fortawesome/free-solid-svg-icons';
import { faHand } from '@fortawesome/free-regular-svg-icons';

import { IconButtonWithTooltip } from 'components/ui';

import { useCallback, useEffect } from 'react';
import { MagicPenButtonsProps } from './types';
import { Tool } from '../KonvaStage/type';

const parentVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  exit: { opacity: 0, transition: { staggerChildren: 0.1 } },
};

const childrenVariant: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  exit: { opacity: 0, x: 50, transition: { duration: 0.5 } },
};

type IconWithTooltipProps = {
  icon: React.ReactNode;
  label: Tool;
  shortcut?: string;
  className?: string;
  onClick?: () => void;
};

const MagicPenButtons = ({
  selectedTool, setSelectedTool,
}: MagicPenButtonsProps) => {
  const buttons: IconWithTooltipProps[] = [
    {
      icon: <FontAwesomeIcon icon={faHand} />, label: 'Hand', shortcut: 'h',
    },
    {
      icon: <FontAwesomeIcon icon={faMagicWandSparkles} />, label: 'Magic Pen', shortcut: 'p', className: 'text-indigo-500',
    },
    {
      icon: <FontAwesomeIcon icon={faEraser} />, label: 'Eraser', shortcut: 'e', className: 'text-red-500',
    },
  ];

  const buttonClickHandler = (label: Tool, onClick?: () => void) => {
    setSelectedTool(label);

    onClick?.();
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'h') {
      setSelectedTool('Hand');
    } else if (event.key === 'p') {
      setSelectedTool('Magic Pen');
    } else if (event.key === 'e') {
      setSelectedTool('Eraser');
    }
  }, [setSelectedTool]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <motion.div
      key="magic-pen-buttons"
      variants={parentVariant}
      initial="initial"
      animate="animate"
      className="flex space-x-1 rounded-s-full rounded-e-full border px-2 py-1 w-fit"
    >
      {
          buttons.map((button) => (
            <motion.div variants={childrenVariant} key={button.label}>
              <IconButtonWithTooltip
                icon={button.icon}
                label={button.label + (button.shortcut ? ` (${button.shortcut})` : '')}
                className={twMerge(
                  button.className,
                  'rounded-full aspect-square w-8 h-8',
                  selectedTool === button.label && 'bg-gray-200',
                )}
                onClick={() => buttonClickHandler(button.label, button.onClick)}
              />
            </motion.div>
          ))
        }
    </motion.div>
  );
};

export default MagicPenButtons;
