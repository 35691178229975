import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  isNil, omitBy, uniqBy,
} from 'lodash';

import { httpGetV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { convertKeysToSnakeCase } from 'helpers/mapping';
import { Message, MessageIntent, MessageChannel } from '../../models/Message';

interface FetchMessagesProps {
  preventInitialFetch?: boolean;
  defaultFilter?: MessagesFilter;
}

type MessagesFilter = {
  intent?: MessageIntent;
  sources?: MessageChannel[];
  unreadOnly?: boolean;
  includeBusinessSentBy?: boolean;
};

const useFetchMessages = ({
  preventInitialFetch = false,
  defaultFilter,
}: FetchMessagesProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialFetchRef = useRef(true);
  const endReachedRef = useRef(false);
  const messagesPaginationCursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const [messagesFilter, setMessagesFilter] = useState<MessagesFilter>(defaultFilter || {});

  const resetRefs = () => {
    endReachedRef.current = false;
    messagesPaginationCursor.current = null;
  };

  const loadMessages = useCallback(
    async (reset: boolean = false, giveErrorFeedback: boolean = true) => {
      if (reset) {
        setMessages([]);
        resetRefs();
      } else if (endReachedRef.current) return;

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);

      try {
        const response = await httpGetV1('/chat/messages', {
          params: {
            cursor: messagesPaginationCursor.current,
            sources: [MessageChannel.IMAP],
            limit: 20,
            ...convertKeysToSnakeCase(omitBy(messagesFilter, isNil)), // exclude all null values
          },
          signal: controller.signal,
        });

        const entries = response?.data?.result || [];
        if (reset) {
          setMessages(entries);
        } else {
          setMessages((msgs) => uniqBy([...msgs, ...entries], 'id'));
        }
        messagesPaginationCursor.current = response.data.cursor;

        if (!response.data.cursor || entries.length === 0) {
          endReachedRef.current = true;
        }
      } catch (error) {
        console.error('Failed to fetch messages:', error.name);
        if (giveErrorFeedback && error.name !== 'AbortError' && error.name !== 'CanceledError') {
          genericErrorFeedback('An error has occured while fetching messages')(error);
        }
      } finally {
        if (!controller.signal.aborted) {
          setIsLoading(false);
        }
        currentAbortController.current = null;
      }
    },
    [messagesFilter],
  );

  useEffect(() => {
    if (preventInitialFetch) {
      initialFetchRef.current = false;
      return () => {};
    }

    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      loadMessages();
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadMessages, preventInitialFetch]);

  useEffect(() => {
    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      return;
    }

    loadMessages(true);
  }, [messagesFilter, loadMessages]);

  return {
    messages,
    setMessages,
    messagesFilter,
    setMessagesFilter,
    isLoading,
    loadMessages,
  };
};

export { useFetchMessages, MessagesFilter };
