import { Editor } from '@tiptap/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold, faItalic, faListOl, faListUl, faStrikethrough, faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { ActionIcon } from '@mantine/core';
import { TextSize, TextAlign } from './utils';

interface Props {
  editor: Editor;
}

const MenuBar = ({ editor }: Props) => {
  if (!editor) {
    return null;
  }
  return (
    <div className="flex w-full items-center gap-1 px-4 py-1 border-b">
      <TextSize editor={editor} />

      <ActionIcon
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
        variant="transparent"
        color={editor.isActive('bold') ? 'blue' : 'gray'}
      >
        <FontAwesomeIcon icon={faBold} />
      </ActionIcon>

      <ActionIcon
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
        variant="transparent"
        color={editor.isActive('italic') ? 'blue' : 'gray'}
      >
        <FontAwesomeIcon icon={faItalic} />
      </ActionIcon>

      <ActionIcon
        type="button"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          }
        variant="transparent"
        color={editor.isActive('underline') ? 'blue' : 'gray'}
      >
        <FontAwesomeIcon icon={faUnderline} />
      </ActionIcon>

      <ActionIcon
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
        variant="transparent"
        color={editor.isActive('strike') ? 'blue' : 'gray'}
      >
        <FontAwesomeIcon icon={faStrikethrough} />
      </ActionIcon>

      <TextAlign editor={editor} />

      <ActionIcon
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBulletList()
              .run()
          }
        variant="transparent"
        color={editor.isActive('bulletList') ? 'blue' : 'gray'}
      >
        <FontAwesomeIcon icon={faListUl} />
      </ActionIcon>

      <ActionIcon
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleOrderedList()
              .run()
          }
        variant="transparent"
        color={editor.isActive('orderedList') ? 'blue' : 'gray'}
      >
        <FontAwesomeIcon icon={faListOl} />
      </ActionIcon>
    </div>
  );
};

export default MenuBar;
