import { ClassConstructor, plainToInstance as _plainToInstance, instanceToPlain as _instanceToPlain } from 'class-transformer';
import { validate } from 'class-validator';

import { isObject } from './comparison';

function plainToInstance<T, V>(cls: ClassConstructor<T>, plain: V): T {
  return _plainToInstance(cls, plain);
}

function instanceToPlain<T>(instance: T): any {
  return _instanceToPlain(instance);
}

async function plainToValidInstance<T, V>(cls: ClassConstructor<T>, plain: V): Promise<T> {
  return new Promise((resolve, reject) => {
    const instance = plainToInstance(cls, plain);
    validate(instance as object)
      .then((errors) => {
        if (errors.length === 0) {
          resolve(instance);
        } else {
          reject(errors);
        }
      })
      .catch((error: unknown) => {
        reject(error);
      });
  });
}

const convertKeysToSnakeCase = (data: any): any => {
  if (!isObject(data)) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(convertKeysToSnakeCase);
  }
  const remapped: { [key: string]: any } = {};
  Object.keys(data).forEach((key) => {
    const leadingUnderscores = key.match(/^_+/)?.[0] || '';
    // This also takes care of keys such as "text_field_1"
    const newKey = leadingUnderscores + key.slice(leadingUnderscores.length)
      .replace(/([a-z])([A-Z0-9])/g, '$1_$2')
      .replace(/([A-Z0-9])([A-Z][a-z])/g, '$1_$2')
      .toLowerCase();
    remapped[newKey] = convertKeysToSnakeCase(data[key]);
  });
  return remapped;
};

const convertKeysToCamelCase = (data: any): any => {
  if (!isObject(data)) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(convertKeysToCamelCase);
  }
  const remapped: { [key: string]: any } = {};
  Object.keys(data).forEach((key) => {
    // Preserve leading underscores for reserved attributes (e.g., __version)
    const leadingUnderscores = key.match(/^_+/)?.[0] || '';
    const newKey = leadingUnderscores + key.replace(/[^a-zA-Z0-9]+(.)/g, (_, letter) => letter.toUpperCase()).replace(/^[A-Z]/, (c) => c.toLowerCase());
    remapped[newKey] = convertKeysToCamelCase(data[key]);
  });
  return remapped;
};

export {
  convertKeysToCamelCase, convertKeysToSnakeCase, plainToValidInstance, plainToInstance, instanceToPlain,
};
