import { useEffect } from 'react';
import { useFormContext } from '../../../../contexts/useFormContext';
import { Business } from '../../../../models/Business';
import { Phone } from '../../../../models/Phone';
import { FormInput } from '../../../FormInput';
import { FormInputPhones } from '../../../FormInputPhones';

interface Props {
  customer: Business;
}

const _Body = ({ customer }: Props) => {
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormContext<{
    name: string;
    externalId: string;
    address: string;
    email: string;
    phones: Phone[];
  }>();

  useEffect(() => {
    if (customer) {
      setValues({
        name: customer.name,
        externalId: customer.externalId,
        address: customer.address,
        email: customer.email,
        phones: customer.phones,
      });
    }
  }, [customer, setValues]);

  return (
    <div className="flex flex-col gap-5 px-1 py-3">
      <FormInput
        required
        field="name"
        label="Name"
        value={values.name}
        error={errors.name}
        touched={touched.name}
        onChange={handleChange('name')}
        onBlur={handleBlur('name')}
      />

      <FormInput
        field="externalId"
        label="Customer ID"
        value={values.externalId}
        error={errors.externalId}
        touched={touched.externalId}
        onChange={handleChange('externalId')}
        onBlur={handleBlur('externalId')}
      />

      <FormInput
        field="address"
        label="Address"
        value={values.address}
        error={errors.address}
        touched={touched.address}
        onChange={handleChange('address')}
        onBlur={handleBlur('address')}
      />

      <FormInput
        field="email"
        label="Email"
        value={values.email}
        error={errors.email}
        touched={touched.email}
        onChange={handleChange('email')}
        onBlur={handleBlur('email')}
      />

      <FormInputPhones
        field="phones"
        label="Phones"
        values={values.phones}
        errors={errors.phones}
        touched={touched.phones}
        setFieldValue={setFieldValue}
        onBlur={handleBlur('phones')}
      />
    </div>
  );
};

export { _Body };
