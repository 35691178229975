import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import {
  format,
  differenceInSeconds,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';
import { useDebouncedCallback } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';

import { genericErrorFeedback } from 'helpers/errors';
import { isZeroTime } from 'helpers/dateTime';
import { twMerge } from 'tailwind-merge';
import { Message } from 'models/Message';
import { Order } from 'features/order/models/Order';
import { Event } from '../../../models/Event';
import { Operation } from '../../../models/Lro';
import { ScrollAreaWrapper } from '../../wrapper/ScrollAreaWrapper';
import { globalNotifications } from '../../../state/globalNotifications';

const getTitle = (event: Event) => {
  switch (event.type) {
    case 'new_chat':
      return 'New chat created';
    case 'new_order': {
      const order = event.getDataInstanceOf(Order);
      return `New order placed by ${order.createdByUserInfo?.email || order.createdByUserInfo?.phone}`;
    }
    case 'smtp_message_failed': {
      const msg = event.getDataInstanceOf(Message);
      return `Failed to send email ${msg?.id}`;
    }
    case 'lro_done': {
      const lro = event.getDataInstanceOf(Operation);
      switch (lro?.group) {
        case 'workflow':
          return 'Workflow run completed';
        default:
          return 'Operation completed';
      }
    }
    case 'failed_order_sync':
      return 'Failed to sync order to ERP';
    default:
      break;
  }

  return '';
};

const getEventTime = (event: Event) => {
  const eventTime = new Date(event.createdAt);
  const now = new Date();
  const diffInSeconds = differenceInSeconds(now, eventTime);

  if (diffInSeconds < 10) {
    return 'Just now';
  }

  const diffInDays = differenceInDays(now, eventTime);
  const diffInMonths = differenceInMonths(now, eventTime);
  const diffInYears = differenceInYears(now, eventTime);

  if (diffInDays === 0) {
    return format(eventTime, 'HH:mm');
  }
  if (diffInDays === 1) {
    return 'Yesterday';
  }
  if (diffInDays <= 30) {
    return `${diffInDays} days ago`;
  }
  if (diffInMonths <= 12) {
    return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
  }
  return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
};

interface Props {
  showBadge?: boolean;
}

const NotificationBell = observer(({ showBadge = true }: Props) => {
  const notifications = globalNotifications.notifications;
  const unreadCount = globalNotifications.unreadCount;
  const displayCount = useMemo(() => (unreadCount > 9 ? '9+' : unreadCount?.toString()), [unreadCount]);

  const markEventsAsRead = useCallback(
    (eventIds: string[]) => {
      try {
        globalNotifications.markAsRead(eventIds);
      } catch (error) {
        genericErrorFeedback('Failed to mark notifications as read')(error);
      }
    },
    [],
  );

  const debouncedMarkEventsAsRead = useDebouncedCallback(markEventsAsRead, 1000);

  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const seenEvents = new Set<string>();
    const currentObserver = new IntersectionObserver(
      (entries) => {
        const newlySeenEvents = entries
          .filter((entry) => entry.isIntersecting)
          .map((entry) => entry.target.getAttribute('data-event-id'))
          .filter((id): id is string => id !== null)
          .filter((id) => !seenEvents.has(id));

        if (newlySeenEvents.length > 0) {
          newlySeenEvents.forEach((id) => seenEvents.add(id));
          debouncedMarkEventsAsRead(newlySeenEvents);
        }
      },
      {
        threshold: 0.5,
        root: document.querySelector('.scroll-area-wrapper'),
      },
    );

    observerRef.current = currentObserver;

    notifications.forEach((notification) => {
      const element = document.querySelector(`[data-event-id="${notification.id}"]`);
      if (element) {
        currentObserver.observe(element);
      }
    });

    return () => {
      currentObserver.disconnect();
      seenEvents.clear();
    };
  }, [notifications, debouncedMarkEventsAsRead]);

  const observeNotification = useCallback((element: HTMLElement | null) => {
    if (element && observerRef.current) {
      observerRef.current.unobserve(element);
      observerRef.current.observe(element);
    }
  }, []);

  useEffect(() => {
    globalNotifications.loadNotifications();
    globalNotifications.loadCount();
  }, []);

  const prevCount = useRef(unreadCount);
  const badgeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (unreadCount !== prevCount.current && badgeRef.current) {
      badgeRef.current.classList.add('scale-125');

      setTimeout(() => {
        badgeRef.current?.classList.remove('scale-125');
      }, 200);

      prevCount.current = unreadCount;
    }
  }, [unreadCount]);

  return (
    <Popover className="relative">
      <PopoverButton className="group flex cursor-pointer items-center gap-smd rounded-md p-smd text-sm leading-6 text-gray-600 outline-none ring-0 hover:bg-gray-50">
        <div className="relative">
          <BellIcon
            width={24}
            height={24}
            className="text-[rgba(107,114,128,1)]"
          />
          {showBadge && (
            <span
              ref={badgeRef}
              className="absolute -right-2 -top-2 flex min-w-[18px] items-center justify-center rounded-full bg-danger-500 px-1 text-xs text-white transition-transform duration-200"
            >
              {displayCount}
            </span>
          )}
        </div>
      </PopoverButton>

      <PopoverPanel className="absolute left-1/2 z-10 mt-1 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
        <div className="w-[300px] flex-auto overflow-hidden rounded-sm border border-neutral-50 bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
          <ScrollAreaWrapper
            maxVh={0.5}
            className="w-full py-2"
            onScrolledEnd={() => globalNotifications.loadNotifications()}
            // NOTE(ntauth): Disabled because it messes up padding calculations and the centering of the children
            offsetScrollbar={false}
          >
            <div className="space-y-1 px-2">
              {notifications.map((event) => (
                <div
                  key={event.id}
                  data-event-id={event.id}
                  ref={observeNotification}
                  className={twMerge(
                    'group relative flex max-w-md cursor-pointer gap-x-2 rounded-sm px-1 py-2 hover:bg-gray-50',
                    isZeroTime(event.consumedAt) && 'bg-primary-50',
                  )}
                >
                  <div className="flex items-start">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-neutral-100">
                      <p className="text-lg font-bold text-white">
                        {event.topic[0].toUpperCase()}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 overflow-hidden">
                    {/* <p className='text-neutral-900 font-semibold'>{getTitle(event)}</p> */}
                    <p className="line-clamp-2 w-full max-w-full overflow-hidden text-ellipsis leading-tight text-neutral-800">
                      {getTitle(event)}
                    </p>
                    <p className="text-xs text-neutral-300">
                      {getEventTime(event)}
                    </p>
                  </div>

                  {isZeroTime(event.consumedAt) && (
                    <span className="absolute right-0 top-0 flex h-full items-center p-1">
                      <span className="aspect-square w-2 rounded-full bg-primary-500" />
                    </span>
                  )}
                </div>
              ))}
            </div>

            <div className="hidden bg-gray-50 p-2">
              <div className="flex justify-end">
                <p className="text-primary-600">Mark as read</p>
              </div>
            </div>
          </ScrollAreaWrapper>
        </div>
      </PopoverPanel>
    </Popover>
  );
});

export { NotificationBell };
