import { createContext, useContext } from 'react';
import { Action, Workflow } from '../types/instruction';
import { Prompt } from '../features/instruction/models/Prompt';

// For order drafts. To highlight on hover or scroll on click keyword.
type InstructionContextType = {
  selectedWorkflow: Workflow;
  setSelectedWorkflow: React.Dispatch<React.SetStateAction<Workflow>>;
  selectedAction: Action;
  setSelectedAction: React.Dispatch<React.SetStateAction<Action>>;
  prompt: Prompt;
  setPrompt: React.Dispatch<React.SetStateAction<Prompt>>;
  instructionName: string;
  setInstructionName: React.Dispatch<React.SetStateAction<string>>;
};

const InstructionContext = createContext<InstructionContextType | undefined>(undefined);

const useInstructionContext = () => {
  const context = useContext(InstructionContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by InstructionContext provider.');
  }

  return context;
};

export { InstructionContext, useInstructionContext };
