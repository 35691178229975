import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { SubjectListPage } from 'features/subject/pages';

const SubjectListScreen = () => (
  <ProtectedScreen>
    <SubjectListPage />
  </ProtectedScreen>
);

export default SubjectListScreen;
