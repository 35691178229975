/* eslint-disable no-duplicate-case */
import { useMemo } from 'react';

import { ArrowLeftIcon, ArrowRightIcon, ArrowsRightLeftIcon } from '@heroicons/react/20/solid';

import { SyncDirection } from 'models/Erp';

interface Props {
  direction: SyncDirection;
}

const DirectionBadge = ({ direction }: Props) => {
  const directionText = useMemo(() => {
    switch (direction) {
      case SyncDirection.FromERP:
        return 'FROM ERP';
      case SyncDirection.ToERP:
        return 'TO ERP';
      case SyncDirection.FromToERP:
        return 'BOTH';
      default:
        return '-';
    }
  }, [direction]);

  const directionIcon = useMemo(() => {
    switch (direction) {
      case SyncDirection.FromERP:
        return <ArrowRightIcon className="h-4 w-4" />;
      case SyncDirection.ToERP:
        return <ArrowLeftIcon className="h-4 w-4" />;
      case SyncDirection.FromToERP:
        return <ArrowsRightLeftIcon className="h-4 w-4" />;
      default:
        return null;
    }
  }, [direction]);

  return (
    <div
      className="inline-flex items-center gap-1.5 px-0.5 py-0.5 rounded-sm px-sm py-xs text-xs font-semibold bg-gray-100 text-gray-600 min-w-[100px] justify-center"
    >
      {directionIcon}
      {directionText}
    </div>
  );
};

export { DirectionBadge };
