import { ProductWithQuantity } from 'features/order/models/Order';

import { ProductSelect } from 'features/order/components/process-order-draft/form-elemts/ProductSelect';
import { UnitSelect } from 'features/order/components/process-order-draft/form-elemts/UnitSelect';

import { FieldSpec } from 'features/instruction/models/Schema';
import { useCallback } from 'react';
import { Product, Unit } from 'models/Product';

import { FieldPathWrapper } from 'features/order/components/process-order-draft/utils';
import { ProductQuantityInputWithPopup } from 'features/order/components/process-order-draft/form-elemts';

const WrappedProductSelect = FieldPathWrapper(ProductSelect);
const WrappedUnitSelect = FieldPathWrapper(UnitSelect);

interface Props {
  product: ProductWithQuantity;
  customerId: string;
  productNameField: FieldSpec | null;
  productQuantityField: FieldSpec | null;
  productUnitField: FieldSpec | null;

  onProductSelectionChange: (p: Product) => void;
  onProductQuantityChange: (val: number) => void;
  onProductUnitChange: (newUnit: Unit) => void;

  setError: (error: string, path: string) => void;

  quantityPopupContent: {
    prevQuantity: number;
    newQuantity: number;
  } | null;
  onQuantityPopupConfirmed: () => void;
  onQuantityPopupCanceled: () => void;
}

const CollapsedContent = ({
  product, customerId, productNameField, productQuantityField, productUnitField,
  onProductSelectionChange, onProductQuantityChange, onProductUnitChange,
  setError,
  quantityPopupContent,
  onQuantityPopupConfirmed,
  onQuantityPopupCanceled,
}: Props) => {
  const setUnitError = useCallback((error: string) => {
    setError(error, productUnitField?.path);
  }, [setError, productUnitField?.path]);

  const setError_ = useCallback((error: string) => {
    setError(error, 'product-name');
  }, [setError]);

  return (
    <div className="flex gap-2 pt-2">
      <div className="w-[380px] 2xl:w-[500px]">
        <WrappedProductSelect
          isProductField
          fieldPath={productNameField?.path}
          fieldId={product.positionId}
          businessId={customerId}
          productWithQuantity={product}
          onProductChange={onProductSelectionChange}
          setError={setError_}
        />
      </div>

      <div className="flex">
        <ProductQuantityInputWithPopup
          productQuantityField={productQuantityField}
          product={product}
          onProductQuantityChange={onProductQuantityChange}
          quantityPopupContent={quantityPopupContent}
          onQuantityPopupCanceled={onQuantityPopupCanceled}
          onQuantityPopupConfirmed={onQuantityPopupConfirmed}
          isInCollapsedContent
        />
        <div className="w-[120px]">
          <WrappedUnitSelect
            fieldPath={productUnitField?.path}
            fieldId={product.positionId}
            isProductField
            productWithQuantity={product}
            onUnitChange={onProductUnitChange}
            inputStyles={{
              error: {
                position: 'absolute',
              },
              input: {
                borderRadius: '0 var(--mantine-radius-md) var(--mantine-radius-md) 0',
              },
            }}
            setError={setUnitError}
          />
        </div>
      </div>
    </div>
  );
};

export default CollapsedContent;
