interface SearchBarProp {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

function SubjectSearchBar({ onSubmit }: SearchBarProp) {
  return (
    <form onSubmit={onSubmit} className="flex items-center space-x-3">
      <div className="flex">
        <input
          type="text"
          name="query"
          id="query"
          className="block min-w-[300px] rounded-l-smd border-0 border-blue-gray-100 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          placeholder="Name, Email or Phone"
        />
        <button
          type="submit"
          className="rounded-r-smd border border-l-0 border-blue-gray-100 bg-white py-1.5 pl-slg pr-lg text-sm text-blue-gray-200 shadow-sm"
        >
          Search
        </button>
      </div>
    </form>
  );
}

export default SubjectSearchBar;
