import { ReactNode } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';
import { ContentTheme } from '../types';
import { contentThemeToTextColor } from '../utils';

interface Props {
  additionalInfo: ReactNode;
  contentTheme: ContentTheme;
}

const AdditionalInformation = ({ additionalInfo, contentTheme }: Props) => {
  if (!additionalInfo) return null;

  return (
    <OverflowEllipsisParagraph
      maxLines={1}
      isTooltipEnabled
      customTooltipContent={additionalInfo}
    >
      <ExclamationCircleIcon className={twMerge('aspect-square w-5', contentThemeToTextColor(contentTheme))} />
    </OverflowEllipsisParagraph>
  );
};

export default AdditionalInformation;
