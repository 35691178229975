import { useState, useCallback } from 'react';

import { httpDelV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';

const useDeleteOrderDrafts = () => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteOrderDrafts = useCallback(
    async (
      ids: string[],
      createAlert: boolean = true,
      giveError: boolean = true,
    ): Promise<void> => {
      if (ids.length === 0) {
        return Promise.resolve();
      }

      setIsLoading(true);
      return httpDelV1('/orders/drafts', {
        data: {
          ids,
        },
      })
        .then(() => {
          if (createAlert) {
            globalAlertData.create('Order drafts deleted successfully');
          }

          return Promise.resolve();
        })
        .catch((error) => {
          if (giveError) {
            genericErrorFeedback('Error deleting order drafts')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return { isLoading, deleteOrderDrafts };
};

export { useDeleteOrderDrafts };
