import {
  ReactNode, createContext, useContext, useMemo,
  useState,
} from 'react';
import { Schema } from '../features/instruction/models/Schema';

type SchemasContextType = {
  schemas: Record<string, Schema>;
  setSchemas: React.Dispatch<React.SetStateAction<Record<string, Schema>>>;
};

const SchemasContext = createContext<SchemasContextType | undefined>(undefined);

type SchemasProviderProps = {
  children: ReactNode;
};

const SchemasProvider: React.FC<SchemasProviderProps> = ({
  children,
}: SchemasProviderProps) => {
  const [schemas, setSchemas] = useState<Record<string, Schema>>({});

  const contextValue = useMemo(
    () => ({
      schemas,
      setSchemas,
    }),
    [schemas, setSchemas],
  );

  return (
    <SchemasContext.Provider value={contextValue}>
      {children}
    </SchemasContext.Provider>
  );
};

const useSchemasContext = () => {
  const context = useContext(SchemasContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by SchemasContext provider.');
  }

  return context;
};

export { SchemasContext, useSchemasContext, SchemasProvider };
