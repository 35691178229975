import { isSameDay, isSameYear } from 'date-fns';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

const initDateTimeHelper = () => {
  dayjs.extend(LocalizedFormat);
};

const zeroTime = '0001-01-01T00:00:00Z';
const isZeroTime = (datetime: string) => datetime === zeroTime;

const formatDate = (date: Date) => {
  const now = new Date();
  if (isSameDay(now, date)) {
    return dayjs(date).format('h:mm A');
  }

  if (isSameYear(now, date)) {
    return dayjs(date).format('MMM D, h:mm A');
  }

  return dayjs(date).format('MMM D, YYYY, h:mm A');
};

export {
  initDateTimeHelper, isZeroTime, zeroTime, formatDate,
};
