import { Page } from 'components/layout/Page/Page';
// import { MessageListTable } from '../components/message-list';
import { ThreadListTable } from '../components/thread-list';

const InboxPage = () => (
  <Page contentWithPadding>
    <div className="flex-1 bg-white flex flex-col overflow-hidden gap-3">
      {/* <MessageListTable /> */}
      <ThreadListTable />
    </div>
  </Page>
);

export default InboxPage;
