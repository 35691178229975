import {
  array, boolean, object, string,
} from 'yup';

import { useState } from 'react';
import { LoadingOverlay } from '@mantine/core';

import { phone } from 'helpers/validations';

import { httpPatchV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { _Header as Header } from './_Header';
import { _Body as Body } from './_Body';
import { _Footer as Footer } from './_Footer';
import { Business } from '../../../../models/Business';
import { PhoneType } from '../../../../models/Phone';
import { SidebarBase } from '../../SidebarBase';
import { globalAlertData } from '../../../../state/globalAlertData';
import { FormProvider } from '../../../../contexts/useFormContext';

const customerFormValidationSchema = object().shape({
  name: string().required(),
  email: string().email('Invalid email address'),
  address: string(),
  externalId: string(),
  phones: array(
    object({
      number: phone(),
      isDefault: boolean(),
      type: string().oneOf([PhoneType.Landline, PhoneType.Mobile]),
    }),
  ),
});

interface Props {
  customer: Business;
  _onComplete: (customer: Business) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const _Sidebar = ({
  customer,
  _onComplete,
  sidebarOpen,
  setSidebarOpen,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FormProvider
      initialValues={{
        name: '',
        email: '',
        address: '',
        externalId: '',
        phones: [],
      }}
      validationSchema={customerFormValidationSchema}
      onSubmit={(values) => {
        setIsLoading(true);
        httpPatchV1(`/businesses/me/customers/${customer.id}`, values)
          .then((response) => {
            const data = response.data as Business;
            _onComplete(data);
            globalAlertData.create('Customer saved successfully');
          })
          .catch(genericErrorFeedback)
          .finally(() => {
            setIsLoading(false);
          });
      }}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    >
      <LoadingOverlay
        visible={!customer && sidebarOpen}
        loaderProps={{ type: 'dots' }}
        overlayProps={{ blur: 2 }}
      />

      <SidebarBase
        header={<Header />}
        body={<Body customer={customer} />}
        footer={<Footer isLoading={isLoading} />}
        sidebarWidth="max-w-[30vw]"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </FormProvider>
  );
};

export { _Sidebar };
