import { getAdditionalInfo } from 'helpers/customer';
import { Business } from 'models/Business';

const getCustomerVisibleId = (customer: Business): string => {
  if (!customer) return '';

  if (customer.shortName && customer.shortName !== '') return customer.shortName;

  if (customer.externalId && customer.externalId !== '') return customer.externalId;

  return '';
};

export const formatSelectedCustomerOption = (customer: Business) => {
  if (!customer) return null;

  return {
    visibleId: getCustomerVisibleId(customer),
    flag: customer.disabled ? 'Disabled' : null,
    label: customer.name,
    value: customer.id,
    additionalInfo: (
      <div>
        {getAdditionalInfo(customer).map((info) => (
          <p key={info.label}>{info.value}</p>
        ))}
      </div>
    ),
  };
};

export const formatCustomerOption = (customer: Business) => {
  if (!customer) return null;

  const additionalInfo = getAdditionalInfo(customer);

  return {
    visibleId: getCustomerVisibleId(customer),
    label: customer.name,
    flag: customer.disabled ? 'Disabled' : null,
    value: customer.id,
    descriptions: additionalInfo,
  };
};
