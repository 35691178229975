import {
  useCallback, useLayoutEffect, useRef, useState,
} from 'react';
import Dayjs from 'dayjs';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { LoadingOverlay } from '@mantine/core';
import { Button } from 'components/ui/Button';
import { ScrollAreaWrapper } from 'components/wrapper/ScrollAreaWrapper';
import { formatPrice } from 'helpers/formatting';
import { Order } from '../../models/Order';
import { OrderStatusBadge } from './OrderStatusBadge';

interface Props {
  isLoading: boolean;
  orders: Order[];
  selectedOrders: Order[];
  onSelectionChange: (selected: Order[]) => void;
  onOrderOpened: (idx: number, orderId: string, isDraft: boolean) => void;
  onScrolledToEnd: () => void;
}

const OrderListTable = ({
  isLoading,
  orders,
  onOrderOpened,
  selectedOrders,
  onSelectionChange,
  onScrolledToEnd,
}: Props) => {
  const checkbox = useRef<HTMLInputElement>();
  const [allSelected, setAllSelected] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useLayoutEffect(() => {
    if (selectedOrders != null && orders != null) {
      const isIndeterminate = selectedOrders.length > 0 && selectedOrders.length < orders.length;
      setAllSelected(selectedOrders.length === orders.length);
      setIndeterminate(isIndeterminate);
      if (checkbox && checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedOrders, orders]);

  const onDetailsButtonClick = useCallback(
    (idx: number, orderId: string, isDraft: boolean) => {
      onOrderOpened(idx, orderId, isDraft);
    },
    [onOrderOpened],
  );

  const toggleAll = useCallback(() => {
    onSelectionChange(allSelected || indeterminate ? [] : orders);
    setAllSelected(!allSelected && !indeterminate);
    setIndeterminate(false);
  }, [allSelected, indeterminate, onSelectionChange, orders]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-md border border-solid">
      <ScrollAreaWrapper
        className="flex-1"
        offsetScrollbar={false}
        onScrolledEnd={onScrolledToEnd}
      >
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="sticky top-0 z-10 bg-primary-50 text-sm font-semibold text-neutral-600 drop-shadow">
            <tr>
              <th scope="col" className="relative px-lg py-smd">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  ref={checkbox}
                  checked={allSelected}
                  onChange={toggleAll}
                />
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Status
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Customer
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-right"
              >
                Products
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-right"
              >
                Total Price
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Requested Delivery
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Created At
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Exported
              </th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th scope="col" />
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Details
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {orders.map((order, idx) => (
              <tr
                key={order.id}
                className={`${selectedOrders.includes(order) && 'bg-gray-50'} hover:bg-gray-50`}
              >
                <td className="relative px-7 sm:w-12 sm:px-xl">
                  {selectedOrders.includes(order) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    value={order.id}
                    checked={selectedOrders.includes(order)}
                    onChange={(e) => onSelectionChange(
                      e.target.checked
                        ? [...selectedOrders, order]
                        : selectedOrders.filter((p) => p !== order),
                    )}
                    onClick={(e) => e.stopPropagation()}
                  />
                </td>
                <td className="relative flex items-center justify-center px-lg py-smd">
                  <OrderStatusBadge status={order.status} />
                </td>
                <td className="px-lg py-smd text-sm text-gray-500">
                  {order.customer?.name ?? order.createdByUserInfo.email}
                </td>
                <td className="relative top-[0.5px] px-lg py-smd text-right !font-mono text-sm text-gray-500">
                  {order.products.length}
                </td>
                <td className="relative top-[0.5px] whitespace-nowrap px-lg py-smd text-right !font-mono text-sm text-gray-500">
                  {formatPrice(
                    order?.totalPrice?.amount || 0,
                    order?.totalPrice?.currency,
                  )}
                </td>
                <td className="whitespace-nowrap px-lg py-smd text-sm text-gray-500">
                  {Dayjs(order.requestedDeliveryTime).format('llll')}
                </td>
                <td className="whitespace-nowrap px-lg py-smd text-sm text-gray-500">
                  {Dayjs(order.createdAt).format('llll')}
                </td>
                <td className="flex items-center justify-center px-lg py-smd">
                  {order.isExported ? (
                    <div className="relative top-2">
                      <CheckIcon
                        width={24}
                        height={24}
                        className="text-success"
                      />
                    </div>
                  ) : (
                    <div className="relative top-2">
                      <XMarkIcon
                        width={24}
                        height={24}
                        className="text-light-gray-100"
                      />
                    </div>
                  )}
                </td>
                <td />
                <td className="flex items-center justify-center px-lg py-smd">
                  {order.isDraft ? (
                    <Button
                      title="Draft"
                      theme="secondary"
                      onClick={() => onDetailsButtonClick(idx, order.id, true)}
                    />
                  ) : (
                    <Button
                      title="View"
                      theme="secondary"
                      onClick={() => onDetailsButtonClick(idx, order.id, false)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="relative flex h-10 w-full items-center justify-center">
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollAreaWrapper>
    </div>
  );
};

export default OrderListTable;
