import { Tooltip } from 'components/ui';

interface Props {
  errors: string[];
  children: React.ReactNode;
}

const ErrorsTooltip = ({ errors, children }: Props) => {
  const displayedErrors = errors.slice(0, 10);
  const hasMoreErrors = errors.length > 10;

  return (
    <Tooltip
      label={(
        <div className="p-2">
          <p className="text-red-500 font-medium">
            {errors.length}
            {' '}
            errors detected
          </p>
          <p className="text-xxs">Need to resolve these errors to proceed</p>
          <ul className="list-disc pl-3 space-y-1 pt-2 text-xs">
            {displayedErrors.map((error) => (
              <li key={error}>{error}</li>
            ))}
            {hasMoreErrors && <li>...</li>}
          </ul>
        </div>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default ErrorsTooltip;
