enum SyncModel {
  Product = 'product',
  Customer = 'customer',
  Order = 'order',
}

enum SyncRunStatus {
  Ok = 'ok',
  Failed = 'failed',
}

enum SyncDirection {
  FromERP = 'from_erp',
  ToERP = 'to_erp',
  FromToERP = 'from_to_erp',
}

class SyncLog {
  id: string;

  runId: string;

  businessId: string;

  model: SyncModel;

  syncDirection: SyncDirection;

  status: SyncRunStatus;

  failedIndex?: number;

  failedId?: string;

  failedExternalId?: string;

  failedMessage?: string;

  additionalFields: Record<string, any>;

  createdAt: Date;
}

export {
  SyncDirection, SyncLog, SyncModel, SyncRunStatus,
};
