import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faDisplay, faGear, faPlus, faSearch,
} from '@fortawesome/free-solid-svg-icons';

import { Popover, Select, Switch } from '@mantine/core';

import { Button } from 'components/ui/Button';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { ProductSortingColumn, SortExtractedProductsBy } from 'types/product';
import { Group } from 'constants/prompt';

interface Props {
  productLength: number;
}

const ProductDetailsHeader = ({
  productLength,
}: Props) => {
  const {
    removeAllProducts, addNewProduct,
  } = useProcessOrderContext();
  const {
    sortingColumn, filterEnabledProducts,
    setSortingColumn, setFilterEnabledProducts,
    sortExtractedProductsBy, setSortExtractedProductsBy,
    errors,
  } = useProcessOrderContext();
  const [opened, setOpened] = useState<boolean>(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [sortingColumn_, setSortingColumn_] = useState<string>(sortingColumn || 'created_at');
  const [filterEnabledProducts_, setFilterEnabledProducts_] = useState<boolean>(filterEnabledProducts);
  const [sortExtractedProductsBy_, setSortExtractedProductsBy_] = useState<string>(sortExtractedProductsBy || 'default');

  const customerAndOrderDetailsErrorsNum = useMemo(() => (
    Object.values(errors[Group.Customer] || {}).filter((e) => e).length
    + Object.values(errors[Group.StandardFields] || {}).filter((e) => e).length
  ), [errors]);

  const onFilterEnabledProductsSwitchChange_ = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterEnabledProducts_(event.currentTarget.checked);
  }, []);

  const onApplyClick = useCallback(() => {
    setSortingColumn(sortingColumn_ as ProductSortingColumn);
    setFilterEnabledProducts(filterEnabledProducts_);
    setSortExtractedProductsBy(sortExtractedProductsBy_ as SortExtractedProductsBy);
    setOpened(false);
  }, [sortingColumn_, filterEnabledProducts_, sortExtractedProductsBy_, setSortingColumn,
    setFilterEnabledProducts, setSortExtractedProductsBy]);

  const onErrorVisibleClick = useCallback(() => {
    document.querySelector('[data-order-section="upper-section"]')?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const upperSection = document.querySelector('[data-order-section="upper-section"]');
      if (upperSection) {
        const rect = upperSection.getBoundingClientRect();
        setIsErrorVisible(rect.bottom < 0);
      }
    };

    document.getElementById('body-container')?.addEventListener('scroll', handleScroll);
    return () => document.getElementById('body-container')?.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="flex justify-between items-center sticky top-0 z-20 bg-gray-50 pb-2">
      {
        isErrorVisible && customerAndOrderDetailsErrorsNum > 0 && (
          <div className="absolute top-0 left-1/2 -translate-x-1/2 w-fit h-fit z-50">
            <button
              type="button"
              className="bg-red-500 flex items-center gap-2 text-white px-2 py-1 rounded-full animate-bounceTwice"
              onClick={onErrorVisibleClick}
            >
              <FontAwesomeIcon icon={faArrowUp} />
              <p className="text-sm">
                {customerAndOrderDetailsErrorsNum}
                {' '}
                errors
              </p>
            </button>
          </div>
        )
      }
      <Popover
        position="bottom-start"
        withArrow
        shadow="md"
        clickOutsideEvents={['mouseup', 'touchend']}
        transitionProps={{ transition: 'pop', duration: 200 }}
        opened={opened}
        onChange={setOpened}
      >
        <Popover.Target>
          <button
            type="button"
            className="flex items-center gap-1 text-sm bg-gray-200 hover:bg-gray-300/80 px-3 py-1 rounded"
            onClick={() => setOpened(true)}
          >
            <FontAwesomeIcon icon={faGear} />
            Settings
          </button>
        </Popover.Target>
        <Popover.Dropdown className="">
          <div className="flex items-center space-x-2 text-sm text-muted-foreground text-gray-400 pb-2">
            <FontAwesomeIcon icon={faSearch} className="w-4 h-4" />
            <span className="font-medium">Search Options</span>
          </div>
          <div className="space-y-3">
            <Select
              label="Sort by"
              data={[{ label: 'ID or SKU', value: 'id_or_sku' },
                { label: 'Name', value: 'name' }, { label: 'Creation date', value: 'created_at' }]}
              value={sortingColumn_}
              onChange={setSortingColumn_}
              comboboxProps={{ withinPortal: false }}
              size="xs"
            />
            <Switch
              checked={filterEnabledProducts_}
              onChange={onFilterEnabledProductsSwitchChange_}
              labelPosition="left"
              label="Show only enabled products"
              size="xs"
            />
          </div>

          <hr className="my-3" />

          <div className="flex items-center space-x-2 text-sm text-muted-foreground text-gray-400 pb-2">
            <FontAwesomeIcon icon={faDisplay} className="w-4 h-4" />
            <span className="font-medium">Display Options</span>
          </div>

          <Select
            label="Sort extracted products by"
            data={[{ label: 'Default', value: 'default' },
              { label: 'Score', value: 'score' }, { label: 'Error status', value: 'error_status' }]}
            value={sortExtractedProductsBy_}
            onChange={setSortExtractedProductsBy_}
            comboboxProps={{ withinPortal: false }}
            size="xs"
          />

          <Button
            title="Apply"
            onClick={onApplyClick}
            className="w-full mt-3"
          />
        </Popover.Dropdown>
      </Popover>
      <div className="flex items-center gap-2">
        <button
          type="button"
          className="flex items-center rounded-l-full rounded-r-full gap-1 text-sm bg-gray-200 hover:bg-gray-300/80 px-3 py-1 rounded"
          onClick={removeAllProducts}
        >
          Delete all
        </button>
        <button
          type="button"
          className="flex items-center rounded-l-full rounded-r-full gap-1 text-sm bg-gray-200 hover:bg-gray-300/80 px-3 py-1 rounded"
          onClick={() => {
            const newProductname = `New product ${productLength + 1}`;
            addNewProduct?.({
              id: '',
              uiId: uuidv4(),
              name: newProductname,
              quantity: null,
              product: null,
              unit: null,
              price: null,
              comment: '',
              score: 0,
              autoMatched: false,
              defaultUnitConversionFactor: null,
            });
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          Add product
        </button>
      </div>
    </div>
  );
};

export default ProductDetailsHeader;
