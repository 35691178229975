import { useCallback } from 'react';
import Dayjs from 'dayjs';

import { Order } from 'features/order/models/Order';
import { FieldSchema, StandardFieldType } from 'features/instruction/models/Schema';
import { RequestedDeliveryTimePicker } from '../../../form-elemts/RequestedDeliveryTimePicker';

import DynamicField, { FieldType } from './DynamicField';

interface Props {
  fieldSchema: FieldSchema;

  type?: FieldType;
  label?: string;
  value?: string;
  className?: string;
  placeHolder?: string;
  validation?: string;
  disabled?: boolean;
  readOnly?: boolean;

  order?: Order;

  onValueChange?: (value: any) => void;
  addError?: (error: string) => void;
  removeError?: () => void;
}

const StandardField = ({
  fieldSchema,
  type,
  label,
  value,
  className,
  placeHolder,
  validation,
  disabled,
  readOnly,
  order,
  onValueChange,
  addError,
  removeError,
}: Props) => {
  const addOrRemoveError = useCallback((error: string) => {
    if (error) {
      addError?.(error);
    } else {
      removeError();
    }
  }, [addError, removeError]);

  switch (fieldSchema.standardFieldType) {
    case StandardFieldType.StandardFieldTypeOrderNumber:
      return (
        <DynamicField
          type={type}
          label={label}
          value={value}
          className={className}
          placeHolder={placeHolder}
          validation={validation}
          disabled={disabled}
          readOnly={readOnly}
          onValueChange={onValueChange}
          addError={addError}
          removeError={removeError}
        />
      );
    case StandardFieldType.StandardFieldTypeOrderDeliveryDateTime:
      return (
        <RequestedDeliveryTimePicker
          label={label}
          requestedDeliveryTime={order?.requestedDeliveryTime}
          autoMatchedRequestedDeliveryTime={order?.autoMatchedRequestedDeliveryTime}
          onDeliveryDateTimeChange={async (datetime: Date) => onValueChange(
            datetime ? Dayjs(datetime).format() : null)}
          setError={addOrRemoveError}
        />
      );
    default:
      return (
        <DynamicField
          type={type}
          label={label}
          value={value}
          className={className}
          placeHolder={placeHolder}
          disabled={disabled}
          readOnly={readOnly}
          validation={validation}
          onValueChange={onValueChange}
          addError={addError}
          removeError={removeError}
        />
      );
  }
};

export default StandardField;
