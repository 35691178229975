import { Combobox } from '@mantine/core';
import { twMerge } from 'tailwind-merge';

import { SelectOption } from '../types';
import {
  contentThemeToTextColor,
} from '../utils';

import Sparkle from './Sparkle';
import Description from './Description';
import AdditionalInformation from './AdditionalInformation';
import VisibleId from './VisibleId';
import Flag from './Flag';
import Label from './Label';
import Meta from './Meta';

interface Props {
  option: SelectOption;
  showMeta?: boolean;
  showDescriptions?: boolean;
  shrinkVisibleId?: boolean;
}

const SelectOptionRenderer = ({
  option,
  showMeta = true,
  showDescriptions: showDescriptions_ = true,
  shrinkVisibleId = false,
}: Props) => {
  const showDescriptions = (option.descriptions || []).length > 0 && showDescriptions_;
  return (
    <Combobox.Option
      value={option.value}
      key={option.value}
      className={twMerge(
        'w-full text-gray-800',
        showDescriptions && 'border-b border-gray-200',
      )}
    >
      <div className="flex w-full items-center justify-between gap-2">
        <div
          className={twMerge(
            'flex flex-1 items-center gap-2',
            contentThemeToTextColor(option?.contentTheme),
          )}
        >
          <VisibleId visibleId={option.visibleId} shrink={shrinkVisibleId} />

          <div className="flex flex-1 items-center space-x-2 overflow-hidden">
            <Label
              label={option.label}
              customLabelTooltip={option.customLabelTooltip}
              contentTheme={option.contentTheme}
            />

            <Sparkle contentTheme={option.contentTheme} />

            <Flag flag={option.flag} />
          </div>
        </div>

        <div className="flex w-fit items-center space-x-2">
          <AdditionalInformation
            additionalInfo={option.additionalInfo}
            contentTheme={option.contentTheme}
          />
          {showMeta && <Meta meta={option.meta} metaDescription={option.metaDescription} />}
        </div>
      </div>

      {showDescriptions && (<Description descriptions={option.descriptions} />)}
    </Combobox.Option>
  );
};

export default SelectOptionRenderer;
