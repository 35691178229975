import { useParams } from 'react-router-dom';

import { ProtectedScreen } from 'components/ui/ProtectedScreen';
import { OrderDetailsPage } from 'features/order/pages';
import { SchemasProvider } from 'contexts/useSchemasContext';

const OrderDetailsScreen = () => {
  const { orderId } = useParams();

  return (
    <ProtectedScreen>
      <SchemasProvider>
        <OrderDetailsPage orderId={orderId} />
      </SchemasProvider>
    </ProtectedScreen>
  );
};

export default OrderDetailsScreen;
