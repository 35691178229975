import { Expose } from 'class-transformer';

export type Error = {
  code: number;

  message: string;

  details: any[];
};

export type Metadata = {
  progressPercentage: number;

  startedAt: string;

  finishedAt: string;
};

export class Operation<T = any> {
  @Expose({ name: 'id' })
    id: string;

  @Expose({ name: 'business_id' })
    businessId: string;

  @Expose({ name: 'group' })
    group: string;

  @Expose({ name: 'metadata' })
    metadata: Metadata;

  @Expose({ name: 'is_done' })
    isDone: boolean;

  @Expose({ name: 'error' })
    error?: Error;

  @Expose({ name: 'result' })
    result?: T;

  @Expose({ name: 'created_at' })
    createdAt: string;

  @Expose({ name: 'updated_at' })
    updatedAt: string;

  @Expose({ name: 'expires_at' })
    expiresAt: string;
}
