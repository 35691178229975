import { OverflowEllipsisParagraph } from 'components/ui/OverflowEllipsisParagraph';

interface Props {
  meta: string;
  metaDescription: string;
}

const Meta = ({ meta, metaDescription }: Props) => {
  if (!meta) return null;
  return (
    <div className="max-w-16 flex-shrink-0">
      <OverflowEllipsisParagraph
        maxLines={1}
        isTooltipEnabled
        customTooltipContent={metaDescription || 'Additional details'}
        className="relative top-[1px] !font-mono text-gray-500"
      >
        {meta}
      </OverflowEllipsisParagraph>
    </div>
  );
};

export default Meta;
