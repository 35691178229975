import { Carousel } from '@mantine/carousel';
import { useEffect, useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import {
  ChatBubbleBottomCenterTextIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import { Card } from 'components/common/Card';

import { httpGetV1 } from 'helpers/xhr';
import classes from './AdamInsights.module.css';

import adam from '../../../assets/adam-face.png';

const AdamInsights = () => {
  const autoplay = useRef(Autoplay({ delay: 3000 }));

  useEffect(() => {
    httpGetV1('/businesses/me/customers/analytics/churn_rate', {
      params: {
        metric: 'total_products_sold',
      },
    }).then((response) => {
      console.log(response);
    });
  }, []);

  // TODO(chihirokuya): This logic should be moved to the backend
  return (
    <div className="col-span-4 row-start-3 rounded-lg bg-gradient-to-br from-violet-300 to-violet-600 p-0.5">
      <Card className="h-full w-full border-0">
        <Card.Body className="flex flex-col">
          <h3 className="mb-4 flex flex-col items-start">
            <span className="text-sm font-semibold text-violet-500">
              Adam insights
            </span>
            <span className="mt-1 text-xs text-violet-400">
              <span className="font-semibold text-sm">2</span>
              {' '}
              new suggestions
            </span>
          </h3>
          <Carousel
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            className="flex-1"
            classNames={classes}
            styles={{
              viewport: {
                height: '100%',
              },
              container: {
                height: '100%',
              },
            }}
          >
            <Carousel.Slide
              styles={{
                slide: {
                  height: '100%',
                },
              }}
            >
              <div className="flex h-full flex-col space-y-4">
                <div className="flex items-center">
                  <div className="symbol symbol-50px me-3">
                    <img src={adam} className="h-10 w-10 rounded-full" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-hover-primary fw-bold text-sm text-gray-800">
                      Adam
                    </p>
                    <span className="fw-semibold block text-xs text-gray-500">
                      Yestarday at 5:06 PM
                    </span>
                  </div>
                </div>
                <div className="flex-1 space-y-3">
                  <p>
                    <span className="blur-md">
                      Customer A
                    </span>
                    {' '}
                    has not placed an order in the past month. I have prepared a
                    personalized message to re-engage them and encourage future
                    orders.
                  </p>
                  <div className="flex w-full justify-between">
                    <div className="flex space-x-4">
                      <HandThumbUpIcon className="h-5 w-5" />
                      <HandThumbDownIcon className="h-5 w-5" />
                    </div>
                    <div>
                      <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Slide>

            <Carousel.Slide
              styles={{
                slide: {
                  height: '100%',
                },
              }}
            >
              <div className="flex h-full flex-col space-y-4">
                <div className="flex items-center">
                  <div className="symbol symbol-50px me-3">
                    <img src={adam} className="h-10 w-10 rounded-full" alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <p className="text-hover-primary fw-bold text-sm text-gray-800">
                      Adam
                    </p>
                    <span className="fw-semibold block text-xs text-gray-500">
                      Yestarday at 5:06 PM
                    </span>
                  </div>
                </div>
                <div className="flex-1 space-y-4">
                  <p>
                    New product
                    {' '}
                    <span className="blur-md">Product ABC</span>
                    has been added to the catalog. It is a great addition to
                    our product line and I prepared a list of leads to contact
                    to introduce it to our customers.
                  </p>
                  <div className="flex w-full justify-between">
                    <div className="flex space-x-4">
                      <HandThumbUpIcon className="h-5 w-5" />
                      <HandThumbDownIcon className="h-5 w-5" />
                    </div>
                    <div>
                      <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Slide>
          </Carousel>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdamInsights;
