import { ArchiveBoxIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';
import { HTMLAttributes, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'components/common/Tag';
import { TagVariant } from '../../../types/tag';
import { ROUTES } from '../../../config/routes';
import { MessageIntent } from '../../../models/Message';

interface Props extends HTMLAttributes<HTMLDivElement> {
  orderCount?: number;
  otherCount?: number;
}

const InboxCard = ({
  Icon,
  title,
  count,
  soon,
  onClick,
}: {
  Icon: React.ElementType;
  title: string;
  count: number;
  soon?: boolean;
  onClick?: () => void;
}) => (
  <div
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={null}
    className={twMerge(
      'w-1/2 h-1/2 flex-[0_0_auto] px-1 mt-2',
      onClick ? 'cursor-pointer' : '',
    )}
  >
    <div className={twMerge(
      'bg-gray-50 px-4 py-5 w-full aspect-square rounded-md flex flex-col',
      onClick ? 'hover:bg-gray-100' : 'cursor-default',
    )}
    >
      <div className="flex justify-between items-center">
        <Icon className="w-6 h-6 text-primary-400" />
        {
          soon && (
          <Tag
            className="static"
            isTagLoading={false}
            tagTitle="soon"
            tagVariant={TagVariant.BLUE}
            hideCircle
          />
          )
        }
      </div>
      <div className="m-0 flex-1 flex flex-col justify-end">
        <div>
          <span className="text-gray-700 font-semibold block text-3xl -tracking-tighter leading-4 mb-1">{typeof count === 'number' ? count : '-'}</span>
          <span className="text-gray-500/40 text-xs font-semibold">{title}</span>
        </div>
      </div>
    </div>
  </div>
);

const Inbox = ({ orderCount, otherCount, ...props }: Props) => {
  const navigate = useNavigate();

  const onOrderClick = useCallback(() => {
    navigate(ROUTES.INBOX({
      intent: MessageIntent.ORDER,
      unread_only: 'true',
    }));
  }, [navigate]);

  const onOtherClick = useCallback(() => {
    navigate(ROUTES.INBOX({
      intent: MessageIntent.OTHER,
      unread_only: 'true',
    }));
  }, [navigate]);

  return (
    <div
      {...props} // props except className
      className={twMerge(
        'border border-[#f1f1f4] shadow-sm relative flex flex-col break-words rounded-md bg-white h-full',
        props.className,
      )}
    >
      {/* Heading */}
      <div className={twMerge(
        'rounded-md flex justify-between flex-wrap min-h-[70px] px-9 h-[250px] bg-gradient-to-r from-primary-200 via-primary-300 to-violet-300',
        'bg-no-repeat bg-cover [background-position-y:top] [background-position-x:center]',
        'items-start',
      )}
      >
        <h3 className="flex justify-center items-start flex-col text-white pt-15">
          <span className="font-semibold text-lg mb-1">Your unread messages</span>

          <div className="text-white opacity-75 text-sm">
            Track and manage your unread inbox
          </div>
        </h3>
      </div>
      {/* Body */}
      <div className="py-8 px-9 flex-1 mt-[-2rem]">
        <div className="relative mt-[-7rem]">
          <div className="flex flex-wrap">
            <InboxCard
              Icon={ShoppingCartIcon}
              title="Orders"
              count={orderCount}
              onClick={onOrderClick}
            />
            <InboxCard
              Icon={ArchiveBoxIcon}
              title="Other"
              count={otherCount}
              onClick={onOtherClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;
