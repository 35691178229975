import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  isNil, omitBy, uniqBy,
} from 'lodash';

import { httpGetV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { convertKeysToSnakeCase } from 'helpers/mapping';
import { Subject } from 'models/User';

interface FetchSubjectsProps {
  preventInitialFetch?: boolean;
  defaultFilter?: SubjectsFilter;
}

type SubjectsFilter = {
  query?: string;
};

const useFetchSubjects = ({
  preventInitialFetch = false,
  defaultFilter,
}: FetchSubjectsProps) => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialFetchRef = useRef(true);
  const endReachedRef = useRef(false);
  const cursor = useRef<string | null>(null);
  const currentAbortController = useRef<AbortController | null>(null);

  const [subjectsFilter, setSubjectsFilter] = useState<SubjectsFilter>(defaultFilter || {});

  const resetRefs = () => {
    endReachedRef.current = false;
    cursor.current = null;
  };

  const loadSubjects = useCallback(
    async (reset: boolean = false, giveErrorFeedback: boolean = true) => {
      if (reset) {
        setSubjects([]);
        resetRefs();
      } else if (endReachedRef.current) return;

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);

      try {
        const response = await httpGetV1('/businesses/me/subjects', {
          params: {
            cursor: cursor.current,
            limit: 20,
            ...convertKeysToSnakeCase(omitBy(subjectsFilter, isNil)), // exclude all null values
          },
          signal: controller.signal,
        });

        const entries = response?.data?.result || [];
        if (reset) {
          setSubjects(entries);
        } else {
          setSubjects((_subjects) => uniqBy([..._subjects, ...entries], 'id'));
        }
        cursor.current = response.data.cursor;

        if (!response.data.cursor || entries.length === 0) {
          endReachedRef.current = true;
        }
      } catch (error) {
        if (giveErrorFeedback && error.name !== 'AbortError' && error.name !== 'CanceledError') {
          genericErrorFeedback('An error has occured while fetching subjects')(error);
        }
      } finally {
        if (!controller.signal.aborted) {
          setIsLoading(false);
        }
        currentAbortController.current = null;
      }
    },
    [subjectsFilter],
  );

  useEffect(() => {
    if (preventInitialFetch) {
      initialFetchRef.current = false;
      return () => {};
    }

    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      loadSubjects();
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadSubjects, preventInitialFetch]);

  useEffect(() => {
    if (initialFetchRef.current) {
      initialFetchRef.current = false;
      return;
    }

    loadSubjects(true);
  }, [subjectsFilter, loadSubjects]);

  return {
    subjects,
    setSubjects,
    subjectsFilter,
    setSubjectsFilter,
    isLoading,
    loadSubjects,
  };
};

export { useFetchSubjects, SubjectsFilter };
