import { ProtectedScreen } from 'components/ui/ProtectedScreen';

import SyncLogListPage from '../pages/SyncLogs';

const SyncLogsScreen = () => (
  <ProtectedScreen>
    <SyncLogListPage />
  </ProtectedScreen>
);

export default SyncLogsScreen;
