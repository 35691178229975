import { useState, useCallback } from 'react';

import { httpPatchV1 } from '../helpers/xhr';

const useMarkMessageAsRead = () => {
  const [isLoading, setIsLoading] = useState(false);

  const markMessagesAsRead = useCallback((ids: string[]): Promise<void> => {
    setIsLoading(true);
    return httpPatchV1('/chat/messages/read', { ids })
      .then(() => {})
      .catch((error) => {
        console.error('failed to mark messages as read', error);
        return Promise.reject(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading, markMessagesAsRead };
};

export { useMarkMessageAsRead };
