import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Combobox,
  ComboboxProps,
  Input,
  InputBase,
  InputBaseProps,
  ScrollArea,
  useCombobox,
} from '@mantine/core';

import { LoadingIndicator } from '../../LoadingIndicator';

import { SelectOption } from './types';
import {
  contentThemeToBorderStyle,
} from './utils';
import { SelectOptionRenderer } from './SelectOptionRenderer';

interface Props extends ComboboxProps {
  label?: string;
  error?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  dropdownPlaceHolder?: string;
  emptyPlaceholder?: string;
  required?: boolean;
  isLoading?: boolean;
  size?: 'sm' | 'xs';

  inputStyles?: InputBaseProps['styles'];

  selectedOption: SelectOption;
  options: SelectOption[];

  isExternalSearchEnabled?: boolean;
  shrinkVisibleId?: boolean;

  onSelectionChange: (option: SelectOption) => void;
  onSearchPerformed?: (search: string) => void;
  onScrolledEnd?: (search: string) => void;
  onDropdownOpen?: () => void;
}

const Select = ({
  label,
  error,
  placeholder,
  searchPlaceholder,
  dropdownPlaceHolder,
  emptyPlaceholder,
  required,
  options,
  selectedOption,
  inputStyles,
  isLoading = false,
  isExternalSearchEnabled = false,
  size = 'sm',
  shrinkVisibleId = false,
  onSelectionChange,
  onScrolledEnd,
  onSearchPerformed,
  onDropdownOpen,
  ...props
}: Props) => {
  const [search, setSearch] = useState('');

  const viewPortRef = useRef<HTMLDivElement>(null);
  const isDropdownOpenedRef = useRef(false);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      isDropdownOpenedRef.current = true;
      if (isExternalSearchEnabled) {
        combobox?.focusSearchInput();
      }
      onDropdownOpen?.();
    },
  });

  const onOptionSelect = useCallback(
    (value: string) => {
      const option = options.find((o) => o.value === value);
      if (option) onSelectionChange(option);
      combobox.closeDropdown();
    },
    [combobox, onSelectionChange, options],
  );

  const onOptionsScroll = useCallback(
    (position: { x: number; y: number }) => {
      if (
        viewPortRef.current.scrollHeight
        <= Math.round(position.y) + viewPortRef.current.clientHeight
      ) onScrolledEnd?.(search);
    },
    [onScrolledEnd, search],
  );

  const onComboSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      setSearch(value);
    },
    [setSearch],
  );

  const comboOptions = useMemo(
    () => options.map((o) => <SelectOptionRenderer key={o.value} option={o} shrinkVisibleId={shrinkVisibleId} />),
    [options, shrinkVisibleId],
  );

  const comboOptionsOrEmpty = useMemo(() => {
    if (isLoading) {
      return (
        <>
          {comboOptions}
          <Combobox.Empty>Loading...</Combobox.Empty>
        </>
      );
    }

    if (comboOptions.length === 0) {
      if (isExternalSearchEnabled && !search) {
        return <Combobox.Empty>{dropdownPlaceHolder || 'Nothing found'}</Combobox.Empty>;
      }

      return <Combobox.Empty>{emptyPlaceholder || 'Nothing found'}</Combobox.Empty>;
    }

    return comboOptions;
  }, [isLoading, comboOptions, isExternalSearchEnabled, search, emptyPlaceholder, dropdownPlaceHolder]);

  const onInputBaseClick = useCallback(() => {
    combobox.toggleDropdown();
  }, [combobox]);

  useEffect(() => {
    if (isDropdownOpenedRef.current) onSearchPerformed?.(search);
  }, [onSearchPerformed, search]);

  return (
    <div className="w-full">
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={onOptionSelect}
        offset={4}
        shadow="lg"
        {...props}
      >
        <Combobox.Target>
          <InputBase
            label={label}
            error={error}
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            className="relative"
            onClick={onInputBaseClick}
            rightSectionPointerEvents="none"
            required={required}
            size={size}
            styles={{ input: { border: contentThemeToBorderStyle(selectedOption?.contentTheme) }, ...inputStyles }}
          >
            <div className="flex items-center gap-2">
              {selectedOption ? (
                <SelectOptionRenderer
                  option={selectedOption}
                  showMeta={false}
                  showDescriptions={false}
                  shrinkVisibleId={shrinkVisibleId}
                />
              ) : (
                <Input.Placeholder>
                  {isLoading ? 'Loading' : placeholder || 'Select an option...'}
                </Input.Placeholder>
              )}
              <LoadingIndicator isLoading={isLoading} />
            </div>
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown style={{ outline: '0.5px solid #E8E8E8' }}>
          {isExternalSearchEnabled ? (
            <Combobox.Search
              value={search}
              onChange={onComboSearch}
              placeholder={searchPlaceholder || 'Search options...'}
            />
          ) : null}
          <Combobox.Options mah={400}>
            <ScrollArea.Autosize
              mah={400}
              type="scroll"
              offsetScrollbars
              onScrollPositionChange={onOptionsScroll}
              mt="sm"
              viewportRef={viewPortRef}
            >
              {comboOptionsOrEmpty}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
};

export default Select;
