import { Expose } from 'class-transformer';

export class Inbox {
  @Expose({ name: 'id' })
  public id: string;

  @Expose({ name: 'name' })
  public name: string;

  @Expose({ name: 'description' })
  public description: string;

  @Expose({ name: 'business_id' })
  public businessId: string;

  @Expose({ name: 'owner_user_id' })
  public ownerUserId: string;

  @Expose({ name: 'participant_user_ids' })
  public participantUserIds: string[];

  @Expose({ name: 'bound_email_channels' })
  public boundEmailChannels: string[];

  @Expose({ name: 'is_deleted' })
  public isDeleted: boolean;

  @Expose({ name: 'is_default' })
  public isDefault: boolean;

  @Expose({ name: 'created_at' })
  public createdAt: Date;

  @Expose({ name: 'updated_at' })
  public updatedAt: Date;
}
