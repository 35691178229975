import { useCallback, useEffect, useMemo } from 'react';

import { User } from 'models/User';
import { globalUser } from 'state/globalUser';
import { Select, SelectOption } from 'components/ui/Select';

interface Props {
  teamMembers: User[];
  assignedTeamMember: SelectOption;
  setAssignedTeamMember: (selected: SelectOption) => void;
  loadTeamMembers: () => void;

  isLoading?: boolean;

  assignMe?: boolean;

  showLabel?: boolean;
  placeholder?: string;
}

const AssigneeSelect = ({
  teamMembers, assignedTeamMember, setAssignedTeamMember, loadTeamMembers,
  isLoading = false,
  assignMe = true,
  showLabel = true,
  placeholder = 'Assign to...',
}: Props) => {
  const teamMembersOptions = useMemo(
    () => {
      const options = teamMembers.map((u) => ({
        label: `${u.firstName} ${u.lastName}`,
        value: u.id,
      }));

      if (!options.find((u) => u.value === globalUser.id)) {
        options.push({
          label: `${globalUser.firstName} ${globalUser.lastName}`,
          value: globalUser.id,
        });
      }
      return options;
    },
    [teamMembers],
  );

  useEffect(() => {
    if (teamMembers.length > 0 && assignMe) {
      setAssignedTeamMember(
        teamMembersOptions.find((u) => u.value === globalUser.id) || null,
      );
    }
  }, [teamMembers.length, teamMembersOptions, setAssignedTeamMember, assignMe]);

  const onTeamMemberSelectChange = useCallback(
    (selected: { value: string; label: string }) => {
      setAssignedTeamMember(selected);
    },
    [setAssignedTeamMember],
  );

  const onScrolledEnd = useCallback(() => {
    loadTeamMembers();
  }, [loadTeamMembers]);

  return (
    <Select
      label={showLabel ? 'Assignee' : ''}
      isLoading={isLoading}
      placeholder={placeholder}
      required
      options={teamMembersOptions}
      selectedOption={assignedTeamMember}
      onSelectionChange={onTeamMemberSelectChange}
      onScrolledEnd={onScrolledEnd}
      isExternalSearchEnabled
    />
  );
};

export default AssigneeSelect;
