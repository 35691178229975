import {
  useCallback, useRef, useState,
} from 'react';

import { SubjectsTable, SubjectSearchBar } from 'components/subject/subject-list';
import { Page } from 'components/layout/Page/Page';
import { Subject } from 'models/User';
import { SubjectDetailSidebarWrapper } from 'components/wrapper/SubjectDetailSidebarWrapper';
import { useFetchSubjects } from 'hooks/fetch/useFetchSubjects';

const SubjectListPage = () => {
  const {
    subjects, isLoading, loadSubjects, setSubjects, setSubjectsFilter,
  } = useFetchSubjects({});
  const [selectedIdx, setSelectedIdx] = useState<number>(null);
  const subjectDetailSidebarWrapperRef = useRef(null);

  const onSearchClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const query = formData.get('query');
    setSubjectsFilter({ query: query.toString() });

    loadSubjects(true, true);
  };

  const openDialog = useCallback((idx: number) => {
    setSelectedIdx(idx);
    if (subjectDetailSidebarWrapperRef.current) {
      subjectDetailSidebarWrapperRef.current.onManageCustomerButtonClick();
    }
  }, []);

  const onSubjectEditComplete = useCallback(
    (_subject: Subject) => {
      setSubjects((s) => s.map((subject, idx) => (idx === selectedIdx ? _subject : subject)),
      );
    },
    [selectedIdx, setSubjects],
  );

  const onScrolledEnd = useCallback(() => {
    if (!isLoading) {
      loadSubjects(false, true);
    }
  }, [isLoading, loadSubjects]);

  return (
    <Page isLoading={isLoading}>
      <SubjectDetailSidebarWrapper
        ref={subjectDetailSidebarWrapperRef}
        subject={subjects[selectedIdx]}
        onComplete={onSubjectEditComplete}
      >
        <div className="m-lg flex flex-1 flex-col gap-lg rounded-2xl border border-solid bg-white p-lg">
          <div className="flex justify-between gap-lg">
            <SubjectSearchBar onSubmit={onSearchClicked} />
          </div>

          <SubjectsTable
            subjects={subjects}
            openDialog={openDialog}
            isLoading={isLoading}
            onScrolledEnd={onScrolledEnd}
          />
        </div>
      </SubjectDetailSidebarWrapper>
    </Page>
  );
};

export default SubjectListPage;
