import { useState, useCallback } from 'react';

import { httpPostV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';

const useInitResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const initResetPassword = useCallback(
    async (username: string, giveErrorFeedback: boolean = true): Promise<void> => {
      setIsLoading(true);
      return httpPostV1('/auth/password/reset', { username })
        .then(() => {})
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Failed to send reset password link')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return { isLoading, initResetPassword };
};

export { useInitResetPassword };
