import {
  ReactNode, createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import { Order } from '../models/Order';

type OrderContextType = {
  groupOrders: Order[];
  setGroupOrders: React.Dispatch<React.SetStateAction<Order[]>>; // TODO: remove this
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;

  addOrder: (order: Order) => void;
  removeOrderById: (orderId: string) => void;

  deletedOrderIds: string[];
  setDeletedOrderIds: React.Dispatch<React.SetStateAction<string[]>>; // TODO: remove this
  addDeletedOrderId: (orderId: string) => void;
};
const OrderContext = createContext<OrderContextType | undefined>(undefined);

type OrderProviderProps = {
  children: ReactNode;

  orderGroup?: Order[];
};

const OrderProvider: React.FC<OrderProviderProps> = ({
  children,
  orderGroup: _orderGroup,
}: OrderProviderProps) => {
  const [groupOrders, setGroupOrders] = useState<Order[]>(_orderGroup || []);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [deletedOrderIds, setDeletedOrderIds] = useState<string[]>([]);

  const addOrder = useCallback((order: Order) => {
    setGroupOrders((_groupOrders) => [..._groupOrders, order]);
  }, []);

  const removeOrderById = useCallback((orderId: string) => {
    setGroupOrders((_groupOrders) => _groupOrders.filter((order) => order.id !== orderId));
  }, []);

  const addDeletedOrderId = useCallback((orderId: string) => {
    setDeletedOrderIds((_deletedOrderIds) => [..._deletedOrderIds, orderId]);
  }, []);

  const contextValue = useMemo(
    () => ({
      groupOrders,
      setGroupOrders,
      selectedIndex,
      setSelectedIndex,
      deletedOrderIds,
      setDeletedOrderIds,
      addOrder,
      removeOrderById,
      addDeletedOrderId,
    }),
    [groupOrders, selectedIndex, deletedOrderIds, addOrder, removeOrderById, addDeletedOrderId],
  );

  return (
    <OrderContext.Provider value={contextValue}>
      {children}
    </OrderContext.Provider>
  );
};

const useOrderContext = () => {
  const context = useContext(OrderContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by OrderContext provider.');
  }

  return context;
};

export { OrderContext, useOrderContext, OrderProvider };
