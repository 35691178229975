import {
  forwardRef,
  useCallback, useEffect, useImperativeHandle, useState, useMemo,
} from 'react';
import {
  Group,
  Layer, Rect, Stage, Text,
} from 'react-konva';
import { LoadingOverlay, Tooltip } from '@mantine/core';
import Konva from 'konva';
import { twMerge } from 'tailwind-merge';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { useKeywordContext } from 'features/order/contexts/useKeywordContext';
import { isValidKeyword } from 'features/order/utils/keyword';
import { Annotation, KonvaStageRef, Tool } from './type';
import {
  getFillColor, getStrokeColor, getTextBackgroundColor, isKeywordMatchAnnotation,
} from './utils';

interface Props {
  annotationsKey: string;
  imageDimensions: {
    width: number;
    height: number;
  };
  scaleX: number;
  scaleY: number;
  selectedTool: Tool;
  sendScreenshot: (annotation: Annotation) => void;
}

const MIN_ANNOTATION_SIZE = 50;

const calculateTextWidth = (text: string, fontSize: number, fontFamily: string = 'Plus Jakarta Sans') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  }
  return 0;
};

const doesAnnotationContainAnotherAnnotation = (annotations: Annotation[], annotation: Annotation) => {
  const topLeft = { x: annotation.x, y: annotation.y };
  const bottomRight = { x: annotation.x + annotation.width, y: annotation.y + annotation.height };

  return annotations.some(
    (a) => (
      a.x >= topLeft.x
      && a.y >= topLeft.y
      && a.x + a.width <= bottomRight.x
      && a.y + a.height <= bottomRight.y
    ),
  );
};

const KonvaStage = ({
  annotationsKey, imageDimensions, scaleX, scaleY, selectedTool, sendScreenshot,
}: Props, ref: React.ForwardedRef<KonvaStageRef>) => {
  const {
    annotationsRecord,
    setAnnotations,
    removeAnnotationByPoint,
    setAnnotationIsLoading,
    addAnnotationProductUiIds,
    addAnnotationPath,
    addAnnotationPopupContent,
    scrollToKeyword,
  } = useProcessOrderContext();
  const {
    leftHoveredKeyword,
    rightHoveredKeyword,
    setLeftHoveredKeyword,
  } = useKeywordContext();

  const [newAnnotation, setNewAnnotation] = useState<Annotation | null>(null);
  const [openTooltips, setOpenTooltips] = useState<string[]>([]);

  const annotations = useMemo(() => annotationsRecord[annotationsKey] || [], [annotationsRecord, annotationsKey]);
  const isLeftHoveredKeywordValid: boolean = useMemo(() => isValidKeyword(leftHoveredKeyword), [leftHoveredKeyword]);
  const hoveredKeyword = useMemo(() => (
    isLeftHoveredKeywordValid ? leftHoveredKeyword : rightHoveredKeyword
  ), [isLeftHoveredKeywordValid, leftHoveredKeyword, rightHoveredKeyword]);

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (selectedTool !== 'Magic Pen' && selectedTool !== 'Eraser') {
      return;
    }

    if (!newAnnotation) {
      const { x, y } = event.target.getStage().getPointerPosition();

      // If the selected tool is the eraser, clear the annotation at the point
      if (selectedTool === 'Eraser') {
        removeAnnotationByPoint(annotationsKey, x, y);
      } else {
        setNewAnnotation({
          x, y, width: 0, height: 0, key: '0',
        });
      }
    }
  };

  const handleMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (selectedTool !== 'Magic Pen' && selectedTool !== 'Eraser') {
      return;
    }

    if (newAnnotation) {
      const sx = newAnnotation.x;
      const sy = newAnnotation.y;
      const { x, y } = event.target.getStage().getPointerPosition();
      const width = x - sx;
      const height = y - sy;

      if (width / scaleX < MIN_ANNOTATION_SIZE
        || height / scaleY < MIN_ANNOTATION_SIZE
        || doesAnnotationContainAnotherAnnotation(annotations, {
          x: sx, y: sy, width, height, key: '',
        })) {
        setNewAnnotation(null);
        return;
      }

      const annotationToAdd = {
        x: sx,
        y: sy,
        width,
        height,
        key: `${annotations.length + 1}`,
      } as Annotation;
      annotations.push(annotationToAdd);
      setNewAnnotation(null);
      setAnnotations(annotationsKey, annotations);

      sendScreenshot(annotationToAdd);
    }
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (selectedTool !== 'Magic Pen' && selectedTool !== 'Eraser') {
      return;
    }

    if (newAnnotation) {
      const sx = newAnnotation.x;
      const sy = newAnnotation.y;
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation({
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        isTooSmall: (x - sx) / scaleX < MIN_ANNOTATION_SIZE
          || (y - sy) / scaleY < MIN_ANNOTATION_SIZE,
        key: '0',
      });
    }
  };

  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape' && newAnnotation) {
      setNewAnnotation(null);
    }
  }, [newAnnotation]);

  const onPopupClicked = useCallback((key: string | number) => {
    const annotation = annotations?.find((a) => a.key === key);

    const scrollToId = annotation?.productUiIds?.[0] || annotation?.path;
    if (scrollToId) {
      scrollToKeyword(scrollToId);
    }
  }, [annotations, scrollToKeyword]);

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => window.removeEventListener('keydown', keyDownHandler);
  }, [keyDownHandler]);

  useImperativeHandle(ref, () => ({
    setIsLoading: (key: string, isLoading: boolean) => (
      setAnnotationIsLoading(annotationsKey, key, isLoading)
    ),
    addProductUiIds: (key: string, productUiIds: string[]) => (
      addAnnotationProductUiIds(annotationsKey, key, productUiIds)
    ),
    addPopup: (key: string, content: string) => {
      addAnnotationPopupContent(annotationsKey, key, content);
      onPopupClicked(key);
    },
    addPath: (key: string, path: string) => {
      addAnnotationPath(annotationsKey, key, path);
    },
  }));

  return (
    <div className="absolute left-0 top-0 z-[100]">
      <div className="relative">
        <Stage
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          width={imageDimensions.width}
          height={imageDimensions.height}
          className={twMerge(
            'z-[100]',
          )}
        >
          <Layer>
            {annotations.map((annotation) => (
              <Group key={annotation.key}>
                <Group>
                  <Rect
                    x={annotation.x}
                    y={annotation.y}
                    width={annotation.width}
                    height={annotation.height}
                    fill={getFillColor(annotation, hoveredKeyword, isLeftHoveredKeywordValid)}
                    stroke={getStrokeColor(annotation, hoveredKeyword, isLeftHoveredKeywordValid)}
                    strokeWidth={isValidKeyword(hoveredKeyword) ? 0 : 1}
                    onClick={() => onPopupClicked(annotation.key)}
                    onMouseEnter={() => {
                      setOpenTooltips((prev) => (
                        prev.includes(annotation.key)
                          ? prev
                          : [...prev, annotation.key]
                      ));
                      setLeftHoveredKeyword({
                        fieldIds: annotation.productUiIds || [],
                        fieldPath: annotation.path || '',
                      });
                    }}
                    onMouseLeave={() => {
                      setOpenTooltips((prev) => prev.filter((k) => k !== annotation.key));
                      setLeftHoveredKeyword(null);
                    }}
                  />
                </Group>
                {
                  hoveredKeyword && isKeywordMatchAnnotation(hoveredKeyword, annotation, isLeftHoveredKeywordValid) && (
                    <>
                      <Rect
                        x={annotation.x}
                        y={annotation.y - 9}
                        stroke={getTextBackgroundColor(annotation)}
                        strokeWidth={1}
                        width={calculateTextWidth(annotation.label, 8) + 2}
                        height={9}
                        fill={getTextBackgroundColor(annotation)}
                      />
                      <Text
                        x={annotation.x + 2}
                        y={annotation.y - 8}
                        text={annotation.label}
                        fontSize={8}
                        fill="white"
                      />
                    </>
                  )
                }
              </Group>
            ))}

            {
              newAnnotation && (
                <>
                  <Rect
                    key={newAnnotation.key}
                    x={newAnnotation.x}
                    y={newAnnotation.y}
                    width={newAnnotation.width}
                    height={newAnnotation.height}
                    fill={newAnnotation.isTooSmall ? 'rgba(255,0,0,0.2)' : 'rgba(66,149,245,0.2)'}
                    stroke={newAnnotation.isTooSmall ? 'red' : 'blue'}
                    strokeWidth={1}
                  />

                  {
                    newAnnotation.isTooSmall && (
                      <Text
                        x={newAnnotation.x}
                        y={newAnnotation.y - 10}
                        text="Choose a larger area"
                        fill="red"
                        fontSize={10}
                      />
                    )
                  }
                </>
              )
            }
          </Layer>
        </Stage>

        {annotations.map((value) => (
          value.isLoading ? (
            <div
              key={value.key}
              className="flex items-center justify-center"
              style={{
                position: 'absolute',
                left: value.x,
                top: value.y,
                width: value.width,
                height: value.height,
              }}
            >
              <LoadingOverlay
                visible
                loaderProps={{ type: 'dots' }}
                overlayProps={{ blur: 2 }}
              />
            </div>
          ) : null
        ))}

        {annotations.map((value) => (
          value.popupContent && selectedTool !== 'Eraser' ? (
            <div
              key={value.key}
              style={{
                position: 'absolute',
                left: value.x,
                top: value.y - 8,
                width: 'fit-content',
                height: 'fit-content',
              }}
            >
              <Tooltip
                withArrow
                position="top"
                label={value.popupContent}
                opened={openTooltips.includes(value.key)}
                defaultOpened
                transitionProps={{ transition: 'pop', duration: 200 }}
                bg="white"
                styles={{
                  tooltip: {
                    color: 'black',
                    border: '1.5px solid #E8E8E8',
                    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                  },
                }}
              >
                <div style={{ width: value.width }} />
              </Tooltip>
            </div>
          ) : null
        ))}
      </div>
    </div>
  );
};

export default forwardRef(KonvaStage);
