import { Business, Organization } from './Business';
import { ContactMedium } from './ContactUser';

enum UserRole {
  AiAssistant = 'AI_ASSISTANT',
  RestaurantAdmin = 'RESTAURANT_ADMIN',
  RestaurantUser = 'RESTAURANT_USER',
  SupplierAdmin = 'SUPPLIER_ADMIN',
  SupplierUser = 'SUPPLIER_USER',
}

class User {
  externalId?: string;

  subjectData: SubjectData;

  id: string;

  username: string;

  firstName: string;

  lastName: string;

  email: string;

  phone: string;

  roles: UserRole[];

  external: boolean;

  business: Business;

  contactMedium: ContactMedium;

  activatedAt: string;

  // TODO: proper constructor
  constructor({
    roles,
  }: {
    roles?: UserRole[];
  }) {
    this.roles = roles || [];
  }
}

class SubjectData {
  organizationId: string;

  organization: Organization;
}

type Subject = User;

export { User, UserRole, Subject };
