import { useState } from 'react';
import { MultiSelect, Popover, Select } from '@mantine/core';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'components/ui/Button';
import { MessageIntent } from 'models/Message';
import { ThreadsFilter } from 'hooks/fetch/useFetchThreads';
import { toTitleLocaleCase } from 'utils/strings';

interface Props {
  popOverTarget?: React.ReactNode;
  defaultFilter?: ThreadsFilter;
  setThreadsFilter: React.Dispatch<React.SetStateAction<ThreadsFilter>>;
}

const intentOptions = [
  { value: 'all', label: 'All' },
  { value: MessageIntent.ORDER, label: toTitleLocaleCase(MessageIntent.ORDER) },
  { value: MessageIntent.OTHER, label: toTitleLocaleCase(MessageIntent.OTHER) },
];

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'unread', label: 'Unread' },
];

const Filter = ({ popOverTarget, defaultFilter, setThreadsFilter }: Props) => {
  const [opened, setOpened] = useState(false);

  const [, setSearchParams] = useSearchParams();

  const [localThreadsFilter, setLocalThreadsFilter] = useState<ThreadsFilter>(defaultFilter || {});

  const onApplyClick = () => {
    setThreadsFilter((prev) => ({ ...prev, ...localThreadsFilter }));

    const newSearchParams: Record<string, string> = {};

    // DEV-564: TODO(ntauth)
    // if (localThreadsFilter.intent) {
    //   newSearchParams.intent = localThreadsFilter.intent;
    // }

    // if (localMessagesFilter.sources) {
    //   newSearchParams.message_sources = localMessagesFilter.sources.join(',');
    // }

    // if (localThreadsFilter.unreadOnly) {
    //   newSearchParams.unread_only = 'true';
    // }

    setSearchParams(newSearchParams);
    setOpened(false);
  };

  return (
    <Popover
      position="bottom"
      clickOutsideEvents={['mouseup', 'touchend']}
      transitionProps={{ transition: 'pop', duration: 200 }}
      shadow="md"
      withArrow
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        {popOverTarget || (
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => setOpened(true)}
          >
            <div className="flex border border-gray-300/80 shadow-sm gap-1 items-center rounded-sm px-1.5 py-0.5">
              <FunnelIcon className="w-4 aspect-square text-gray-600" />
              <p className="text-sm text-gray-600">Filters</p>
            </div>
          </button>
        )}
      </Popover.Target>
      <Popover.Dropdown>
        <div className="grid grid-cols-3 gap-2">
          <p className="col-span-1">Intents</p>
          <MultiSelect
            data={intentOptions}
            value={localThreadsFilter.intents}
            onChange={(value) => {
              setLocalThreadsFilter({ ...localThreadsFilter, intents: value as MessageIntent[] });
            }}
            comboboxProps={{ withinPortal: false }}
            className="col-span-2"
          />

          {/* <p className="col-span-1">Sources</p>
          <MultiSelect
            data={sourcesOptions}
            value={localMessagesFilter.sources}
            onChange={(value) => {
              if (!value) {
                setLocalMessagesFilter({ ...localMessagesFilter, sources: null });
              } else {
                setLocalMessagesFilter({ ...localMessagesFilter, sources: value as MessageSource[] });
              }
            }}
            comboboxProps={{ withinPortal: false }}
            className="col-span-2"
          /> */}

          <p className="col-span-1">Status</p>
          <Select
            data={statusOptions}
            value={localThreadsFilter.unreadOnly ? 'unread' : 'all'}
            onChange={(value) => {
              setLocalThreadsFilter({ ...localThreadsFilter, unreadOnly: value === 'unread' });
            }}
            comboboxProps={{ withinPortal: false }}
            className="col-span-2"
          />

          <div className="col-span-3">
            <Button
              title="Apply"
              onClick={onApplyClick}
              className="w-full"
            />
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Filter;
