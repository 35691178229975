import {
  useCallback, useMemo, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import isEqual from 'lodash/isEqual';

import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { Product, Unit } from 'models/Product';
import { ProductWithQuantity } from 'features/order/models/Order';
import { FieldSpec, Schema } from 'features/instruction/models/Schema';
import { Group } from 'constants/prompt';

import { isZeroId } from 'helpers/objectId';
import { convertQuantity } from 'helpers/product';

import ProductPositionIdWrapper from 'features/order/components/process-order-draft/utils/ProductPositionIdWrapper';
import { QuantityConversionPopupContent } from 'types/product';
import CollapsedContent from './CollapsedContent';
import ProductNameAndIcons from './ProductNameAndIcons';
import ExpandedContent from './ExpandedContent';

interface Props {
  customerId: string;
  product: ProductWithQuantity;

  productNameField: FieldSpec | null;
  productQuantityField: FieldSpec | null;
  productUnitField: FieldSpec | null;
}

const filterRecordByUiId = (record: Record<Group, Record<string, string>>, uiId: string): string[] => (
  Object.entries(record[Group.Product])
    .filter(([key, error]) => key.startsWith(uiId) && error)
    .map(([, error]) => error)
);

const getItemId = (product: ProductWithQuantity) => (
  product.positionId && !isZeroId(product.positionId)
    ? product.positionId
    : product.uiId || product.name?.trim()
);

type MemoizedProductExpandableCardProps = {
  product: ProductWithQuantity;
  productErrors: string[];
  productWarnings: string[];
  id: string;
  removeProductByUiIdAndPositionId: (uiId: string, positionId?: string) => void;
  productFields: FieldSpec[];
  schema: Schema;
  customerId: string;
  productNameField: FieldSpec | null;
  productQuantityField: FieldSpec | null;
  productUnitField: FieldSpec | null;
  onProductUnitChange: (newUnit: Unit) => void;
  onProductQuantityChange: (val: number) => void;
  onProductSelectionChange: (p: Product) => void;
  updateProductFieldValueByPath: (path: string, val: any) => void;
  updateProduct: (values: any) => void;
  setError: (error: string, path: string) => void;

  quantityPopupContent: QuantityConversionPopupContent | null;
  onQuantityPopupConfirmed: () => void;
  onQuantityPopupCanceled: () => void;
};

const deepEqual = (prevProps: MemoizedProductExpandableCardProps, nextProps: MemoizedProductExpandableCardProps) => {
  if (!isEqual(prevProps.productErrors, nextProps.productErrors)) {
    return false;
  }

  if (!isEqual(prevProps.productWarnings, nextProps.productWarnings)) {
    return false;
  }

  if (!isEqual(prevProps.product, nextProps.product)) {
    return false;
  }

  // Use Object.keys instead of for..in
  return Object.keys(prevProps).every((key) => {
    if (key === 'productErrors' || key === 'productWarnings' || key === 'product') return true;
    return prevProps[key as keyof MemoizedProductExpandableCardProps]
           === nextProps[key as keyof MemoizedProductExpandableCardProps];
  });
};

const MemoizedProductExpandableCard = React.memo(({
  product,
  productErrors,
  productWarnings,
  id,
  removeProductByUiIdAndPositionId,
  productFields,
  schema,
  customerId,
  productNameField,
  productQuantityField,
  productUnitField,
  quantityPopupContent,
  onQuantityPopupConfirmed,
  onQuantityPopupCanceled,
  onProductUnitChange,
  onProductQuantityChange,
  onProductSelectionChange,
  updateProductFieldValueByPath,
  updateProduct,
  setError,
}: MemoizedProductExpandableCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={twMerge(
        `bg-white relative p-4 rounded border ${productErrors.length > 0 && 'pb-5'}`,
        productErrors.length > 0 && 'border-red-500',
      )}
      id={id}
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center justify-between">
          <ProductNameAndIcons
            product={product}
            productNameField={productNameField}
            removeProductByUiIdAndPositionId={removeProductByUiIdAndPositionId}
            errors={productErrors}
            warnings={productWarnings}
          />
        </div>
        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className="ml-4 transition-transform duration-300"
        >
          <div className={`transform transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </button>
      </div>

      {isExpanded ? (
        <ExpandedContent
          customerId={customerId}
          product={product}
          fieldSpecs={productFields}
          schema={schema}
          productNameField={productNameField}
          onProductUnitChange={onProductUnitChange}
          onProductSelectionChange={onProductSelectionChange}
          updateProductFieldValueByPath={updateProductFieldValueByPath}
          updateProduct={updateProduct}
          setError={setError}
          quantityPopupContent={quantityPopupContent}
          onQuantityPopupCanceled={onQuantityPopupCanceled}
          onQuantityPopupConfirmed={onQuantityPopupConfirmed}
        />
      ) : (
        <CollapsedContent
          customerId={customerId}
          product={product}
          productNameField={productNameField}
          productQuantityField={productQuantityField}
          productUnitField={productUnitField}
          onProductSelectionChange={onProductSelectionChange}
          onProductQuantityChange={onProductQuantityChange}
          onProductUnitChange={onProductUnitChange}
          setError={setError}
          quantityPopupContent={quantityPopupContent}
          onQuantityPopupConfirmed={onQuantityPopupConfirmed}
          onQuantityPopupCanceled={onQuantityPopupCanceled}
        />
      )}
    </div>
  );
}, (prevProps, nextProps) => deepEqual(prevProps, nextProps));

const ProductExpandableCard = ({
  customerId, product, productNameField, productQuantityField, productUnitField,
}: Props) => {
  const {
    updateProductByUiId, updateProductByUiIdAndPath, addError, removeError, removeProductByUiIdAndPositionId,
    errors, warnings, productFields, schema,
  } = useProcessOrderContext();

  const [quantityPopupContent, setQuantityPopupContent] = useState<QuantityConversionPopupContent | null>(null);

  const id = getItemId(product);
  const productErrors: string[] = useMemo(() => filterRecordByUiId(errors, product.uiId), [errors, product.uiId]);
  const productWarnings: string[] = useMemo(() => filterRecordByUiId(warnings, product.uiId), [warnings, product.uiId]);

  const setError = useCallback((error: string, path: string) => {
    const key = `${product.uiId}-${path}`;

    if (error) {
      addError(Group.Product, key, error);
    } else {
      removeError(Group.Product, key);
    }
  }, [addError, removeError, product.uiId]);

  const updateProduct = useCallback((values: any) => {
    updateProductByUiId(product.uiId, values);
  }, [product.uiId, updateProductByUiId]);

  const onLocalProductSelectionChange = useCallback((p: Product) => updateProduct({
    updatedName: p.name,
    id: p.id,
    score: p.score,
    product: p,
  }), [updateProduct]);

  const updateLocalProductFieldValueByPath = useCallback((path: string, val: any) => {
    updateProductByUiIdAndPath(product.uiId, path, val);
  }, [product.uiId, updateProductByUiIdAndPath]);

  const onLocalProductQuantityChange = useCallback((val: number) => {
    updateLocalProductFieldValueByPath(productQuantityField?.path, val);

    if (typeof val !== 'number' || val <= 0) {
      setError('Invalid quantity', productQuantityField?.path);
    } else {
      setError('', productQuantityField?.path);
    }
  }, [updateLocalProductFieldValueByPath, productQuantityField?.path, setError]);

  const onQuantityPopupConfirmed = useCallback(() => {
    if (quantityPopupContent) {
      onLocalProductQuantityChange(quantityPopupContent.newQuantity);
      setQuantityPopupContent(null);
    }
  }, [quantityPopupContent, onLocalProductQuantityChange]);

  const onQuantityPopupCanceled = useCallback(() => {
    setQuantityPopupContent(null);
  }, [setQuantityPopupContent]);

  const onLocalProductUnitChange = useCallback((newUnit: Unit) => {
    const newQuantity = convertQuantity(product.quantity, product.unit, newUnit);
    updateLocalProductFieldValueByPath(productUnitField?.path, newUnit);

    if (newQuantity !== product.quantity) {
      setQuantityPopupContent({
        prevQuantity: product.quantity,
        newQuantity,
      });
    }
  }, [product.quantity, product.unit, updateLocalProductFieldValueByPath,
    productUnitField?.path, setQuantityPopupContent]);

  return (
    <MemoizedProductExpandableCard
      product={product}
      productErrors={productErrors}
      productWarnings={productWarnings}
      id={id}
      removeProductByUiIdAndPositionId={removeProductByUiIdAndPositionId}
      productFields={productFields}
      schema={schema}
      customerId={customerId}
      productNameField={productNameField}
      productQuantityField={productQuantityField}
      productUnitField={productUnitField}
      onProductUnitChange={onLocalProductUnitChange}
      onProductQuantityChange={onLocalProductQuantityChange}
      onProductSelectionChange={onLocalProductSelectionChange}
      updateProductFieldValueByPath={updateLocalProductFieldValueByPath}
      updateProduct={updateProduct}
      setError={setError}
      quantityPopupContent={quantityPopupContent}
      onQuantityPopupConfirmed={onQuantityPopupConfirmed}
      onQuantityPopupCanceled={onQuantityPopupCanceled}
    />
  );
};

export default ProductPositionIdWrapper(ProductExpandableCard);
