import { Tooltip } from '@mantine/core';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode | string;
  icon: React.ReactNode;
}

const IconButtonWithTooltip = ({ icon, label, ...props }: Props) => (
  <Tooltip label={label} transitionProps={{ transition: 'pop' }}>
    <button
      type="button"
      {...props}
      className={twMerge(
        'hover:bg-gray-100 rounded-full p-1 aspect-square',
        'text-gray-500', // Base color
        props.className,
      )}
    >
      {icon}
    </button>
  </Tooltip>
);

export default IconButtonWithTooltip;
