import { twMerge } from 'tailwind-merge';

import stars from 'assets/stars-svgrepo-com.svg';

import { contentThemeHasSparkle, contentThemeToSparkleFilter } from '../utils';
import { ContentTheme } from '../types';

interface Props {
  contentTheme: ContentTheme;
}

const Sparkle = ({ contentTheme }: Props) => (
  <img
    className={twMerge(
      'relative ml-auto h-[25.4px] w-[20px]',
      contentThemeHasSparkle(contentTheme) ? 'block' : 'hidden',
    )}
    src={stars}
    alt=""
    style={{
      filter: contentThemeToSparkleFilter(contentTheme),
    }}
  />
);

export default Sparkle;
