import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  LoadingOverlay, Popover, ScrollArea, Textarea,
} from '@mantine/core';

import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { Card } from 'components/common/Card';
import adam from '../../../../assets/adam-face.png';
import { Message } from '../../../../models/Message';
import { globalUser } from '../../../../state/globalUser';
import { ChatMessagesProvider, useChatMessagesContext } from '../../../../contexts/useChatMessagesContext';
import { decodeEntities, getMessageDirection } from '../../../../utils/messageUtils';
import { MessageDirection } from '../../../../types/message';
import { Chat } from '../../../../models/Chat';
import { AdamMessageWrapper } from '../../../wrapper/AdamMessageWrapper';
import AdamText from './AdamText';

const ChatWithAdamMessage = memo(({
  message,
}: {
  message: Message;
}) => {
  const { setMessages } = useChatMessagesContext();
  const direction = useMemo(() => getMessageDirection(message, globalUser.business), [message]);

  const callback = useCallback(() => {
    console.log('callback', message);
    setMessages((messages) => messages?.map((msg) => {
      if (msg.id === message.id) {
        return { ...msg, isAdded: false };
      }
      return msg;
    }));
  }, [message, setMessages]);

  if (direction === MessageDirection.Received) {
    return (
      <span id={message.id}>
        <AdamText
          text={message.message}
          useTypeWriter={!!message.isAdded}
          callback={callback}
        />
      </span>
    );
  }

  return (
    <div id={message.id} className="flex justify-end">
      <div className="py-2 px-2 bg-primary-500 text-white w-fit rounded-md max-w-[80%]">
        {decodeEntities(message.message)}
      </div>
    </div>
  );
});

const ChatWithAdamMessages = memo(({
  messages,
}: {
  messages: Message[],
}) => {
  const { isAdamThinking } = useChatMessagesContext();
  return (
    <span className="flex-1 space-y-6">
      {messages.map((message) => (
        <ChatWithAdamMessage key={message.id} message={message} />
      ))}
      {isAdamThinking && (
      <AdamMessageWrapper variant="small">
        <div className="relative h-[1ch] w-20">
          <LoadingOverlay
            visible
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </AdamMessageWrapper>
      )}
    </span>
  );
});

const ChatWithAdamContent = ({
  messages,
  setChat,
}: {
  messages: Message[];
  setChat: React.Dispatch<React.SetStateAction<Chat>>;
}) => {
  const {
    userInput,
    setUserInput,
    isAdamThinking,
    onMessageSubmit,
    isSendingLLMMessage,
  } = useChatMessagesContext();

  const mainWrapperRef = useRef<HTMLDivElement>(null);
  const textareaWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chatId = messages.find((message) => message.chatIdV2)?.chatIdV2;
    if (chatId) {
      setChat((chat) => ({
        ...chat,
        id: chatId,
      }));
    }
  }, [messages, setChat]);

  return (
    <div className="max-h-[80vh] flex flex-col gap-5">
      <ScrollArea.Autosize
        scrollbars="y"
        className="flex-1 space-y-6"
        viewportRef={mainWrapperRef}
      >
        <ChatWithAdamMessages messages={messages} />
      </ScrollArea.Autosize>
      {/* Textarea */}
      <div
        ref={textareaWrapperRef}
        className="flex max-h-[4rem] items-end"
      >
        <Textarea
          placeholder=""
          disabled={isAdamThinking || isSendingLLMMessage}
          className="w-full border-0 focus:ring-0 focus-visible:ring-0"
          radius="lg"
          autosize
          maxRows={3}
          value={userInput}
          onChange={(e) => {
            setUserInput(e.currentTarget.value);

            if (textareaWrapperRef.current) {
              textareaWrapperRef.current.style.height = `${e.target.scrollHeight}px`;
            }
            if (mainWrapperRef.current) {
              mainWrapperRef.current.scrollTop = mainWrapperRef.current.scrollHeight;
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (userInput.length > 0) {
                onMessageSubmit('adam_assistant');
              }
            }
          }}
          rightSection={(
            <div className="flex h-full flex-col justify-end pb-[0.5rem]">
              <PaperAirplaneIcon
                className={`h-5 w-5 ${isAdamThinking || isSendingLLMMessage ? 'text-gray-600' : 'text-gray-400'}`}
              />
            </div>
        )}
        />
      </div>
    </div>
  );
};

const ChatWithAdam = memo(() => {
  const [chat, setChat] = useState<Chat>({
    id: '',
    business: globalUser.business,
    lastMessage: null,
    lastMessageCreatedAt: null,
    lastMessageRefAction: null,
    lastMessageRefType: null,
    lastMessageType: null,
    lastMessageSource: null,
  });
  const [messages, setMessages] = useState<Message[]>([
    // landing message
    new Message(
      uuidv4(),
      null,
      null,
      null,
      null,
      null,
      "Hi there! I'm <strong>Adam</strong>, your AI assistant. I'm here to help you with whatever you need.",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true,
    ),
  ]);

  const onOpen = useCallback(() => {
    const lastMessage = messages[messages.length - 1];
    setTimeout(() => {
      const lastMessageDiv = document.getElementById(lastMessage.id);
      if (lastMessageDiv) {
        lastMessageDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 100);
  }, [messages]);

  return (
    <Popover
      position="bottom"
      clickOutsideEvents={['mouseup', 'touchend']}
      transitionProps={{ transition: 'pop', duration: 200 }}
      shadow="md"
      withArrow
      onOpen={onOpen}
    >
      <Popover.Target>
        <div className="flex cursor-pointer flex-row items-center gap-smd space-x-1 rounded-full border border-violet-300 px-2 py-1">
          <img
            src={adam}
            alt="adam"
            className="h-6 w-6 rounded-full object-cover"
          />
          <p className="bg-gradient-to-r from-violet-400 via-violet-500 to-violet-600 bg-clip-text text-sm font-semibold text-transparent select-none">
            Chat with Adam
          </p>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Card.Body className="relative px-0 py-0 w-[25vw] 2xl:w-[20vw]">
          <ChatMessagesProvider setMessages={setMessages} chat={chat} isAdamTemporaryChat>
            <ChatWithAdamContent messages={messages} setChat={setChat} />
          </ChatMessagesProvider>
        </Card.Body>
      </Popover.Dropdown>
    </Popover>
  );
});

export default ChatWithAdam;
