import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Page } from 'components/layout/Page/Page';
import { OrderProvider } from 'features/order/contexts/useOrderContext';
import { PlayRecordingProvider } from 'contexts/usePlayRecordingIndex';
import { TeamMembersProvider } from 'contexts/useTeamMembersContext';
import {
  MessageProviderType,
  MessagesProvider,
} from 'contexts/useMessagesContext';
import { BusinessSettingsProvider } from 'contexts/useBusinessSettingsContext';
import { SchemasProvider } from 'contexts/useSchemasContext';
import { ProcessOrderDrafts } from 'features/order/components/process-order-draft';
import { ROUTES } from 'config/routes';
import { ProcessOrderProvider } from 'features/order/contexts/useProcessOrderContext';
import { useFetchOrder } from 'features/order/api/useFetchOrder';
import { isZeroId } from 'helpers/objectId';

interface Props {
  orderId: string;
}

const ProcessOrderDraftByIdPage = observer(({ orderId }: Props) => {
  const navigate = useNavigate();

  const { order, isLoading: isOrderLoading } = useFetchOrder({
    orderId,
    preventFetch: false,
  });

  const messageReferenceId = useMemo(() => {
    if (order?.groupId && !isZeroId(order.groupId)) {
      return order.groupId;
    }

    return orderId;
  }, [order?.groupId, orderId]);

  const onOrderDraftProcessed = useCallback(() => {
    navigate(ROUTES.ORDER_BY_ID(orderId));
  }, [navigate, orderId]);

  const onOrderDraftRetried = useCallback(async (retriedOrderId: string) => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(retriedOrderId));
  }, [navigate]);

  return (
    <Page isLoading={false} contentWithBorder>
      <PlayRecordingProvider>
        <MessagesProvider
          providerType={MessageProviderType.BY_REFERENCE_ID}
          referenceId={messageReferenceId}
        >
          <BusinessSettingsProvider>
            <OrderProvider>
              <SchemasProvider>
                <ProcessOrderProvider onOrderDraftProcessed={onOrderDraftProcessed}>
                  <TeamMembersProvider>
                    <ProcessOrderDrafts
                      order={order}
                      isOrderLoading={isOrderLoading}
                      onOrderRetried={onOrderDraftRetried}
                    />
                  </TeamMembersProvider>
                </ProcessOrderProvider>
              </SchemasProvider>

            </OrderProvider>
          </BusinessSettingsProvider>
        </MessagesProvider>
      </PlayRecordingProvider>
    </Page>
  );
});

export default ProcessOrderDraftByIdPage;
