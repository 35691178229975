import { useState, useCallback } from 'react';

import { httpPostV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';

type GroupOrderDraftsProps = {
  orderIds: string[];
  giveErrorFeedback?: boolean;
};

const useGroupOrderDrafts = () => {
  const [isLoading, setIsLoading] = useState(false);

  const groupOrders = useCallback(
    async ({
      orderIds,
      giveErrorFeedback = false,
    }: GroupOrderDraftsProps): Promise<string> => {
      if (!orderIds.length || isLoading) return Promise.reject(new Error('No order IDs provided'));

      setIsLoading(true);

      return httpPostV1('/orders/drafts/group', {
        order_ids: orderIds,
      })
        .then((response) => {
          globalAlertData.create('Order drafts grouped successfully');
          return Promise.resolve(response.data?.groupId);
        })
        .catch((error) => {
          if (giveErrorFeedback) genericErrorFeedback('Error grouping order drafts')(error);
          return Promise.reject(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isLoading],
  );

  return { isLoading, groupOrders };
};

export { useGroupOrderDrafts };
