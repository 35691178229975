import {
  useCallback, useEffect, useImperativeHandle, useState, forwardRef,
} from 'react';
import { Text, Textarea } from '@mantine/core';
import { twMerge } from 'tailwind-merge';

import { SelectOption } from 'components/ui/Select';
import { Button } from 'components/ui/Button';
import { useTeamMemnersContext } from 'contexts/useTeamMembersContext';
import { Order } from 'features/order/models/Order';

import { AssigneeSelect } from '../../Assignee/AssigneeSelect';

interface Props {
  order: Order;
  assignOrder: (teamMemberId: string, teamMemberName: string, comment: string) => void;

  assignedTeamMember: SelectOption;
  setAssignedTeamMember: (teamMember: SelectOption) => void;
}

const Assignee = forwardRef(({
  order,
  assignOrder,
  assignedTeamMember,
  setAssignedTeamMember,
}: Props, ref) => {
  const {
    teamMembers, isLoading, loadTeamMembers,
  } = useTeamMemnersContext();

  const [assignedComment, setAssignedComment] = useState<string>('');

  const isAssigneeChanged = order?.assigneeId !== assignedTeamMember?.value;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onAssignButtonClick = useCallback(() => {
    if (assignedTeamMember?.value) {
      assignOrder(
        assignedTeamMember.value,
        assignedTeamMember.label,
        assignedComment,
      );
    }
  }, [
    assignedTeamMember?.value,
    assignedTeamMember?.label,
    assignOrder,
    assignedComment,
  ]);

  useImperativeHandle(ref, () => ({
    isAssigneeChanged,
  }));

  // Set comment if present
  useEffect(() => {
    if (order?.draft?.comment) {
      setAssignedComment(order.draft.comment);
    } else {
      setAssignedComment('');
    }
  }, [order, setAssignedComment]);

  return (
    <div className={twMerge(
      'border px-4 py-2 bg-gray-100',
      isAssigneeChanged ? 'rounded-l-md rounded-r-md' : 'rounded-l-full rounded-r-full',
    )}
    >
      <div className="flex items-center gap-4">
        <Text aria-required size="sm">Assignee*</Text>

        <div className="w-[180px]">
          <AssigneeSelect
            teamMembers={teamMembers}
            assignedTeamMember={assignedTeamMember}
            setAssignedTeamMember={setAssignedTeamMember}
            loadTeamMembers={loadTeamMembers}
            isLoading={isLoading}
            showLabel={false}
          />
        </div>
      </div>

      {
        isAssigneeChanged && (
          <>
            <Textarea
              placeholder="Add some comment if needed..."
              label="Comment"
              className="pt-1"
              autosize
              minRows={2}
              maxRows={4}
              value={assignedComment}
              onChange={(event) => setAssignedComment(event.currentTarget.value)}
            />

            <div
              className="flex w-full justify-end pt-2"
            >
              <Button
                title="Assign"
                theme="primary"
                disabled={assignedTeamMember === null}
                onClick={onAssignButtonClick}
              />
            </div>
          </>
        )
      }
    </div>
  );
});

export default Assignee;
