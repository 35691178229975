import { useState, useCallback } from 'react';

import { httpPostV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';
import { globalOrderDrafts } from 'state/globalOrderDrafts';

const useConfirmOrderDraftsByGroupId = () => {
  const [isLoading, setIsLoading] = useState(false);

  const confirmOrderDraftsByGroupId = useCallback(
    (groupId: string, id: string): Promise<void> => {
      setIsLoading(true);
      let timeToFinalize = localStorage.getItem(`${id}/timeToFinalize`);
      console.log('timeToFinalize', timeToFinalize);
      if (!timeToFinalize) {
        timeToFinalize = '0';
      }
      return httpPostV1(`/orders/drafts/group/${groupId}/confirm`, { groupId, timeToFinalize })
        .then(() => {
          globalAlertData.create('Order confirmed successfully');
          globalOrderDrafts.markOrderAsProcessed();
        })
        .catch((error) => {
          genericErrorFeedback('Error confirming order draft')(error);
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return { isLoading, confirmOrderDraftsByGroupId };
};

export { useConfirmOrderDraftsByGroupId };
