import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { Tooltip } from '@mantine/core';
import { getIconForAttachment } from 'helpers/attachments';
import { Attachment } from '../../../../models/Message';

const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(1))} ${sizes[i]}`;
};

const ThreadMessageAttachmentPreview = ({ attachment }: { attachment: Attachment }) => {
  const {
    name, type, size, url,
  } = attachment;

  return (
    <a
      href={url}
      download
      className="flex items-center gap-3 p-3 rounded-lg border border-gray-200 bg-gray-50 hover:bg-gray-100 transition-colors w-64"
    >
      <div className="flex items-center justify-center w-10 h-10 bg-white rounded-lg border border-gray-200">
        <img
          src={getIconForAttachment(type)}
          className="w-5 h-5"
          alt={type.toString()}
        />
      </div>

      <div className="flex-1 min-w-0">
        <div className="flex items-center justify-between gap-2">
          <Tooltip label={name}>
            <p className="text-sm font-medium text-gray-900 truncate">
              {name.length > 20 ? `${name.slice(0, 17)}...` : name}
            </p>
          </Tooltip>
          <FontAwesomeIcon icon={faDownload} className="w-4 h-4 text-gray-500" />
        </div>
        <p className="text-xs text-gray-500">
          {formatFileSize(size)}
        </p>
      </div>
    </a>
  );
};

export const ThreadMessageAttachments = ({ attachments }: { attachments: Attachment[] }) => (
  <div>
    <div className="flex flex-wrap gap-4 p-4">

      {attachments.map((attachment) => (
        <ThreadMessageAttachmentPreview key={attachment.url} attachment={attachment} />
      ))}
    </div>
  </div>
);
