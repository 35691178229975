import { useCallback, useMemo, useState } from 'react';

import { Page } from 'components/layout/Page/Page';
import { SyncLog } from 'models/Erp';
import { SyncLogFilters, SyncLogListTable } from 'features/erp/components/sync-logs';
import { useFetchSyncLogs } from 'features/erp/api/useFetchSyncLogs';

const SyncLogListPage = () => {
  const {
    logs,
    isLoading: isLoadingLogs,
    params,
    setParams,
    resetParams,
    loadLogs,
  } = useFetchSyncLogs({
    initialFetch: true,
    autoLoad: true,
  });

  const isLoading = useMemo(
    () => isLoadingLogs,
    [isLoadingLogs],
  );

  const [selectedLogs, setSelectedLogs] = useState([]);

  const onLogsSelectionChange = useCallback((list: SyncLog[]) => {
    setSelectedLogs(list);
  }, []);

  const onTableScrolledToEnd = useCallback(() => {
    if (!isLoadingLogs) {
      loadLogs();
    }
  }, [loadLogs, isLoadingLogs]);

  const onResetFilters = useCallback(() => {
    resetParams();
  }, [resetParams]);

  return (
    <Page>
      <div className="m-4 flex w-[calc(100%-32px)] flex-1 flex-col gap-lg rounded-2xl border border-solid bg-white p-lg">
        <div className="flex justify-between gap-lg">
          <SyncLogFilters
            params={params}
            setParams={setParams}
            onResetFilters={onResetFilters}
          />
        </div>

        <SyncLogListTable
          isLoading={isLoading}
          logs={logs}
          selectedLogs={selectedLogs}
          onSelectionChange={onLogsSelectionChange}
          onScrolledToEnd={onTableScrolledToEnd}
        />
      </div>
    </Page>
  );
};

export default SyncLogListPage;
