import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Flex, Loader, ScrollArea } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import { Message, MessageContextUtils } from '../../../../models/Message';
import { ChatMessageBase } from '../ChatMessage';
import { MessageDirection } from '../../../../types/message';
import { usePlayRecordingContext } from '../../../../contexts/usePlayRecordingIndex';
import { Business } from '../../../../models/Business';
import { getMessageDirection } from '../../../../utils/messageUtils';

const MemoizedChatMessageBase = memo(
  ({
    message,
    business,
    isExpandable,
    isOrderProcessingMode,
  }: {
    message: Message;
    business: Business;
    isExpandable: boolean;
    isOrderProcessingMode: boolean;
  }) => {
    const direction = useMemo(
      () => getMessageDirection(message, business),
      [business, message],
    );
    return (
      <ChatMessageBase
        message={message}
        business={business}
        direction={direction}
        isExpandable={isExpandable}
        isOrderProcessingMode={isOrderProcessingMode}
      />
    );
  },
);

interface Props {
  messages: Message[];
  business?: Business;
  isLoading: boolean;
  isAdamThinking?: boolean;

  isExpandable?: boolean;
  isOrderProcessingMode?: boolean;
  loadMessages?: () => Promise<void>;
}
const ChatMessagesBase = ({
  messages,
  business,
  isLoading,
  isAdamThinking = false,
  isExpandable = false,
  isOrderProcessingMode = false,
  loadMessages,
}: Props) => {
  const { setIsRecordingAvailable } = usePlayRecordingContext();

  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const doScroll = useRef(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const viewPortRef = useRef<HTMLDivElement>(null);

  const chatId = useMemo(() => messages?.[0]?.chatIdV2, [messages]);

  const onTopReached = useCallback(() => {
    if (isLoading || !viewPortRef.current) return;

    setCurrentScrollPosition(
      viewPortRef.current.scrollHeight - viewPortRef.current.scrollTop,
    );
    loadMessages?.()
      .then(() => {
        doScroll.current = true;
      });
  }, [isLoading, loadMessages]);

  // Scroll to the position 'previous' to new messages
  useEffect(() => {
    if (viewPortRef.current && doScroll.current) {
      viewPortRef.current.scrollTo({
        top: viewPortRef.current.scrollHeight - currentScrollPosition - 20,
      });
      doScroll.current = false;
    }
  }, [currentScrollPosition, messages]);

  useEffect(() => {
    if (viewPortRef.current) {
      viewPortRef.current.scrollTo({ top: viewPortRef.current.scrollHeight });
    }
  }, [chatId]);

  useEffect(() => {
    setCurrentScrollPosition(0);
  }, [chatId]);

  useEffect(() => {
    setCurrentScrollPosition(0);
  }, [chatId]);

  useEffect(() => {
    setIsRecordingAvailable(
      messages.some(
        (_message) => MessageContextUtils.audioAttachments(_message.context).length > 0,
      ),
    );
  }, [messages, setIsRecordingAvailable]);

  if (!business) return null;

  return (
    <div className="flex h-full w-full flex-1 flex-col overflow-hidden">
      {isLoading ? (
        <div className="flex h-fit w-full justify-center">
          <Loader size="sm" />
        </div>
      ) : null}
      <div
        className="flex flex-1 flex-col overflow-hidden"
        ref={containerRef}
      >
        <AnimatePresence>
          <ScrollArea.Autosize
            viewportRef={viewPortRef}
            type="scroll"
            scrollbars="y"
            onTopReached={onTopReached}
            scrollbarSize={8}
            offsetScrollbars
            className="flex-1"
          >
            <Flex direction="column" rowGap={48}>
              {messages?.map((message) => (
                <MemoizedChatMessageBase
                  key={message.id}
                  message={message}
                  business={business}
                  isExpandable={isExpandable}
                  isOrderProcessingMode={isOrderProcessingMode}
                />
              ))}
              {isAdamThinking && (
              <ChatMessageBase
                message={
                      new Message(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        true,
                        false,
                      )
                    }
                direction={MessageDirection.Received}
              />
              )}
            </Flex>
          </ScrollArea.Autosize>
        </AnimatePresence>
      </div>
    </div>
  );
};

export { ChatMessagesBase };
