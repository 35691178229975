import { SelectOptionDescription } from '../types';

interface Props {
  descriptions: SelectOptionDescription[];
}

const Description = ({ descriptions }: Props) => (
  <div className="mt-1 grid grid-cols-2 gap-x-4 gap-y-1 text-sm text-gray-500">
    {descriptions.map((description, index) => (
      <div key={`${description.value}-${index.toString()}`}>
        {description.label}
        :
        {' '}
        {description.value}
      </div>
    ))}
  </div>
);

export default Description;
