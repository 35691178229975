import {
  useState, useCallback, useEffect, useRef,
} from 'react';

import { uniqBy } from 'lodash';
import { httpGetV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import {
  SyncLog, SyncRunStatus, SyncModel,
} from 'models/Erp';

type GetSyncLogsParams = {
  model?: SyncModel;
  status?: SyncRunStatus;
  from_time?: string;
  to_time?: string;

  cursor?: string;
};

interface FetchSyncLogsProps {
  initialFetch?: boolean;
  autoLoad?: boolean;
}

const useFetchSyncLogs = ({
  initialFetch = false,
  autoLoad = false,
}: FetchSyncLogsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<SyncLog[]>([]);

  const [params, setParams] = useState<GetSyncLogsParams>({});

  const cursor = useRef<string | null>(null);
  const initialRenderRef = useRef(true);
  const endReachedRef = useRef(false);
  const currentAbortController = useRef<AbortController | null>(null);

  const resetRefs = () => {
    cursor.current = null;
    endReachedRef.current = false;
  };

  const resetParams = () => {
    setParams({});
  };

  const loadLogs = useCallback(
    (
      giveErrorFeedback: boolean = true,
      reset: boolean = false,
    ): Promise<SyncLog[]> => {
      if (endReachedRef.current) {
        return Promise.reject(new Error('No more sync logs to load'));
      }

      // NOTE(ntauth): No need to call resetRefs() if reset is true here because
      // changing the params will change the callback as well, in chain, trigger
      // the effect. This, in turn, will reset the refs as long as autoLoad is true.

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      const controller = new AbortController();
      currentAbortController.current = controller;

      setIsLoading(true);

      const paramsWithCursor = {
        ...params,
        cursor: cursor.current,
      };

      return httpGetV1('/erp/sync_logs', {
        params: paramsWithCursor,
      })
        .then((response) => {
          const responseLogs = response.data.result || [];
          const responseCursor = response.data.cursor;

          if (reset) {
            setLogs(responseLogs);
          } else {
            setLogs((_logs) => uniqBy([..._logs, ...responseLogs], 'id'));
          }

          if (!responseCursor || responseLogs.length === 0) {
            endReachedRef.current = true;
          } else {
            cursor.current = responseCursor;
          }

          return responseLogs;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occurred while fetching sync logs')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => {
          if (!controller.signal.aborted) {
            setIsLoading(false);
          }
          currentAbortController.current = null;
        });
    },
    [params],
  );

  useEffect(() => {
    if (initialFetch && initialRenderRef.current) {
      resetRefs();
      setLogs([]);
      loadLogs();
      initialRenderRef.current = false;
      return () => {};
    }

    if (autoLoad) {
      resetRefs();
      setLogs([]);
      loadLogs();
    }

    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [loadLogs, initialFetch, autoLoad]);

  return {
    isLoading,
    logs,
    setLogs,
    params,
    setParams,
    loadLogs,
    resetParams,
  };
};

export { useFetchSyncLogs, GetSyncLogsParams };
