import { Group } from 'constants/prompt';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  resetTrigger: number;
}

type UseWarningAndErrorsReturnType = {
  errors: Record<Group, Record<string, string>>;
  addError: (group: Group, key: string, error: string) => void;
  removeError: (group: Group, key: string) => void;
  deleteProductErrorsByUiId: (uiId: string) => void;

  warnings: Record<Group, Record<string, string>>;
  addWarning: (group: Group, key: string, warning: string) => void;
  removeWarning: (group: Group, key: string) => void;
  deleteProductWarningsByUiId: (uiId: string) => void;
};

const add = (
  setState: React.Dispatch<React.SetStateAction<Record<Group, Record<string, string>>>>,
) => (group: Group, key: string, value: string) => {
  setState((prevState) => {
    const newGroupState = { ...prevState[group] };
    newGroupState[key] = value;
    return {
      ...prevState,
      [group]: newGroupState,
    };
  });
};

const remove = (
  setState: React.Dispatch<React.SetStateAction<Record<Group, Record<string, string>>>>,
) => (group: Group, key: string) => {
  setState((prevState) => {
    const newGroupState = prevState[group];
    delete newGroupState[key];
    return {
      ...prevState,
      [group]: newGroupState,
    };
  });
};

const deleteProduct = (
  setState: React.Dispatch<React.SetStateAction<Record<Group, Record<string, string>>>>,
) => (uiId: string) => {
  setState((prevState) => {
    const updatedProductGroup = Object.keys(prevState[Group.Product]).reduce((acc, key) => {
      if (!key.startsWith(uiId)) {
        acc[key] = prevState[Group.Product][key];
      }
      return acc;
    }, {} as Record<string, string>);

    return {
      ...prevState,
      [Group.Product]: updatedProductGroup,
    };
  });
};

export const useWarningAndErrors = ({ resetTrigger }: Props): UseWarningAndErrorsReturnType => {
  const [errors, setErrors] = useState<Record<Group, Record<string, string>>>({
    [Group.Customer]: {},
    [Group.Product]: {},
    [Group.StandardFields]: {},
  });

  const [warnings, setWarnings] = useState<Record<Group, Record<string, string>>>({
    [Group.Customer]: {},
    [Group.Product]: {},
    [Group.StandardFields]: {},
  });

  const addError = useCallback(
    (group: Group, key: string, error: string) => add(setErrors)(group, key, error),
    [],
  );
  const removeError = useCallback(
    (group: Group, key: string) => remove(setErrors)(group, key),
    [],
  );
  const deleteProductErrorsByUiId = useCallback(
    (uiId: string) => deleteProduct(setErrors)(uiId),
    [],
  );

  const addWarning = useCallback(
    (group: Group, key: string, warning: string) => add(setWarnings)(group, key, warning),
    [],
  );
  const removeWarning = useCallback(
    (group: Group, key: string) => remove(setWarnings)(group, key),
    [],
  );
  const deleteProductWarningsByUiId = useCallback(
    (uiId: string) => deleteProduct(setWarnings)(uiId),
    [],
  );

  useEffect(() => {
    setErrors({
      [Group.Customer]: {},
      [Group.Product]: {},
      [Group.StandardFields]: {},
    });
    setWarnings({
      [Group.Customer]: {},
      [Group.Product]: {},
      [Group.StandardFields]: {},
    });
  }, [resetTrigger]);

  return {
    errors,
    addError,
    removeError,
    deleteProductErrorsByUiId,

    warnings,
    addWarning,
    removeWarning,
    deleteProductWarningsByUiId,
  };
};
