import { useEffect, useMemo } from 'react';

import { useFetchBusinessSettings } from 'hooks/fetch/useFetchBusinessSettings';
import { useFetchSchemaByTypeRef } from 'hooks/fetch/useFetchSchemaByTypeRef';

import { useBusinessSettingsContext } from 'contexts/useBusinessSettingsContext';
import { useSchemasContext } from 'contexts/useSchemasContext';
import { DragDropProvider } from 'contexts/useDragDropContext';

import { CustomerSelect } from 'features/customer/components/CustomerSelect';
import LoadingOverlay from 'components/ui/LoadingOverlay';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';
import { isZeroId } from 'helpers/objectId';

interface Props {
  children: React.ReactNode;
}

const BodyWrapper = ({ children }: Props) => {
  const { schemas, setSchemas } = useSchemasContext();
  const { loadSchema: loadOrderSchema, isLoading: isOrderSchemaLoading } = useFetchSchemaByTypeRef();

  const { setBusinessSettings } = useBusinessSettingsContext();
  const { businessSettings, isLoading: isBusinessSettingsLoading } = useFetchBusinessSettings({});

  const { order, isPromptLoading } = useProcessOrderContext();

  const isCustomerSelected = useMemo(() => !!order && !isZeroId(order.createdBy), [order]);

  useEffect(() => {
    setBusinessSettings(businessSettings);
  }, [businessSettings, setBusinessSettings]);

  useEffect(() => {
    if (order) {
      order.typeSpecs?.forEach((typeSpec) => {
        if (schemas[typeSpec.typeRef]) {
          return;
        }

        loadOrderSchema(typeSpec.typeRef)
          .then((schema) => {
            setSchemas((_schemas) => ({ ..._schemas, [typeSpec.typeRef]: schema }));
          });
      });
    }
  }, [order, loadOrderSchema, setSchemas, schemas]);

  if (!isCustomerSelected) {
    return (
      <div className="p-4 h-fit w-full space-y-3">
        <div className="space-y-1">
          <h1 className="font-semibold">
            Select Customer
          </h1>
          <p className="text-sm text-description">
            Please choose a customer to proceed with this order
          </p>
        </div>
        <CustomerSelect showLabel={false} />
      </div>
    );
  }

  if (isBusinessSettingsLoading) {
    return (
      <LoadingOverlay visible displayText="Loading business settings" />
    );
  }

  if (isOrderSchemaLoading) {
    return (
      <LoadingOverlay visible displayText="Loading order schema" />
    );
  }

  if (isPromptLoading) {
    return (
      <LoadingOverlay visible displayText="Loading prompt" />
    );
  }

  return (
    <DragDropProvider>
      {children}
    </DragDropProvider>
  );
};

export default BodyWrapper;
