import { useState, useCallback } from 'react';

import { httpPostV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { globalAlertData } from 'state/globalAlertData';
import { globalOrderDrafts } from 'state/globalOrderDrafts';

const useConfirmOrderDraft = () => {
  const [isLoading, setIsLoading] = useState(false);

  const confirmOrderDraft = useCallback((id: string): Promise<void> => {
    setIsLoading(true);
    const timeToFinalize = localStorage.getItem(`${id}/timeToFinalize`);
    console.log('confirmOrderDraft timeToFinalize', timeToFinalize);
    return httpPostV1(`/orders/drafts/${id}/confirm`, { id, timeToFinalize })
      .then(() => {
        globalAlertData.create('Order confirmed successfully');
        globalOrderDrafts.markOrderAsProcessed();
      })
      .catch((error) => {
        genericErrorFeedback('Error confirming order draft')(error);
        return Promise.reject(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return { isLoading, confirmOrderDraft };
};

export { useConfirmOrderDraft };
