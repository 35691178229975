import {
  useState, useCallback,
} from 'react';

import { httpDelV1 } from '../helpers/xhr';
import { globalAlertData } from '../state/globalAlertData';
import { genericErrorFeedback } from '../helpers/errors';

const useDeletePrompt = () => {
  const [isLoading, setIsLoading] = useState(false);

  const deletePrompt = useCallback(
    async (
      promptId: string,
      createAlertData: boolean = true,
      giveErrorFeedback: boolean = true,
    ): Promise<void> => {
      if (!promptId) return Promise.reject(new Error('Prompt id is required'));

      setIsLoading(true);

      return httpDelV1(`/llm/pipelines/prompts/${promptId}`)
        .then(() => {
          if (createAlertData) {
            globalAlertData.create('Prompt deleted successfully');
          }
          return Promise.resolve();
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('Delete prompt failed')(error);
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    isLoading,
    deletePrompt,
  };
};

export { useDeletePrompt };
