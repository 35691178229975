import {
  useCallback, useLayoutEffect, useRef, useState, Fragment,
} from 'react';
import Dayjs from 'dayjs';

import { LoadingOverlay } from '@mantine/core';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { ScrollAreaWrapper } from 'components/wrapper/ScrollAreaWrapper';
import { SyncLog } from 'models/Erp';
import { isZeroId } from 'helpers/objectId';
import ReactJson from 'react-json-view';
import { StatusBadge } from './StatusBadge';
import { DirectionBadge } from './DirectionBadge';

interface Props {
  isLoading: boolean;
  logs: SyncLog[];
  selectedLogs: SyncLog[];
  onSelectionChange: (selected: SyncLog[]) => void;
  onScrolledToEnd: () => void;
}

const ListTable = ({
  isLoading,
  logs,
  selectedLogs,
  onSelectionChange,
  onScrolledToEnd,
}: Props) => {
  const checkbox = useRef<HTMLInputElement>();
  const [allSelected, setAllSelected] = useState(false);
  const [someNotAllSelected, setSomeNotAllSelected] = useState(false);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  useLayoutEffect(() => {
    if (logs && selectedLogs) {
      const isIndeterminate = selectedLogs.length > 0 && selectedLogs.length < logs.length;
      setAllSelected(selectedLogs.length === logs.length);
      setSomeNotAllSelected(isIndeterminate);
      if (checkbox && checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [selectedLogs, logs]);

  const toggleAll = useCallback(() => {
    onSelectionChange(allSelected || someNotAllSelected ? [] : logs);
    setAllSelected(!allSelected && !someNotAllSelected);
    setSomeNotAllSelected(false);
  }, [allSelected, someNotAllSelected, onSelectionChange, logs]);

  const toggleRowExpansion = useCallback((logId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(logId)) {
        newSet.delete(logId);
      } else {
        newSet.add(logId);
      }
      return newSet;
    });
  }, []);

  const getDetails = (log: SyncLog) => {
    const details = [
      log.failedId && !isZeroId(log.failedId) && `Failed ID: ${log.failedId}`,
      log.failedExternalId && `Failed External ID: ${log.failedExternalId}`,
      log.failedIndex && `Failed Index: ${log.failedIndex}`,
      log.failedMessage && `Reason: ${log.failedMessage}`,
      log.additionalFields && `Additional Context: ${JSON.stringify(log.additionalFields)}`,
    ].filter(Boolean).join('\n');

    return details;
  };

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-sm border border-solid">
      <ScrollAreaWrapper
        className="flex-1"
        offsetScrollbar={false}
        onScrolledEnd={onScrolledToEnd}
      >
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="sticky top-0 z-10 bg-gray-50 text-xs font-medium text-gray-500 uppercase drop-shadow">
            <tr>
              <th scope="col" className="relative px-lg py-smd">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  ref={checkbox}
                  checked={allSelected}
                  onChange={toggleAll}
                />
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Timestamp
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Model
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Direction
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-center"
              >
                Status
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Details
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-lg py-smd text-left"
              >
                Run ID
              </th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th scope="col" />
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th scope="col" />
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {logs.map((log) => (
              <Fragment key={log.id}>
                <tr
                  className={`${selectedLogs.includes(log) && 'bg-gray-50'} hover:bg-gray-50 cursor-pointer`}
                  onClick={() => toggleRowExpansion(log.id)}
                >
                  <td className="relative px-7 sm:w-12 sm:px-xl">
                    {selectedLogs.includes(log) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )}
                    <input
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      value={log.id}
                      checked={selectedLogs.includes(log)}
                      onChange={(e) => onSelectionChange(
                        e.target.checked
                          ? [...selectedLogs, log]
                          : selectedLogs.filter((p) => p !== log),
                      )}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                  <td className="whitespace-nowrap px-lg py-smd text-sm text-gray-500 !font-mono">
                    {Dayjs(log.createdAt).format('YYYY-MM-DD HH:mm:ss.SSS')}
                  </td>
                  <td className="px-lg py-smd text-sm text-gray-500">
                    {log.model}
                  </td>
                  <td className="px-lg py-smd text-sm text-gray-500">
                    <DirectionBadge direction={log.syncDirection} />
                  </td>
                  <td className="relative flex items-center justify-center px-lg py-smd">
                    <StatusBadge status={log.status} />
                  </td>
                  <td className="px-lg py-smd text-sm text-gray-500 !font-mono whitespace-pre-line">
                    {getDetails(log)}
                  </td>
                  <td className="px-lg py-smd text-sm whitespace-nowrap !font-mono text-gray-500">
                    {log.runId}
                  </td>
                  <td />
                  <td className="px-lg py-smd">
                    {expandedRows.has(log.id) ? (
                      <ChevronUpIcon className="h-4 w-4" />
                    ) : (
                      <ChevronDownIcon className="h-4 w-4" />
                    )}
                  </td>
                </tr>
                {expandedRows.has(log.id) && (
                  <tr className="bg-gray-50 shadow-[inset_0_4px_6px_-1px_rgb(0,0,0,0.1)]">
                    <td colSpan={9} className="px-lg py-md">
                      <div className="text-sm text-gray-700">
                        <ReactJson
                          src={log}
                          name={null}
                          theme="rjv-default"
                          collapsed={2}
                          enableClipboard
                          displayDataTypes={false}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
        <div className="relative flex h-10 w-full items-center justify-center">
          <LoadingOverlay
            visible={isLoading}
            loaderProps={{ type: 'dots' }}
            overlayProps={{ blur: 2 }}
          />
        </div>
      </ScrollAreaWrapper>
    </div>
  );
};

export default ListTable;
