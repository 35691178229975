import {
  ReactNode, createContext, useContext, useMemo,
  useState,
} from 'react';

import { Keyword } from '../types/Keyword';

type KeywordContextType = {
  toZoomKeyword: Keyword;
  setToZoomKeyword: (keyword: Keyword) => void;
  leftHoveredKeyword: Keyword;
  setLeftHoveredKeyword: (keyword: Keyword) => void;
  rightHoveredKeyword: Keyword;
  setRightHoveredKeyword: (keyword: Keyword) => void;
};

const KeywordContext = createContext<KeywordContextType | undefined>(undefined);

type KeywordProviderProps = {
  children: ReactNode;
};

const updateKeyword = (keyword: Keyword, setKeyword: (keyword: Keyword) => void) => {
  setKeyword({
    ...keyword,
    fieldIds: (keyword?.fieldIds || []).filter((id) => !!id),
    fieldPath: keyword?.fieldPath || '',
  });
};

const KeywordProvider: React.FC<KeywordProviderProps> = ({
  children,
}: KeywordProviderProps) => {
  const [toZoomKeyword, setToZoomKeyword_] = useState<Keyword | null>(null);
  // Hover on image/text highlights (left-side of screen)
  const [leftHoveredKeyword, setLeftHoveredKeyword_] = useState<Keyword | null>(null);
  // Hover/click on inputs or product card (righ)
  const [rightHoveredKeyword, setRightHoveredKeyword_] = useState<Keyword | null>(null);

  const setLeftHoveredKeyword = (keyword: Keyword) => {
    updateKeyword(keyword, setLeftHoveredKeyword_);
  };

  const setRightHoveredKeyword = (keyword: Keyword) => {
    updateKeyword(keyword, setRightHoveredKeyword_);
  };

  const setToZoomKeyword = (keyword: Keyword) => {
    updateKeyword(keyword, setToZoomKeyword_);
  };

  const contextValue = useMemo(
    () => ({
      toZoomKeyword,
      setToZoomKeyword,
      leftHoveredKeyword,
      setLeftHoveredKeyword,
      rightHoveredKeyword,
      setRightHoveredKeyword,
    }),
    [leftHoveredKeyword, rightHoveredKeyword, toZoomKeyword],
  );

  return (
    <KeywordContext.Provider value={contextValue}>
      {children}
    </KeywordContext.Provider>
  );
};

const useKeywordContext = () => {
  const context = useContext(KeywordContext);

  if (context === undefined) {
    throw new Error('Must be wrapped by KeywordContext provider.');
  }

  return context;
};

export { KeywordContext, useKeywordContext, KeywordProvider };
