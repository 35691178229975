import {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react';

import { Subject } from 'models/User';
import { SubjectDetailSidebar } from 'components/sidebars/SubjectDetail/SubjectDetailSidebar';

interface Props {
  children: React.ReactNode;

  subject: Subject;
  onComplete: (subject: Subject) => void;
}

const SubjectDetailSidebarWrapper = forwardRef(
  ({ children, subject, onComplete }: Props, ref) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const onManageSubjectButtonClick = useCallback(() => {
      setIsSidebarOpen(true);
    }, []);

    const onSidebarClose = useCallback(() => {
      setIsSidebarOpen(false);
    }, []);

    useImperativeHandle(ref, () => ({
      onManageSubjectButtonClick,
      onSidebarClose,
    }));
    return (
      <>
        {children}

        <SubjectDetailSidebar
          subject={subject}
          sidebarOpen={isSidebarOpen}
          setSidebarOpen={setIsSidebarOpen}
          onComplete={onComplete}
        />
      </>
    );
  },
);

export default SubjectDetailSidebarWrapper;
