import { Editor } from '@tiptap/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { Popover } from '@mantine/core';
import {
  faAlignCenter, faAlignLeft, faAlignRight, faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

interface Props {
  editor: Editor;
}

const TextAlign = ({ editor }: Props) => {
  const [open, setOpen] = useState(false);

  if (!editor) {
    return null;
  }

  return (
    <Popover opened={open}>
      <Popover.Target>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => setOpen(!open)}
          className="cursor-pointer items-center flex gap-2 hover:bg-gray-100 rounded-sm p-1 transition-colors duration-300"
        >
          {editor.isActive({ textAlign: 'left' }) && <FontAwesomeIcon icon={faAlignLeft} />}
          {editor.isActive({ textAlign: 'center' }) && <FontAwesomeIcon icon={faAlignCenter} />}
          {editor.isActive({ textAlign: 'right' }) && <FontAwesomeIcon icon={faAlignRight} />}
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </Popover.Target>
      <Popover.Dropdown className="flex flex-col gap-1">
        <button
          type="button"
          onClick={() => {
            editor.chain().focus().setTextAlign('left').run();
            setOpen(false);
          }}
          className={twMerge(
            'items-center flex gap-2 hover:bg-gray-100 rounded-sm px-2 py-1 transition-colors duration-300',
            editor.isActive({ textAlign: 'left' }) && 'bg-gray-100',
          )}
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.chain().focus().setTextAlign('center').run();
            setOpen(false);
          }}
          className={twMerge(
            'items-center flex gap-2 hover:bg-gray-100 rounded-sm px-2 py-1 transition-colors duration-300',
            editor.isActive({ textAlign: 'center' }) && 'bg-gray-100',
          )}
        >
          <FontAwesomeIcon icon={faAlignCenter} />
        </button>
        <button
          type="button"
          onClick={() => {
            editor.chain().focus().setTextAlign('right').run();
            setOpen(false);
          }}
          className={twMerge(
            'items-center flex gap-2 hover:bg-gray-100 rounded-sm px-2 py-1 transition-colors duration-300',
            editor.isActive({ textAlign: 'right' }) && 'bg-gray-100',
          )}
        >
          <FontAwesomeIcon icon={faAlignRight} />
        </button>
      </Popover.Dropdown>
    </Popover>
  );
};

export default TextAlign;
