import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { LoadingOverlay, Tooltip } from '@mantine/core';
import { Button } from '../../../ui/Button';
import { ScrollAreaWrapper } from '../../../wrapper/ScrollAreaWrapper';
import { useInstructionContext } from '../../../../contexts/useInstructionContext';
import { useFetchSchemaByTypeRef } from '../../../../hooks/fetch/useFetchSchemaByTypeRef';
import { TypeRef } from '../../../../features/instruction/types/type-ref';

import { HeaderCard } from './HeaderCard';
import { InstructionCard } from './InstructionCard';

import { FieldSpec } from '../../../../features/instruction/models/Schema';
import { Workflow } from '../../../../types/instruction';

interface Props {
  buttonTitle: string;
  onButtonClick: () => void;

  isOuterLoading: boolean;

  title?: string;
  subtitle?: string;
}

const containerVariants = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const InstructionDetail = ({
  buttonTitle, onButtonClick, isOuterLoading, title, subtitle,
}: Props) => {
  const {
    selectedWorkflow,
    prompt,
    setPrompt,
  } = useInstructionContext();

  const { isLoading: schemaIsLoading, schema, loadSchema } = useFetchSchemaByTypeRef();

  const [fieldSpecs, setFieldSpecs] = useState<FieldSpec[]>(prompt.boundTypeSpecs[0].fields);

  const onCreateClicked = () => {
    onButtonClick();
  };

  // TODO: set in the context and this should happen in page redirection
  useEffect(() => {
    switch (selectedWorkflow) {
      case Workflow.ORDER:
        loadSchema(TypeRef.ORDER_DRAFT);
        break;
      default:
        break;
    }
  }, [loadSchema, selectedWorkflow]);

  useEffect(() => {
    setPrompt((_prompt) => ({
      ..._prompt,
      boundTypeSpecs: _prompt.boundTypeSpecs.map((typeSpec, i) => {
        if (i === 0) {
          return {
            ...typeSpec,
            fields: fieldSpecs,
          };
        }
        return typeSpec;
      }),
    }));
  }, [fieldSpecs, setPrompt]);

  return (
    <ScrollAreaWrapper
      className="flex-1 overflow-hidden"
      childrenClassName={`flex flex-col overflow-x-hidden ${title && 'space-y-4 p-lg'}`}
      offsetScrollbar
    >
      {
        title && (
          <div className="flex flex-col justify-center gap-2 pb-4">
            <h1 className="text-xl font-semibold leading-none text-gray-900">
              {title}
            </h1>
            {
              subtitle && (
                <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                  {subtitle}
                </div>
              )
            }
          </div>
        )
      }
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="show"
        className="flex-1 space-y-6"
      >
        <LoadingOverlay
          visible={schemaIsLoading || isOuterLoading}
          loaderProps={{ type: 'dots' }}
          overlayProps={{ blur: 2 }}
        />
        <motion.div variants={itemVariants}>
          <HeaderCard prompt={prompt} setPrompt={setPrompt} />
        </motion.div>

        {
          (schema && schema.fields.length > 0) && (
            <motion.div variants={containerVariants}>
              {schema.fields.map((field) => (
                <InstructionCard
                  key={field.name}
                  fieldSchema={field}
                  fieldSpecs={fieldSpecs}
                  setFieldSpecs={setFieldSpecs}
                />
              ))}
            </motion.div>
          )
        }

        <motion.div className="flex justify-end" variants={itemVariants}>
          <Tooltip label="Coming soon">
            <Button
              disabled
              title={buttonTitle}
              onClick={onCreateClicked}
            />
          </Tooltip>
        </motion.div>
      </motion.div>
    </ScrollAreaWrapper>
  );
};

export default InstructionDetail;
