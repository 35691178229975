import {
  makeObservable, observable, action, runInAction,
} from 'mobx';
import { AxiosError } from 'axios';
import { makePersistable } from 'mobx-persist-store';

import { httpGetV1, httpPostV1, httpRequestV1 } from 'helpers/xhr';
import { UserRole } from 'models/User';
import { Business } from 'models/Business';

type UserSigninParams = {
  username: string;
  password: string;
};

type UserSimplifiedSigninParams = {
  phone: string;
  totpCode: string;
  username?: string;
};

type UserInvitedSignupParams = {
  inviteAppLink: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  totpCode: string;
};

class User {
  id?: string | null = null;

  business?: Business | null = null;

  firstName?: string | null = null;

  lastName?: string | null = null;

  email?: string | null = null;

  roles?: UserRole[] = [];

  isSignedIn: boolean = false;

  isSimplifiedAccess: boolean = false;

  activated: boolean = true;

  constructor() {
    makeObservable(this, {
      id: observable,
      business: observable,
      firstName: observable,
      lastName: observable,
      email: observable,
      roles: observable,
      isSignedIn: observable,
      isSimplifiedAccess: observable,
      activated: observable,
      signIn: action,
      signOut: action,
      setUserActivation: action,
    });
  }

  initPersistence = async () => {
    await makePersistable(this, {
      name: 'user',
      properties: [
        'id',
        'business',
        'firstName',
        'lastName',
        'email',
        'roles',
        'isSignedIn',
        'isSimplifiedAccess',
        'activated',
      ],
      storage: window.localStorage,
    });
  };

  refresh = async () => {
    await httpGetV1('/users/me')
      .then((response) => {
        runInAction(() => {
          this.id = response.data.id;
          this.business = response.data.business;
          this.firstName = response.data.firstName;
          this.lastName = response.data.lastName;
          this.email = response.data.email;
          this.roles = response.data.roles;
        });
      })
      .catch((error) => {
        console.error('Error refreshing user', error);
        return Promise.reject(error);
      });
  };

  signIn = async (params: UserSigninParams) => {
    const response = await httpPostV1('/auth/signin', params, {
      baseURL: `${HOSHII_API_URL}/v2`,
    });
    runInAction(() => {
      this.id = response.data.id;
      this.business = response.data.business;
      this.firstName = response.data.firstName;
      this.lastName = response.data.lastName;
      this.email = response.data.email;
      this.roles = response.data.roles;
      this.isSignedIn = true;
      this.activated = true;
    });
  };

  invitedSignup = async (params: UserInvitedSignupParams) => {
    const response = await httpPostV1(
      `/auth/signup/${params.inviteAppLink}`,
      params,
      { baseURL: `${HOSHII_API_URL}/v2` },
    );
    runInAction(() => {
      this.id = response.data.id;
      this.business = response.data.business;
      this.firstName = response.data.firstName;
      this.lastName = response.data.lastName;
      this.email = response.data.email;
      this.roles = response.data.roles;
      this.isSignedIn = true;
      this.activated = true;
    });
  };

  setUserActivation = (activated: boolean) => {
    this.activated = activated;
  };

  static refreshTokens = async () => httpPostV1('/auth/tokens/refresh');

  signOut = async () => {
    this.id = null;
    this.business = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.isSignedIn = false;
    this.isSimplifiedAccess = false;
    this.activated = true;
    return httpPostV1('/auth/signout');
  };

  blockNotActivatedUser = async (error: AxiosError) => {
    // TODO: Custom hoshii error format type
    // @ts-ignore
    if (error?.response?.data?.code === 1) {
      runInAction(() => {
        this.activated = false;
      });
      return new Promise(() => {});
    }
    throw error;
  };

  refreshTokenIf401 = async (error: AxiosError) => {
    if (
      error?.response?.status === 401
      // TODO: Replace url check with custom backend error code
      && !error.response?.request?.responseURL?.includes('/auth/tokens/refresh')
    ) {
      try {
        await User.refreshTokens();
        return await httpRequestV1(error.response.config!);
      } catch (refreshError: unknown) {
        this.signOut();
        throw new Error('Login expired');
      }
    } else {
      throw error;
    }
  };
}

export { User, UserSigninParams, UserSimplifiedSigninParams };
