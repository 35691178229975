import Dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar, faUser, faTag, faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';

import { Order, OrderStatus } from 'features/order/models/Order';
import adam from 'assets/adam-face.png';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'config/routes';

interface Props {
  order: Order;
}

const OrderPanel = ({ order }: Props) => {
  const navigate = useNavigate();

  const requestedDeliveryTime = useMemo(
    () => Dayjs(order?.requestedDeliveryTime).format('MMM D, YYYY'),
    [order?.requestedDeliveryTime],
  );

  const confirmedOrderDate = useMemo(() => {
    const statusTrail = order?.statusTrails.find((trail) => trail.status === OrderStatus.Confirmed);
    return Dayjs(statusTrail?.createdAt).format('MMM D, YYYY');
  }, [order?.statusTrails]);

  const handleViewOrderDetails = () => {
    navigate(ROUTES.ORDER_BY_ID(order.id));
  };

  const handleProcessOrder = () => {
    navigate(ROUTES.PROCESS_ORDER_DRAFT_BY_ID(order.id));
  };

  if (order.status === OrderStatus.Confirmed || order.status === OrderStatus.Rejected) {
    return (
      <div className="w-full max-w-sm space-y-6 p-6">
        {/* Adam's Avatar and Initial Message */}
        <div className="flex items-center space-x-3">
          <div className="rounded-full overflow-hidden">
            <img src={adam} alt="adam" className="h-10 w-10" />
          </div>
          <div className="flex items-center space-x-2">
            <h3 className="font-semibold text-violet-900">Adam</h3>
          </div>
        </div>

        {/* Chat Bubble with Analysis */}
        <div className="relative bg-violet-100 rounded-2xl py-4 px-5 space-y-4">
          <div className="absolute -top-2 left-6 w-4 h-4 bg-violet-100 transform rotate-45" />

          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faClipboardCheck} className="text-violet-900" />
            <p className="text-violet-900 font-medium">
              This order has been processed:
            </p>
          </div>

          <div className="space-y-3">
            <div className="space-y-3">
              <div className="flex items-center space-x-3 text-sm">
                <FontAwesomeIcon icon={faTag} className="h-4 w-4 text-violet-500" />
                <span className="text-gray-700">
                  {order.products.length}
                  {' '}
                  Product
                  {order.products.length > 1 ? 's' : ''}
                </span>
              </div>
              <div className="flex items-center space-x-3 text-sm">
                <FontAwesomeIcon icon={faUser} className="h-4 w-4 text-violet-500" />
                <span className="text-gray-700">
                  Delivery to
                  {' '}
                  {order.customer?.name || order.createdByUserInfo.email}
                </span>
              </div>
              <div className="flex items-center space-x-3 text-sm">
                <FontAwesomeIcon icon={faCalendar} className="h-4 w-4 text-violet-500" />
                <span className="text-gray-700">
                  For
                  {' '}
                  {requestedDeliveryTime}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Action Button */}
        <button
          type="button"
          className="w-full py-2 rounded-md bg-green-500 hover:bg-green-600 text-white"
          onClick={handleViewOrderDetails}
        >
          View order details →
        </button>

        {/* Status Message */}
        <p className="text-sm text-center text-green-600">
          Order successfully processed on
          {' '}
          {confirmedOrderDate}
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* AI Assistant Header */}
      <div className="flex items-center space-x-2">
        <div className="rounded-full overflow-hidden">
          <img src={adam} alt="adam" className="h-10 w-10" />
        </div>
        <div className="text-start">
          <h3 className="font-semibold text-violet-900">Adam</h3>
          <p className="text-sm text-violet-600">I&apos;ve analyzed this message</p>
        </div>
      </div>

      {/* Order Analysis */}
      <div className="relative bg-violet-100 rounded-2xl py-4 px-5 space-y-4">
        <div className="absolute -top-2 left-6 w-4 h-4 bg-violet-100 transform rotate-45" />
        <p className="text-sm text-violet-900 font-medium">
          I detected an order with these details:
        </p>
        <div className="space-y-3">
          <div className="flex items-center space-x-3 text-sm">
            <FontAwesomeIcon icon={faTag} className="h-4 w-4 text-violet-500" />
            <span className="text-gray-700">
              {order.products.length}
              {' '}
              Product
              {order.products.length > 1 ? 's' : ''}
            </span>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <FontAwesomeIcon icon={faUser} className="h-4 w-4 text-violet-500" />
            <span className="text-gray-700">
              Delivery to
              {' '}
              {order.customer?.name || order.createdByUserInfo.email}
            </span>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <FontAwesomeIcon icon={faCalendar} className="h-4 w-4 text-violet-500" />
            <span className="text-gray-700">
              For
              {' '}
              {requestedDeliveryTime}
            </span>
          </div>
        </div>
      </div>

      {/* Action Button */}
      <div className="space-y-3">
        <button
          type="button"
          className="w-full py-2 rounded-md bg-violet-500 hover:bg-violet-600 text-white"
          onClick={handleProcessOrder}
        >
          Process order draft →
        </button>
        <p className="text-xs text-center text-violet-600">
          I&apos;ve prepared everything for quick processing
        </p>
      </div>
    </div>
  );
};

export default OrderPanel;
