import {
  useCallback, useRef, useEffect, useMemo,
} from 'react';

import { globalUser } from 'state/globalUser';
import { AssignedStatus } from 'types/order';
import { UserRole } from 'models/User';
import { getAssignedStatus } from 'features/order/utils/order';

import { useOrderContext } from 'features/order/contexts/useOrderContext';
import { useProcessOrderContext } from 'features/order/contexts/useProcessOrderContext';

import { useDeclineOrderDraft } from 'features/order/api/useDeclineOrderDraft';
import { useUpdateOrderDrafts } from 'features/order/api/useUpdateOrderDrafts';
import { useConfirmOrderDraft } from 'features/order/api/useConfirmOrderDraft';
import { useConfirmOrderDraftsByGroupId } from 'features/order/api/useConfirmOrderDraftsByGroupId';
import { useAssignDraft } from 'features/order/api/useAssignDraft';

import LoadingOverlay from 'components/ui/LoadingOverlay';

import { ConfirmOrderModalWrapper } from 'components/wrapper/ConfirmOrderModalWrapper';
import { isZeroId } from 'helpers/objectId';
import { Assignee as InitialAssignee } from '../Assignee';
import {
  Body, BodyWrapper, Buttons,
  TabsAndAssignee,
} from './Body';

interface Props {
  isOrderProcessingMode?: boolean;
  isNewOrderDraft?: boolean;
  assignGlobalCurrentOrder?: (assigneeId: string) => void;
  onOrderProcessed?: (orderId: string) => void; // in case, some action is required
}

const ProcessOrderDrafts = (props: Props) => {
  const {
    isOrderProcessingMode,
    isNewOrderDraft,
    assignGlobalCurrentOrder,
    onOrderProcessed,
  } = props;
  const { groupOrders, setGroupOrders } = useOrderContext();
  const { order } = useProcessOrderContext();
  const { onOrderDraftProcessed } = useProcessOrderContext();

  const { isLoading: isAssignLoading, assignTeamMember } = useAssignDraft();
  const { isLoading: isDeclineOrderDraftLoading, declineOrderDraft } = useDeclineOrderDraft();
  const { isLoading: isSaveAndCreateOrderDraftsLoading, updateOrderDrafts } = useUpdateOrderDrafts();
  const { isLoading: isConfirmOrderDraftLoading, confirmOrderDraft } = useConfirmOrderDraft();
  const {
    isLoading: isConfirmOrderDraftsByGroupIdLoading,
    confirmOrderDraftsByGroupId,
  } = useConfirmOrderDraftsByGroupId();

  const localStorageKey = useMemo(() => (order?.id ? `${order.id}/timeToFinalize` : ''), [order?.id]);

  // avoid re-rendering
  const elapsedTimeRef = useRef<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startStopwatch = useCallback((savedTime?: string) => {
    if (savedTime && savedTime !== '0') {
      elapsedTimeRef.current = Number(savedTime);
    }
    intervalRef.current = setInterval(() => {
      elapsedTimeRef.current += 1000; // increment by 1000ms
      // console.log('elapsedTimeRef.current', elapsedTimeRef.current);
    }, 1000);
  }, []);

  const stopStopwatch = useCallback((elapsedTime: number) => {
    try {
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, String(elapsedTime));
        // console.log('stopping stopwatch for order with time', elapsedTime, order?.id);
      }
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  }, [localStorageKey]);

  useEffect(() => {
    const savedTime = localStorage.getItem(localStorageKey);
    // already assigned, so we should start the stopwatch
    if (order?.assigneeId && !isZeroId(order?.assigneeId)) {
      // pass savedTime, if any
      startStopwatch(savedTime);
      // console.log('starting stopwatch because order is assigned for order', order?.id);
    }

    return () => {
      // console.log('cleanup: stopping stopwatch for order with time', elapsedTimeRef.current, order?.id);
      stopStopwatch(elapsedTimeRef.current);
      elapsedTimeRef.current = 0;
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [order?.id, order?.assigneeId, localStorageKey, startStopwatch, stopStopwatch]);

  const assignedStatus: AssignedStatus = getAssignedStatus(order?.assigneeId);

  const onAssignButtonClick = useCallback(
    async (
      teamMemberId: string,
      teamMemberName: string,
      assignedComment: string,
    ) => {
      try {
        const result = await assignTeamMember({
          order,
          teamMemberId,
          teamMemberName,
          assignedComment,
        });
        if (result) {
          // console.log('starting stopwatch for assignment for order', order?.id);
          startStopwatch(); // Start the stopwatch when the order is assigned
        }

        if (!result) { return; }

        assignGlobalCurrentOrder?.(result.assigneeId);
        setGroupOrders((_groupOrders) => _groupOrders.map((o) => (o.id === result.id ? result : o)));

        if (teamMemberId !== globalUser.id) {
          if (isOrderProcessingMode) {
            onOrderProcessed(order?.id);
          } else {
            setGroupOrders([]);
            onOrderProcessed?.(result.id);
          }
        }
      } catch (error) {
        /* empty */
      }
    },
    [
      assignTeamMember,
      isOrderProcessingMode,
      onOrderProcessed,
      order,
      setGroupOrders,
      assignGlobalCurrentOrder,
      startStopwatch,
    ],
  );

  const confirmOrderModalWrapperRef = useRef(null);

  const onConfirm = useCallback(() => {
    // console.log('stopping time onConfirm for order', order?.id);
    stopStopwatch(elapsedTimeRef.current);
    if (order.groupId && !isZeroId(order.groupId)) {
      confirmOrderDraftsByGroupId(order.groupId, order.id).then(() => {
        if (isOrderProcessingMode) onOrderDraftProcessed(groupOrders.find((o) => !o.isGroupSibling)?.id);
      });
    } else {
      confirmOrderDraft(order.id).then(() => {
        if (isOrderProcessingMode) onOrderDraftProcessed(order.id);
      });
    }
  }, [
    stopStopwatch,
    confirmOrderDraft,
    confirmOrderDraftsByGroupId,
    groupOrders,
    onOrderDraftProcessed,
    order?.groupId,
    order?.id,
    isOrderProcessingMode,
  ]);

  const onConfirmButtonClick = useCallback(() => {
    if (
      !order
      || isConfirmOrderDraftLoading
      || isConfirmOrderDraftsByGroupIdLoading
    ) return;

    // If it's a group order, ask for the confirmation. Otherwise, confirm the order
    if (order.groupId && !isZeroId(order.groupId)) {
      confirmOrderModalWrapperRef.current?.openModal();
    } else {
      onConfirm();
    }
  }, [
    onConfirm,
    isConfirmOrderDraftLoading,
    isConfirmOrderDraftsByGroupIdLoading,
    order,
  ]);

  const onDeclineButtonClick = useCallback(() => {
    if (
      !order
      || isDeclineOrderDraftLoading
    ) return;

    // console.log('stopping time onDecline for order', order?.id);
    stopStopwatch(elapsedTimeRef.current);
    declineOrderDraft(order.id).then(() => {
      onOrderDraftProcessed(order.id);
    });
  }, [
    stopStopwatch,
    declineOrderDraft,
    isDeclineOrderDraftLoading,
    order,
    onOrderDraftProcessed,
  ]);

  if (isAssignLoading) {
    return <LoadingOverlay visible displayText="Assigning order" />;
  }

  if (!order || !order.assigneeId) {
    return (
      <LoadingOverlay
        visible
        displayText="Loading order"
      />
    );
  }

  if (assignedStatus === AssignedStatus.OTHER && !globalUser.roles.includes(UserRole.SupplierAdmin)) {
    return (
      <div className="relative flex h-full flex-1 items-center justify-center">
        <div className="flex flex-col space-y-2">
          <h1 className="text-center text-title-md">Oops!</h1>
          <p>This order is already assigned to other team member.</p>
        </div>
      </div>
    );
  }

  if (assignedStatus === AssignedStatus.UNASSIGNED) {
    return (
      <div className="p-4">
        <InitialAssignee
          order={order}
          assignOrder={onAssignButtonClick}
        />
      </div>
    );
  }

  if (isConfirmOrderDraftLoading || isConfirmOrderDraftsByGroupIdLoading) {
    return <LoadingOverlay visible displayText="Confirming order" />;
  }

  if (isDeclineOrderDraftLoading) {
    return <LoadingOverlay visible displayText="Declining order" />;
  }

  if (isSaveAndCreateOrderDraftsLoading) {
    return <LoadingOverlay visible displayText="Saving order" />;
  }

  return (
    <ConfirmOrderModalWrapper
      ref={confirmOrderModalWrapperRef}
      onConfirm={onConfirm}
    >
      <div className="flex h-full w-full flex-col overflow-hidden bg-gray-50">
        <div className="flex-1 flex flex-col overflow-hidden">
          <div
            id="body-container"
            className="m-4 flex-1 overflow-y-scroll hidden-scrollbar"
          >
            <TabsAndAssignee
              order={order}
              onAssignButtonClick={onAssignButtonClick}
            />
            <BodyWrapper>
              <Body />
            </BodyWrapper>
          </div>
        </div>
        <div className="h-fit border-t border-t-gray-300">
          <Buttons
            isNewOrderDraft={isNewOrderDraft}
            onConfirmButtonClick={onConfirmButtonClick}
            updateOrderDrafts={updateOrderDrafts}
            onDeclineButtonClick={onDeclineButtonClick}
          />
        </div>
      </div>
    </ConfirmOrderModalWrapper>
  );
};

export default ProcessOrderDrafts;
