import { useState } from 'react';
import { object, string } from 'yup';
import { Formik } from 'formik';
import { Select } from '@mantine/core';

import { genericErrorFeedback } from 'helpers/errors';
import { httpPostV1 } from 'helpers/xhr';
import { FormInput } from '../../FormInput';
import { UserRole } from '../../../models/User';

import { SidebarBase } from '../SidebarBase';
import { Button } from '../../ui/Button';

const formInitialValues = {
  email: '',
  role: '',
};

const formValidationSchema = object({
  email: string().email().required(),
  role: string().required(),
});

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  onComplete: () => void;
}

const InviteTeammemberSidebar = ({
  sidebarOpen,
  setSidebarOpen,
  onComplete,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const _onComplete = () => {
    setSidebarOpen(false);
    onComplete();
  };

  const header = (
    <div className="flex space-x-2 px-sm">Invite a new team member</div>
  );

  const body = (
    <div className="space-y-4 px-sm">
      <p className="text-sm text-gray-600">
        Invite a team member to collaborate and use Hoshii in your organization.
      </p>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          const roles = [values.role];
          if (values.role === UserRole.SupplierAdmin) {
            roles.push(UserRole.SupplierUser);
          }
          httpPostV1('/users/invite', {
            email: values.email,
            roles,
          })
            .then(() => {
              _onComplete();
            })
            .catch(genericErrorFeedback('Error inviting user'))
            .finally(() => {
              setIsLoading(false);
            });
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <div className="flex flex-col gap-md">
            <FormInput
              field="email"
              disabled={isLoading}
              label="Email address"
              placeHolder="Invite by email address..."
              value={values.email}
              error={errors.email}
              touched={touched.email}
              required
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
            />
            <Select
              value={values.role || ''}
              data={[
                { label: 'User', value: UserRole.SupplierUser },
                { label: 'Admin', value: UserRole.SupplierAdmin },
              ]}
              onChange={handleChange('role')}
              onBlur={handleBlur('role')}
              disabled={isLoading}
              label="Role"
              clearable
              required
            />

            <div className="mt-lg flex w-full justify-end border-t pt-lg">
              <Button
                title="Send invitation"
                disabled={isLoading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );

  return (
    <SidebarBase
      header={header}
      body={body}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
      sidebarWidth="max-w-[30vw] 2xl:max-w-[20vw]"
    />
  );
};

export { InviteTeammemberSidebar };
