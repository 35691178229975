// PWQ stands for ProductWithQuantity
export const ORDER_DRAFT_PWQ_NAME_PATH = 'order_drafts.*.products.*.name';
export const ORDER_DRAFT_PWQ_IDorSKU_PATH = 'order_drafts.*.products.*.id_or_sku';
export const ORDER_DRAFT_PWQ_QUANTITY_PATH = 'order_drafts.*.products.*.quantity';
export const ORDER_DRAFT_PWQ_UNIT_PATH = 'order_drafts.*.products.*.unit';

export const ORDER_DRAFT_CREATED_BY_PATH = 'order_drafts.*.created_by';

// Model paths
export const PRODUCTS_MODEL_PATH = 'products';

export enum Group {
  StandardFields = 'StandardFields',
  Customer = 'Customer',
  Product = 'Product',
}
