import { useState, useCallback } from 'react';

import { httpGetV1 } from 'helpers/xhr';
import { genericErrorFeedback } from 'helpers/errors';
import { Language } from '../models/Language';

const useFetchTranscriptionLanguages = () => {
  const [languages, setLanguages] = useState<Language[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadLanguages = useCallback(
    (giveErrorFeedback: boolean = true): Promise<Language[]> => {
      setIsLoading(true);

      return httpGetV1('/language/transcription_languages')
        .then((response) => {
          const _languages = response.data as Language[];
          setLanguages(_languages);
          return _languages;
        })
        .catch((error) => {
          if (giveErrorFeedback) {
            genericErrorFeedback('An error has occured while loading transcription languages.')(
              error,
            );
          }
          return Promise.reject(error);
        })
        .finally(() => setIsLoading(false));
    },
    [],
  );

  return {
    languages,
    loadLanguages,
    isLoading,
  };
};

export { useFetchTranscriptionLanguages };
