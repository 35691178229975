import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, FocusEvent, useCallback } from 'react';
import { Label } from 'flowbite-react';

import { FormikErrors, FormikTouched } from 'formik';
import { Radio, Select } from '@mantine/core';
import { Phone, PhoneType } from 'models/Phone';
import { Button } from './ui/Button';
import { FormInput } from './FormInput';

interface Props {
  field: string;
  values: Phone[];
  label: string;
  description?: string;
  errors?: string | string[] | FormikErrors<Phone>[];
  required?: boolean;
  touched: FormikTouched<Phone>[];
  disabled?: boolean;
  setFieldValue: (
    field: string,
    value: Phone[],
  ) => Promise<void | FormikErrors<any>>;
  onBlur: (
    event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLSelectElement>,
  ) => void;
}

const FormInputPhones = ({
  field,
  values,
  label,
  description,
  errors,
  required,
  touched,
  disabled,
  setFieldValue,
  onBlur,
}: Props) => {
  const onNumberChange = useCallback(
    (index: number, event: ChangeEvent<HTMLInputElement>) => setFieldValue(
      field,
      values.map((value, i) => {
        if (i === index && event.target.value) {
          return {
            ...value,
            number: event.target.value.replace(/\s+/g, ''),
          };
        }
        return value;
      }),
    ),
    [field, setFieldValue, values],
  );

  const onTypeChange = useCallback(
    (index: number, value: string) => setFieldValue(
      field,
      values.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            type: value as PhoneType,
          };
        }
        return v;
      }),
    ),
    [field, setFieldValue, values],
  );
  const onDefaultChange = useCallback(
    (index: number) => {
      setFieldValue(
        field,
        values.map((value, i) => {
          if (i === index) {
            return {
              ...value,
              isDefault: true,
            };
          }

          return {
            ...value,
            isDefault: false,
          };
        }),
      );
    },
    [field, setFieldValue, values],
  );

  const onAddNumberButtonClick = useCallback(() => {
    setFieldValue(field, [
      ...values,
      {
        number: '',
        type: 'landline',
        isDefault: false,
      } as Phone,
    ]);
  }, [field, setFieldValue, values]);

  const onDeleteButtonClick = useCallback(
    (index: number) => {
      setFieldValue(
        field,
        values.filter((_, i) => i !== index),
      );
    },
    [field, setFieldValue, values],
  );

  return (
    <div>
      <div>
        <div className="flex">
          <Label htmlFor={field} value={label} />
          {required ? (
            <div className="text-required-star text-error">*</div>
          ) : null}
        </div>

        {description ? (
          <div className="mb-xxs mt-sm text-label-caption text-description">
            {description}
          </div>
        ) : null}

        <div className="flex flex-col gap-smd">
          {values
            && values.map((p, i) => (
              <div key={`phone-${p}`} className="flex flex-col gap-xs">
                <div className="flex flex-row gap-smd">
                  <div className="flex-1">
                    <FormInput
                      type="phone"
                      value={p.number}
                      label=""
                      field=""
                      onChange={(event) => onNumberChange(i, event)}
                      disabled={disabled}
                    />
                  </div>
                  <Select
                    value={p.type}
                    data={Object.values(PhoneType)}
                    onChange={(value) => onTypeChange(i, value)}
                    className="w-[10ch]"
                  />
                </div>
                {errors && touched ? (
                  <div className="text-label-caption text-error">
                    {errors[i]
                      ? Object.keys(errors[i])
                        // @ts-ignore
                        .map((k) => errors[i][k]
                          .toString()
                          .replace(`${field}[${i}].`, ''),
                        )
                        .join(', ')
                      : null}
                  </div>
                ) : null}
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center gap-xs">
                    <Radio
                      label="Default number"
                      checked={p.isDefault}
                      onChange={() => onDefaultChange(i)}
                      onBlur={onBlur}
                      disabled={disabled}
                    />
                  </div>
                  <button
                    type="button"
                    className="flex flex-row items-center gap-xs"
                    onClick={() => onDeleteButtonClick(i)}
                  >
                    <XMarkIcon width={20} height={20} />
                    Remove
                  </button>
                </div>
              </div>
            ))}

          {values?.length < 10 ? (
            <div>
              <Button
                title="Add number"
                onClick={onAddNumberButtonClick}
                theme="secondary"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { FormInputPhones };
