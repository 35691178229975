import {
  memo, useEffect, useMemo, useState,
} from 'react';
import { UserIcon } from '@heroicons/react/20/solid';

import { ItemsExpanded } from '../../../../../types/common';
import { useChatsContext } from '../../../../../contexts/useChatsContext';
import { Chat } from '../../../../../models/Chat';
import { ProfileSection } from './Section/ProfileSection';
import {
  DetailPanelWidthType,
  useDetailPanelWidth,
} from '../../../../../contexts/useDetailPanelWidthContext';
import { isAiChat as isAiChatHelper } from '../../../../../helpers/chat';

import adam from '../../../../../assets/adam-face.png';

interface Props {
  chatId: string;
}

const UserInfoPanel = memo(({ chatId }: Props) => {
  const { setDetailPanelWidth } = useDetailPanelWidth();
  const { chats } = useChatsContext();

  const chat: Chat | undefined = useMemo(
    () => chats.find((c) => c.id === chatId),
    [chats, chatId],
  );

  const isAiChat = useMemo(() => isAiChatHelper(chat), [chat]);

  const [customerSectionExpanded, setCustomerSectionExpanded] = useState<ItemsExpanded>({});
  const onCustomerSectionOpenClicked = (id: string) => {
    setCustomerSectionExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    setDetailPanelWidth(DetailPanelWidthType.SMALL);
  }, [setDetailPanelWidth]);

  if (!chat?.business) return null;

  if (isAiChat) {
    return (
      <div className="h-full w-full pt-lg">
        <div>
          <div className="border-b border-blue-gray-50 pb-lg">
            <div className="flex w-full justify-center">
              <div className="flex aspect-square w-[20%] shrink-0 items-center justify-center overflow-hidden rounded-full bg-gray-300">
                <img
                  src={adam}
                  alt="adam"
                  className="w-full aspect-square rounded-full object-cover"
                />
              </div>
            </div>
            <div className="pt-sm text-center text-title-md">
              Adam
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full pt-lg">
      <div>
        <div className="border-b border-blue-gray-50 pb-lg">
          <div className="flex w-full justify-center">
            <div className="flex aspect-square w-[20%] shrink-0 items-center justify-center overflow-hidden rounded-full bg-gray-300">
              <UserIcon className="aspect-square w-[80%] text-white" />
            </div>
          </div>
          <div className="pt-sm text-center text-title-md">
            {chat.business.name}
          </div>
        </div>

        <ProfileSection
          chat={chat}
          isExpanded={customerSectionExpanded.profile}
          onCustomerSectionOpenClicked={onCustomerSectionOpenClicked}
        />
      </div>
    </div>
  );
});

export default UserInfoPanel;
